/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Blockly React Component.
 * @author samelh@google.com (Sam El-Husseini)
 */
/**
 * @modifications
 *
 * Copyright 2023 Diakronos Solutions Inc
 * This file has been heavily modified from the samelh's original.
 */

import React, { useCallback } from "react";

import { useEffect, useRef } from "react";

import Blockly from "blockly/core";
import { javascriptGenerator } from "blockly/javascript";
import locale from "blockly/msg/en";
import "blockly/blocks";
import { useStudy, useStudyDispatch } from "../StudyContext";

Blockly.setLocale(locale);

function BlocklyComponent(props) {
  const study = useStudy();
  const studyDispatch = useStudyDispatch();
  const valueCriteria = study.valueCriteria;
  const alternativeVariables = study.alternativeVariables;
  const contextVariables = study.epochVariables.filter((ev) => ev.context);
  const preparations = study.preparations;

  //   const [disabledRun, setDisabledRun] = useState(true);
  const instantPreview = useRef({ checked: true });
  const oldGroupId = useRef(0);
  const loadingModel = useRef(false);
  const oldValueCriteria = useRef([]);
  const changeHandler = useRef(() => {});
  const handleModelChange = (change) => {
    // catch any changes due to loading from file
    if (loadingModel.current) return;
    if (
      change.type === "move" ||
      change.type === "delete" ||
      change.type === "change"
    ) {
      // catch multiple simultaneous events triggered by, e.g., moving blocks around
      if (change.group === oldGroupId.current) return;
      else oldGroupId.current = change.group;
      //   // first, save the current state of the model
      //   props.saveWorkspace(
      //     Blockly.serialization.workspaces.save(primaryWorkspace.current)
      //   );
      // save locally
      localStorage.setItem(
        "evalModel",
        JSON.stringify(
          Blockly.serialization.workspaces.save(primaryWorkspace.current)
        )
      );
      studyDispatch({
        section: "Study",
        type: "evalmodel",
        evalmodel: JSON.stringify(
          Blockly.serialization.workspaces.save(primaryWorkspace.current)
        ),
      });
      // then run the model in preview mode if applicable
      ////// ***** 3/13  taking out for now
      // if (instantPreview.current.checked) {
      //   // window.LoopTrap = 1000;
      //   // javascriptGenerator.INFINITE_LOOP_TRAP =
      //   //   'if(--window.LoopTrap == 0) throw "Infinite loop.";\n';
      //   var code = javascriptGenerator.workspaceToCode(
      //     primaryWorkspace.current
      //   );
      //   props.runModel(code);
      // }
    } else if (change.type === "var_create") console.log(change.varName);
  };
  const blocklyDiv = useRef();

  const toolbox = useRef();
  let primaryWorkspace = useRef(false);
  const startBlocks = useRef();

  const saveAs = () => {
    let workspaceState = JSON.stringify(
      Blockly.serialization.workspaces.save(primaryWorkspace.current)
    );
    var pom = document.createElement("a");
    pom.setAttribute(
      "href",
      "data:text/plain;charset=urf-8," + encodeURIComponent(workspaceState)
    );
    pom.setAttribute("download", "model.mbe");
    pom.click();
  };

  const loadSTmodel = useCallback(() => {
    loadingModel.current = true;
    console.log("loading");
    Blockly.serialization.workspaces.load(
      JSON.parse(study.evalmodel),
      primaryWorkspace.current
    );
    loadingModel.current = false;
  }, [study.evalmodel]);

  const loadModel = (evt) => {
    loadingModel.current = true;
    let f = evt.target.files[0];
    let fileName = document.getElementById("fileName").value;
    var fileContents;
    let r = new FileReader();
    r.onload = function (e) {
      console.log(e);
      fileContents = JSON.parse(e.target.result);
    };
    r.addEventListener(
      "loadend",
      () => {
        Blockly.serialization.workspaces.load(
          fileContents,
          primaryWorkspace.current
        );
      },
      false
    );
    r.readAsText(f);
    document.getElementById("fileName").value = null;
    loadingModel.current = false;
  };
  const loadFromLS = () => {
    if (localStorage.getItem("evalModel")) {
      loadingModel.current = true;
      Blockly.serialization.workspaces.load(
        JSON.parse(localStorage.getItem("evalModel")),
        primaryWorkspace.current
      );
      loadingModel.current = false;
    }
  };

  const runModel = (diffOverride = false) => {
    // declare a global variable for each DV and each ATT, modified by eval below
    var code = javascriptGenerator.workspaceToCode(primaryWorkspace.current);
    props.runModel(code, diffOverride);
  };

  useEffect(() => {
    const { initialToolbox, children, ...rest } = props;
    let startingBlocks = { blocks: { blocks: [] } };
    if (initialToolbox) {
      toolbox.current = initialToolbox;
      let dvArray = [];
      let dcsArray = [];
      let cvArray = [];
      let prepsArray = [];
      if (contextVariables) {
        // insert the category
        if (
          toolbox.current.contents.filter(
            (content) => content.name === "Context Variables"
          ).length < 1
        )
          toolbox.current.contents.splice(2, 0, {
            kind: "category",
            name: "Context Variables",
            cssConfig: {
              container: "customCategory",
              icon: "customIcon fas fa-globe-americas",
            },
          });
        Blockly.common.defineBlocksWithJsonArray([
          {
            type: "contextvariable_num",
            message0: "%1 %2",
            args0: [
              {
                type: "field_image",
                src: "/images/cv_num.png",
                width: 50,
                height: 30,
                alt: "*",
                flipRtl: false,
              },
              {
                type: "field_label_serializable",
                name: "CVNAME",
                text: "",
                spellcheck: false,
              },
            ],
            output: "Number",
            colour: 185,
          },
        ]);
        Blockly.common.defineBlocksWithJsonArray([
          {
            type: "contextvariable_text",
            message0: "%1 %2",
            args0: [
              {
                type: "field_image",
                src: "/images/cv_any.png",
                width: 50,
                height: 30,
                alt: "*",
                flipRtl: false,
              },
              {
                type: "field_label_serializable",
                name: "CVNAME",
                text: "",
                spellcheck: false,
              },
            ],
            tooltip:
              "Each CV should be used as input to some portion of your model.",
            output: "String",
            colour: 185,
          },
        ]);

        javascriptGenerator["contextvariable_num"] = function (block) {
          return [
            "window['cv']['" + block.getFieldValue("CVNAME") + "']",
            javascriptGenerator.ORDER_ATOMIC,
          ];
        };
        javascriptGenerator["contextvariable_text"] = function (block) {
          return [
            "window['cv']['" + block.getFieldValue("CVNAME") + "']",
            javascriptGenerator.ORDER_ATOMIC,
          ];
        };
        contextVariables.forEach((cv, i) => {
          // dvArray.push({kind:"block",type:"variables_get",fields:{"VAR":{"name":dvs[dv]}}})
          cvArray.push({
            kind: "block",
            type: "contextvariable_" + cv.type,
            fields: { CVNAME: cv.name },
          });
          // startingBlocks.blocks.blocks.push({
          //   kind: "block",
          //   type: "contextvariable_" + cv.type,
          //   fields: { CVNAME: cv.name },
          //   x: 30,
          //   y: 30 * (i + 1),
          // });
        });
        toolbox.current.contents[2]["contents"] = cvArray;
      }
      if (alternativeVariables) {
        Blockly.common.defineBlocksWithJsonArray([
          {
            type: "designvariable_num",
            message0: "%1 %2",
            args0: [
              {
                type: "field_image",
                src: "/images/dv_num.png",
                width: 50,
                height: 30,
                alt: "*",
                flipRtl: false,
              },
              {
                type: "field_label_serializable",
                name: "DVNAME",
                text: "",
                spellcheck: false,
              },
            ],
            output: "Number",
            colour: 185,
          },
        ]);
        Blockly.common.defineBlocksWithJsonArray([
          {
            type: "designvariable_text",
            message0: "%1 %2",
            args0: [
              {
                type: "field_image",
                src: "/images/dv_any.png",
                width: 50,
                height: 30,
                alt: "*",
                flipRtl: false,
              },
              {
                type: "field_label_serializable",
                name: "DVNAME",
                text: "",
                spellcheck: false,
              },
            ],
            tooltip:
              "Each DV should be used as input to some portion of your model.",
            output: "String",
            colour: 185,
          },
        ]);

        javascriptGenerator["designvariable_num"] = function (block) {
          return [
            "window['dv']['" + block.getFieldValue("DVNAME") + "']",
            javascriptGenerator.ORDER_ATOMIC,
          ];
        };
        javascriptGenerator["designvariable_text"] = function (block) {
          return [
            "window['dv']['" + block.getFieldValue("DVNAME") + "']",
            javascriptGenerator.ORDER_ATOMIC,
          ];
        };
        alternativeVariables.forEach((dv, i) => {
          // dvArray.push({kind:"block",type:"variables_get",fields:{"VAR":{"name":dvs[dv]}}})
          dvArray.push({
            kind: "block",
            type: "designvariable_" + dv.type,
            fields: { DVNAME: dv.name },
          });
          // startingBlocks.blocks.blocks.push({
          //   kind: "block",
          //   type: "designvariable_" + dv.type,
          //   fields: { DVNAME: dv.name },
          //   x: 30,
          //   y: 30 * (i + 1),
          // });
        });
        toolbox.current.contents[
          toolbox.current.contents.findIndex(
            (content) => content.name === "Alternative Vars"
          )
        ]["contents"] = dvArray;
      }
      if (valueCriteria) {
        valueCriteria.forEach((att, i) => {
          Blockly.Blocks["set_attribute" + valueCriteria[i].id] = {
            init: function () {
              if (valueCriteria[i].type === "num")
                this.appendDummyInput().appendField(
                  new Blockly.FieldImage("/images/att_num.png", 50, 30, {
                    alt: "*",
                    flipRtl: "FALSE",
                  })
                );
              else
                this.appendDummyInput().appendField(
                  new Blockly.FieldImage("/images/att_any.png", 50, 30, {
                    alt: "*",
                    flipRtl: "FALSE",
                  })
                );

              this.appendDummyInput().appendField(
                'Set "' + valueCriteria[i].name + '" to:'
              );
              this.setCommentText(
                "Each attribute should be 'set' at some point in your model."
              );
              if (valueCriteria[i].type === "num")
                this.appendValueInput("VALUE").setCheck("Number");
              else this.appendValueInput("VALUE");
              this.setPreviousStatement(true);
              this.setNextStatement(true);
              this.setInputsInline(true);
              this.setColour(165);
              this.setEditable(false);
            },
          };
          javascriptGenerator["set_attribute" + valueCriteria[i].id] =
            function (block) {
              var argument0 = javascriptGenerator.valueToCode(
                block,
                "VALUE",
                javascriptGenerator.ORDER_ASSIGNMENT
              );
              if (!argument0) argument0 = "'NOT EVALUATED'";
              return (
                "window['dc']['" +
                valueCriteria[i].name +
                "'] = " +
                argument0 +
                ";"
              );
            };
          // dvArray.push({kind:"block",type:"variables_get",fields:{"VAR":{"name":dvs[dv]}}})
          dcsArray.push({
            kind: "block",
            type: "set_attribute" + valueCriteria[i].id,
          });
          startingBlocks.blocks.blocks.push({
            kind: "block",
            type: "set_attribute" + valueCriteria[i].id,
            x: 30,
            y: 30 * (i + 1),
          });
        });
        toolbox.current.contents[
          toolbox.current.contents.findIndex(
            (content) => content.name === "Value Criteria"
          )
        ]["contents"] = dcsArray;
        oldValueCriteria.current = valueCriteria;
      }
      if (preparations) {
        // insert the category
        if (
          toolbox.current.contents.filter(
            (content) => content.name === "Preparations"
          ).length < 1
        )
          toolbox.current.contents.splice(3, 0, {
            kind: "category",
            name: "Preparations",
            cssConfig: {
              container: "customCategory",
              icon: "customIcon fas fa-chess-rook",
            },
          });
        Blockly.common.defineBlocksWithJsonArray([
          {
            type: "preparation",
            message0: "%1 %2",
            args0: [
              {
                type: "field_image",
                src: "/images/prep_any.png",
                width: 50,
                height: 30,
                alt: "*",
                flipRtl: false,
              },
              {
                type: "field_label_serializable",
                name: "PREPNAME",
                text: "",
                spellcheck: false,
              },
            ],
            tooltip:
              "Each Preparation should be used as input to some portion of your model.",
            output: "String",
            colour: 195,
          },
        ]);

        javascriptGenerator["preparation"] = function (block) {
          return [
            "window['prep']['" + block.getFieldValue("PREPNAME") + "']",
            javascriptGenerator.ORDER_ATOMIC,
          ];
        };
        preparations.forEach((prep, i) => {
          prepsArray.push({
            kind: "block",
            type: "preparation",
            fields: { PREPNAME: prep.name },
          });
          // startingBlocks.blocks.blocks.push({
          //   kind: "block",
          //   type: "preparation",
          //   fields: { PREPNAME: prep.name },
          //   x: 30,
          //   y: 30 * (i + 1),
          // });
        });
        toolbox.current.contents[3]["contents"] = prepsArray;
      }
      startBlocks.current = startingBlocks;
    }
    if (!primaryWorkspace.current) {
      //javascriptGenerator.nameDB_.getName(block.getFieldValue('VAR'), Blockly.Names.NameType.VARIABLE);
      primaryWorkspace.current = Blockly.inject(blocklyDiv.current, {
        toolbox: toolbox.current,
        zoom: { controls: true, pinch: true, wheel: true },
        ...rest,
      });
    }

    // Blockly.serialization.workspaces.load(
    //   startBlocks.current,
    //   primaryWorkspace.current
    // );
    loadFromLS();
  }, []);

  useEffect(() => {
    primaryWorkspace.current.removeChangeListener(changeHandler.current);
    changeHandler.current = primaryWorkspace.current.addChangeListener(
      (change) => {
        handleModelChange(change);
      }
    );
  }, [props.runModel, alternativeVariables]);

  ///// when DVs are added/removed
  useEffect(() => {
    primaryWorkspace.current
      .getBlocksByType("designvariable_num")
      .forEach((block) => {
        if (
          alternativeVariables
            .map((pdv) => pdv.name)
            .indexOf(block.getFieldValue("DVNAME")) < 0
        )
          block.dispose();
      });
    primaryWorkspace.current
      .getBlocksByType("designvariable_text")
      .forEach((block) => {
        if (
          alternativeVariables
            .map((pdv) => pdv.name)
            .indexOf(block.getFieldValue("DVNAME")) < 0
        )
          block.dispose();
      });

    if (alternativeVariables.length > 0) {
      // first update toolbox
      let dvArray = [];
      alternativeVariables.forEach((dv, i) => {
        let name = dv.name;
        dvArray.push({
          kind: "block",
          type: "designvariable_" + dv.type,
          fields: { DVNAME: name },
        });
      });
      let newToolbox = toolbox.current;
      newToolbox.contents[
        toolbox.current.contents.findIndex(
          (content) => content.name === "Alternative Vars"
        )
      ]["contents"] = dvArray;
      toolbox.current = newToolbox;

      primaryWorkspace.current.updateToolbox(toolbox.current);
    }
  }, [alternativeVariables]);

  ///// when CVs are added/removed
  useEffect(() => {
    primaryWorkspace.current
      .getBlocksByType("contextvariable_num")
      .forEach((block) => {
        if (
          contextVariables
            .map((pcv) => pcv.name)
            .indexOf(block.getFieldValue("CVNAME")) < 0
        )
          block.dispose();
      });
    primaryWorkspace.current
      .getBlocksByType("contextvariable_text")
      .forEach((block) => {
        if (
          contextVariables
            .map((pcv) => pcv.name)
            .indexOf(block.getFieldValue("CVNAME")) < 0
        )
          block.dispose();
      });

    if (contextVariables.length > 0) {
      // if category doesn't exist
      if (
        toolbox.current.contents.filter(
          (content) => content.name === "Context Variables"
        ).length < 1
      )
        toolbox.current.contents.splice(2, 0, {
          kind: "category",
          name: "Context Variables",
          cssConfig: {
            container: "customCategory",
            icon: "customIcon fas fa-globe-americas",
          },
        });
      // first update toolbox
      let cvArray = [];
      contextVariables.forEach((cv, i) => {
        let name = cv.name;
        cvArray.push({
          kind: "block",
          type: "contextvariable_" + cv.type,
          fields: { CVNAME: name },
        });
      });
      let newToolbox = toolbox.current;
      newToolbox.contents[2]["contents"] = cvArray;
      toolbox.current = newToolbox;

      primaryWorkspace.current.updateToolbox(toolbox.current);
    } else if (
      toolbox.current.contents.findIndex(
        (content) => content.name === "Context Variables"
      ) >= 0
    ) {
      let newToolbox = toolbox.current;
      newToolbox.contents.splice(
        newToolbox.contents.findIndex(
          (content) => content.name === "Context Variables"
        ),
        1
      );
      toolbox.current = newToolbox;
      primaryWorkspace.current.updateToolbox(toolbox.current);
    }
  }, [contextVariables]);

  ///// when Preps are added/removed
  useEffect(() => {
    primaryWorkspace.current.getBlocksByType("preparation").forEach((block) => {
      if (
        preparations
          .map((prep) => prep.name)
          .indexOf(block.getFieldValue("PREPNAME")) < 0
      )
        block.dispose();
    });

    if (preparations.length > 0) {
      // if category doesn't exist
      if (
        toolbox.current.contents.filter(
          (content) => content.name === "Preparations"
        ).length < 1
      )
        toolbox.current.contents.splice(3, 0, {
          kind: "category",
          name: "Preparations",
          cssConfig: {
            container: "customCategory",
            icon: "customIcon fas fa-chess-rook",
          },
        });
      // first update toolbox
      let prepArray = [];
      preparations.forEach((prep, i) => {
        let name = prep.name;
        prepArray.push({
          kind: "block",
          type: "preparation",
          fields: { PREPNAME: name },
        });
      });
      let newToolbox = toolbox.current;
      newToolbox.contents[3]["contents"] = prepArray;
      toolbox.current = newToolbox;

      primaryWorkspace.current.updateToolbox(toolbox.current);
    } else if (
      toolbox.current.contents.findIndex(
        (content) => content.name === "Preparations"
      ) >= 0
    ) {
      let newToolbox = toolbox.current;
      newToolbox.contents.splice(
        newToolbox.contents.findIndex(
          (content) => content.name === "Preparations"
        ),
        1
      );
      toolbox.current = newToolbox;

      primaryWorkspace.current.updateToolbox(toolbox.current);
    }
  }, [preparations]);

  ///// **** WRITE ADD/REMOVE ATTS
  useEffect(() => {
    if (study.evalmodel==="{}") return;
    oldValueCriteria.current.forEach((ovc) => {
      console.log("OldValueCriteria:", oldValueCriteria.current);
      primaryWorkspace.current
        .getBlocksByType("set_attribute" + ovc.id)
        .forEach((block) => {
          console.log(
            "ovc: ",
            ovc,
            "found: ",
            valueCriteria.find((att) => att.id === ovc.id)
          );
          if (
            !valueCriteria ||
            !valueCriteria.find((att) => att.id === ovc.id)
          ) {
            if (block.nextConnection) block.nextConnection.disconnect();
            block.dispose();
          }
        });
    });
    let dcsArray = [];
    if (valueCriteria) {
      valueCriteria.forEach((att, i) => {
        Blockly.Blocks["set_attribute" + valueCriteria[i].id] = {
          init: function () {
            if (valueCriteria[i].type === "num")
              this.appendDummyInput().appendField(
                new Blockly.FieldImage("/images/att_num.png", 50, 30, {
                  alt: "*",
                  flipRtl: "FALSE",
                })
              );
            else
              this.appendDummyInput().appendField(
                new Blockly.FieldImage("/images/att_any.png", 50, 30, {
                  alt: "*",
                  flipRtl: "FALSE",
                })
              );

            this.appendDummyInput().appendField(
              'Set "' + valueCriteria[i].name + '" to:'
            );
            this.setCommentText(
              "Each attribute should be assigned an input at some point in your model."
            );
            if (valueCriteria[i].type === "num")
              this.appendValueInput("VALUE").setCheck("Number");
            else this.appendValueInput("VALUE");
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setInputsInline(true);
            this.setColour(165);
            this.setEditable(false);
          },
        };
        javascriptGenerator["set_attribute" + valueCriteria[i].id] = function (
          block
        ) {
          var argument0 = javascriptGenerator.valueToCode(
            block,
            "VALUE",
            javascriptGenerator.ORDER_ASSIGNMENT
          );
          if (!argument0) argument0 = "'NOT EVALUATED'";
          return (
            "window['dc']['" + valueCriteria[i].name + "'] = " + argument0 + ";"
          );
        };
        // dvArray.push({kind:"block",type:"variables_get",fields:{"VAR":{"name":dvs[dv]}}})
        dcsArray.push({
          kind: "block",
          type: "set_attribute" + valueCriteria[i].id,
        });
        // startingBlocks.blocks.blocks.push({kind:"block",type:"designvariable",fields:{DVNAME:dv},"x":30,"y":30*(i+1)})
      });
    }
    let newToolbox = toolbox.current;
    newToolbox.contents[
      toolbox.current.contents.findIndex(
        (content) => content.name === "Value Criteria"
      )
    ]["contents"] = dcsArray;
    toolbox.current = newToolbox;

    primaryWorkspace.current.updateToolbox(toolbox.current);
    oldValueCriteria.current = valueCriteria;
    // capture intermediate vars
    javascriptGenerator["variables_set"] = function (block) {
      var argument0 = javascriptGenerator.valueToCode(
        block,
        "VALUE",
        javascriptGenerator.ORDER_ASSIGNMENT
      );
      var argument1 = javascriptGenerator.nameDB_.getName(
        block.getFieldValue("VAR"),
        Blockly.Names.NameType.VARIABLE
      );
      // TODO:  insert this variable into the window array of intermediate vars!
      return (
        "window['intermediateVars'].push({name:'" +
        argument1 +
        "',level:" +
        argument0 +
        "});" +
        argument1 +
        "=" +
        argument0 +
        ";"
      );
    };
  }, [valueCriteria]);

  useEffect(() => {
    if (!props.loadInitial && study.evalmodel) {
      loadSTmodel();
      props.setLoadInitial(true);
    }
  }, [loadSTmodel, study.evalmodel,props]);

  return (
    <React.Fragment>
      <div
        ref={blocklyDiv}
        id="blocklyDiv"
        onClick={() => {
          !props.previewPin && props.setPreviewPoppedOut(false);
        }}
      />
      <div style={{ display: "none" }} ref={toolbox}>
        {props.children}
      </div>
      <div className="runModel">
        {/* <input
          type="checkbox"
          defaultChecked={true}
          ref={instantPreview}
          id="instantPreview"
          name="instantPreview"
          title="Instant Preview"
        /> */}
        {/* <label htmlFor="instantPreview">Instant Preview</label> */}
        <button
          onClick={() => {
            props.setPreviewPoppedOut(true);
            runModel(true);
          }}
          // disabled={instantPreview.current.checked}
        >
          Run Eval Model
        </button>
      </div>
      <div onClick={saveAs} className="saveFile">
        <img
          src="/images/saveEvalModel.png"
          className="openSaveIcon"
          title="Save eval model as .mbe file"
          alt="Save eval model as .mbe file"
        />
      </div>
      <label htmlFor="fileName" className="loadFile">
        <img
          src="/images/openEvalModel.png"
          className="openSaveIcon"
          title="Load eval model from .mbe"
          alt="Upload saved eval model"
        />
        <input type="file" id="fileName" name="fileName" onChange={loadModel} />
      </label>
      <div>
        {localStorage.getItem("evalModel") && (
          <span onClick={loadFromLS}>Load temp saved</span>
        )}
      </div>
    </React.Fragment>
  );
}

export default BlocklyComponent;
