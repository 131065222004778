import ModelSection from "./components/ModelSection";
import { AuthProvider, useAuth } from "./util/auth.js";
import TitleBar from "./components/TitleBar";
import "./App.css";
import firebase from "./util/firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { useState } from "react";
import { StudyProvider } from "./components/StudyContext";
import {Analytics} from "@vercel/analytics/react";

import { enablePatches } from "immer";
function App() {
  enablePatches();
  var uiConfig = {
    // signInSuccessUrl: "#",
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
    ],
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: "<your-tos-url>",
    // Privacy policy url/callback.
    privacyPolicyUrl: function () {
      window.location.assign("<your-privacy-policy-url>");
    },
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        // Handle the result
        //console.log(authResult);
        return false;
      },
    },
  };

  // Initialize the FirebaseUI Widget using Firebase.
  var ui =
    firebaseui.auth.AuthUI.getInstance() ||
    new firebaseui.auth.AuthUI(firebase.auth());
  // The start method will wait until the DOM is loaded.

  const startSignin = () => ui.start(".signinbar", uiConfig);
  const [user, setUser] = useState(null);
  firebase.auth().onAuthStateChanged((user) => {
    if (!user) {
      startSignin();
    }
    setUser(user);
  });
  return (
    <><Analytics/>
    
    <AuthProvider>
      <StudyProvider user={user}>
                      <TitleBar startSignin={startSignin} user={user} />
                      {user && <ModelSection
                        bg="white"
                        textColor="dark"
                        size="md"
                        bgImage=""
                        bgImageOpacity={1}
                        title="EMBARC 2.0"
                        subtitle="Begin with a simple, instant-feedback model."
                        user={user}
                      />}
      </StudyProvider>
    </AuthProvider>
    </>
  );
}

export default App;
