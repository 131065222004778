import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Table from "react-bootstrap/esm/Table";
import CustomNavIcon from "../CustomNavIcon";
import ErrorIcon from "../ErrorIcon";
import { cartesianProduct } from "../../../util/helpers";

import SectionNotificationsHeader from "../SectionNotificationsHeader";
import { onlyUnique } from "../../../util/helpers";
import { StudyProvider, useStudy, useStudyDispatch } from "../../StudyContext";

function EVSection(props) {
  const study = useStudy();
  const studyDispatch= useStudyDispatch();
  const stakeholders = study.stakeholders;
  const valueCriteria = study.valueCriteria;
  const alternativeVariables = study.alternativeVariables;
  const epochVariables = study.epochVariables;
  const errors = study.errors;

  const evName = useRef();
  const evNumType = useRef();
  const evTimescale = useRef();
  const evLevels = useRef();

  const [validatedEV, setValidatedEV] = useState(false);
  /// NEED TO WRITE REAL REGEX VALIDATION PROBABLY AT SOME POINT
  const validateEV = () => {
    if (
      evName.current.value.length > 0 &&
      epochVariables.filter((ev) => ev.name === evName.current.value).length <
        1 &&
      alternativeVariables.filter((dv) => dv.name === evName.current.value)
        .length < 1
    )
      setValidatedEV(true);
    else setValidatedEV(false);
  };

  const addEV = () => {
    studyDispatch({section:"Epoch Variables",
      type: "added",
      ev: {
        name: evName.current.value,
        type: evNumType.current.value,
        timescale: evTimescale.current.value,
        levels: evLevels.current.value.split(",").filter(onlyUnique),
      },
    });
    evName.current.value = "";
    evLevels.current.value = "";
  };

  const setBaseline = (epVarId, lev) => {
    studyDispatch({section:"Epoch Variables",
      type: "setBaseline",
      evId: epVarId,
      baselineLevel: lev,
    });
  };

  return (
    <Form.Group className="mb-3 EEASection">
      <h4>Epoch Variables</h4>
      Factors outside of your control. &nbsp;AKA Environment Variables or
      Scenario Variables. Changing levels of these affect:
      <ul>
        <li style={{ listStyleType: "none", paddingTop: "5px" }}>
          <div style={{ width: "30px", float: "left", marginRight: "15px" }}>
            <CustomNavIcon type="fas fa-calculator" />
          </div>{" "}
          <em>evaluation</em> of value criteria (via the evaluative model), in
          which case they are Context Variables;
        </li>
        <li style={{ listStyleType: "none", margin: "5px" }}>and/or</li>
        <li style={{ listStyleType: "none" }}>
          <div style={{ width: "30px", float: "left", marginRight: "15px" }}>
            <CustomNavIcon type="fas fa-eye" />
          </div>
          <em>valuation</em> of value criteria (via stakeholder value models),
          in which case they are Preference Variables.
        </li>
      </ul>
      <SectionNotificationsHeader sectionName="Epoch Variables" />
      <Table striped={true} cellPadding={3}>
        <thead className="tableHeader">
          {epochVariables &&
            epochVariables.filter((ev) => ev.levels.length > 1).length > 0 && (
              <tr>
                <td colSpan={7}></td>

                <td
                  colSpan={valueCriteria.length + stakeholders.length}
                  style={{ borderLeft: "1px solid gray" }}
                  align="center"
                >
                  Est. Impact On:
                </td>
              </tr>
            )}

          <tr>
            <td></td>
            <td></td>
            <td>Name</td>
            <td>Number Type</td>
            <td>Timescale</td>
            <td>Levels</td>
            <td></td>
            {epochVariables &&
              epochVariables.filter((ev) => ev.levels.length > 1).length > 0 &&
              valueCriteria &&
              valueCriteria.map((dc, i) => (
                <td
                  key={dc.id}
                  align="center"
                  style={{ borderLeft: i === 0 ? "1px solid gray" : "none" }}
                >
                  <i
                    style={{ cursor: "pointer" }}
                    className="fas fa-gem"
                    onClick={() => props.setActiveNavTab("Value Criteria")}
                  />{" "}
                  {dc.name}
                </td>
              ))}
            {epochVariables &&
              epochVariables.filter((ev) => ev.levels.length > 1).length > 0 &&
              stakeholders &&
              stakeholders.map((sh, i) => (
                <td
                  key={sh.id}
                  align="center"
                  style={{ borderLeft: i === 0 ? "1px solid gray" : "none" }}
                >
                  <i
                    style={{ cursor: "pointer" }}
                    className="fas fa-user"
                    onClick={() => props.setActiveNavTab("Stakeholders")}
                  />{" "}
                  {sh.name}
                </td>
              ))}
          </tr>
        </thead>
        <tbody>
          {epochVariables &&
            epochVariables.map((ev) => {
              return (
                <tr key={ev.name} className="dcDetails">
                  <td width={40}>
                    {ev.levels.length <= 1 ? (
                      <a
                        href="#"
                        title={
                          "This epoch variable only has 1 level, i.e. it never changes. Consider documenting it as part of the baseline epoch and removing it from this project."
                        }
                      >
                        <i className="fa fa-exclamation-circle" />
                      </a>
                    ) : ev.context || ev.preference ? (
                      <small>
                        <small>
                          <CustomNavIcon
                            type={
                              ev.context && ev.preference
                                ? "both"
                                : ev.context
                                ? "fas fa-calculator"
                                : "fas fa-eye"
                            }
                          />
                        </small>
                      </small>
                    ) : errors.find(
                        (er) =>
                          !er.ignored &&
                          er.affects === "Epoch Variables" &&
                          er.affectsId === ev.id
                      ) ? (
                      <ErrorIcon
                        description={
                          errors.find(
                            (er) =>
                              er.affects === "Epoch Variables" &&
                              er.affectsId === ev.id
                          ).description
                        }
                      />
                    ) : (
                      <i className="fas fa-exclamation" />
                    )}
                  </td>
                  <td>
                    <i
                      className="fas fa-minus-circle removeIcon"
                      onClick={() =>
                        studyDispatch({section:"Epoch Variables", type: "deleted", id: ev.id })
                      }
                    />
                  </td>
                  <td>{ev.name}</td>
                  <td>{ev.type==="text"?"categorical":"number"}</td>
                  <td>{ev.timescale}</td>
                  <td colSpan={2}>
                    {ev.levels.map((lev, i) => (
                      <span
                        key={i}
                        onClick={() => {
                          ev.baseline !== lev && setBaseline(ev.id, lev);
                        }}
                        className={
                          "dvLevs" + (ev.baseline === lev ? " baseline" : "")
                        }
                      >
                        {lev}
                        {i === ev.levels.length - 1 ? "" : ","}{" "}
                      </span>
                    ))}
                  </td>
                  {ev.levels.length > 1 &&
                    valueCriteria &&
                    valueCriteria.map((dc) => (
                      <td
                        key={ev.name + dc.id}
                        align="center"
                        style={{ borderLeft: "1px solid gray" }}
                      >
                        <div
                          onClick={() =>
                            studyDispatch({section:"Epoch Variables",type: "changeVC",
                              vc:dc,ev: ev,
                            })
                          }
                          className="changePrefs"
                        >
                          <i
                            className={
                              !ev.dcs.find((cvdc) => cvdc.id === dc.id)
                                ? "fas fa-ban noPrefs"
                                : ev.dcs.find((cvdc) => cvdc.id === dc.id)
                                    .level === "low"
                                ? "fas fa-th low"
                                : ev.dcs.find((cvdc) => cvdc.id === dc.id)
                                    .level === "med"
                                ? "fas fa-th med"
                                : "fas fa-th high"
                            }
                          />
                        </div>
                      </td>
                    ))}
                  {ev.levels.length > 1 &&
                    stakeholders.map((sh) => (
                      <td
                        key={ev.name + sh.id}
                        align="center"
                        style={{ borderLeft: "1px solid gray" }}
                      >
                        <div
                          onClick={() => studyDispatch({section:"Epoch Variables",type:"toggleSH",sh:sh,ev:ev})}
                          className="changePrefs"
                        >
                          <i
                            className={
                              !ev.stakeholders.find((evsh) => evsh.id === sh.id)
                                ? "fas fa-ban noPrefs"
                                : "fas fa-check"
                            }
                          />
                        </div>
                      </td>
                    ))}
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td>
              <Form.Control
                type="name"
                ref={evName}
                placeholder="e.g., Fuel Price, Supply Chain Functionality"
                onChange={validateEV}
              />
            </td>
            <td>
              <select name="NumType" ref={evNumType}>
                <option value="num">Number</option>
                <option value="text">Categorical</option>
              </select>
            </td>
            <td>
              <select name="Timescale" ref={evTimescale}>
                {!epochVariables.find((evs) => evs.timescale === "years") &&
                  !epochVariables.find(
                    (evs) => evs.timescale === "decades"
                  ) && <option value="days">Days</option>}
                {!epochVariables.find((evs) => evs.timescale === "decades") && (
                  <option value="months">Months</option>
                )}
                {!epochVariables.find((evs) => evs.timescale === "days") && (
                  <option value="years">Years</option>
                )}
                {!epochVariables.find((evs) => evs.timescale === "days") &&
                  !epochVariables.find((evs) => evs.timescale === "months") && (
                    <option value="decades">Decades</option>
                  )}
              </select>
            </td>
            <td colSpan={2}>
              <Form.Control
                type="name"
                ref={evLevels}
                placeholder="Enter comma-separated levels"
                onChange={validateEV}
              />
            </td>
            <td>
              <button onClick={addEV} disabled={!validatedEV}>
                Add
              </button>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td colSpan={2}>
              {!epochVariables ||
              epochVariables.length === 0 ||
              (epochVariables.length === 1 &&
                epochVariables[0].levels.length === 1)
                ? "1 default " +
                  (!epochVariables || epochVariables.length === 0
                    ? "(implicit)"
                    : "") +
                  " epoch"
                : (epochVariables.length > 1 ? "Full factorial: " : "") +
                  cartesianProduct(epochVariables.map((ev) => ev.levels))
                    .length +
                  " epochs"}
            </td>
          </tr>
        </tfoot>
      </Table>
    </Form.Group>
  );
}
export default EVSection;
