const stevalmodel = JSON.stringify({
  blocks: {
    languageVersion: 0,
    blocks: [
      {
        type: "procedures_defreturn",
        id: "f9+5`za7tE._S,e}26^,",
        x: 13,
        y: 14,
        icons: {
          comment: {
            text: "Describe this function...",
            pinned: false,
            height: 80,
            width: 160,
          },
        },
        fields: { NAME: "BMF" },
        inputs: {
          RETURN: {
            block: {
              type: "math_number",
              id: "lA$Wkwkz(7|SEE1^h+x0",
              fields: { NUM: 1 },
            },
          },
        },
      },
      {
        type: "procedures_defreturn",
        id: "Uve,3W)+/4QA85Q,/15e",
        x: 13,
        y: 98,
        icons: {
          comment: {
            text: "Describe this function...",
            pinned: false,
            height: 80,
            width: 160,
          },
        },
        fields: { NAME: "Costwet" },
        inputs: {
          RETURN: {
            block: {
              type: "math_number",
              id: "T:s*w21Z$x/;x|FQ{s8)",
              fields: { NUM: 20 },
            },
          },
        },
      },
      {
        type: "procedures_defreturn",
        id: "`{+4etRc[%Mo))Q9YGr_",
        x: 12,
        y: 184,
        icons: {
          comment: {
            text: "Describe this function...",
            pinned: false,
            height: 80,
            width: 160,
          },
        },
        fields: { NAME: "CostDry" },
        inputs: {
          RETURN: {
            block: {
              type: "math_number",
              id: "Ih1??drt5H[}V6xzuJd1",
              fields: { NUM: 150 },
            },
          },
        },
      },
      {
        type: "procedures_defreturn",
        id: "gkT19Yn4dm6%yB;Xls,W",
        x: 13,
        y: 269,
        icons: {
          comment: {
            text: "Describe this function...",
            pinned: false,
            height: 80,
            width: 160,
          },
        },
        fields: { NAME: "Gee" },
        inputs: {
          RETURN: {
            block: {
              type: "math_number",
              id: "5qayW0O$-15)Cw|p8g.$",
              fields: { NUM: 9.8 },
            },
          },
        },
      },
      {
        type: "variables_set",
        id: ",MtpR*(p:d:6.Q4%C9l$",
        x: 349,
        y: -208,
        fields: { VAR: { id: "hO6/*NtJ+@ahk8[nt`tQ" } },
        inputs: {
          VALUE: {
            block: {
              type: "math_arithmetic",
              id: "+QP!ymxGUG@@u%JW/CQS",
              fields: { OP: "ADD" },
              inputs: {
                A: {
                  block: {
                    type: "math_arithmetic",
                    id: "?~[a]8wtia5e,nV%Nre2",
                    fields: { OP: "MULTIPLY" },
                    inputs: {
                      A: {
                        block: {
                          type: "procedures_callreturn",
                          id: "!-%QwR7FX9m$OF6#$8#|",
                          extraState: { name: "BMF" },
                        },
                      },
                      B: {
                        block: {
                          type: "designvariable_num",
                          id: "Y])K|!cD2O/coB(k4i*r",
                          fields: { DVNAME: "Payload" },
                        },
                      },
                    },
                  },
                },
                B: {
                  block: {
                    type: "math_arithmetic",
                    id: "!LRy:w5rxhT_H+#mm~CW",
                    fields: { OP: "ADD" },
                    inputs: {
                      A: {
                        block: {
                          type: "procedures_callreturn",
                          id: "S]D+8aB!kY{N4WO~kdLe",
                          extraState: { name: "PropTypeBaseMassKg" },
                        },
                      },
                      B: {
                        block: {
                          type: "math_arithmetic",
                          id: "|EE!uZL+!jdIt?cM,0Q:",
                          fields: { OP: "MULTIPLY" },
                          inputs: {
                            A: {
                              block: {
                                type: "designvariable_num",
                                id: "bR%#hDh)4N/R)$+[O[b5",
                                fields: { DVNAME: "FuelLevel" },
                              },
                            },
                            B: {
                              block: {
                                type: "procedures_callreturn",
                                id: "V|/O|o%etYFH,_ljfpdB",
                                extraState: { name: "PropTypeMassFraction" },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        next: {
          block: {
            type: "variables_set",
            id: "!}Zym5;(wJq6CYiIKX`)",
            fields: { VAR: { id: "0QB,!4]+$d9G8Lj!:?1)" } },
            inputs: {
              VALUE: {
                block: {
                  type: "math_arithmetic",
                  id: "K)|76^ynf^{pOiuwGtX+",
                  fields: { OP: "ADD" },
                  inputs: {
                    A: {
                      block: {
                        type: "variables_get",
                        id: "`RiWuRArx^w_n?II,ReB",
                        fields: { VAR: { id: "hO6/*NtJ+@ahk8[nt`tQ" } },
                      },
                    },
                    B: {
                      block: {
                        type: "designvariable_num",
                        id: "@?rS*=|2EcnND]0/5=AH",
                        fields: { DVNAME: "Payload" },
                      },
                    },
                  },
                },
              },
            },
            next: {
              block: {
                type: "variables_set",
                id: "W{C(U8*_+hF6!wYOOxp7",
                fields: { VAR: { id: "@`g-`m]Y@{b@R~L86P-3" } },
                inputs: {
                  VALUE: {
                    block: {
                      type: "math_arithmetic",
                      id: "b9o*B!DsR8uMK4@y_E]0",
                      fields: { OP: "ADD" },
                      inputs: {
                        A: {
                          block: {
                            type: "variables_get",
                            id: "GL[YEnB5i`T%VO_zMbyj",
                            fields: { VAR: { id: "0QB,!4]+$d9G8Lj!:?1)" } },
                          },
                        },
                        B: {
                          block: {
                            type: "designvariable_num",
                            id: "yWQ3Z!#[(ykaE_b_T$_^",
                            fields: { DVNAME: "FuelLevel" },
                          },
                        },
                      },
                    },
                  },
                },
                next: {
                  block: {
                    type: "variables_set",
                    id: "M!9^G^ZrT(M_:hF(f~#w",
                    fields: { VAR: { id: ".]6q4K,+=PiIU~i1TDZj" } },
                    inputs: {
                      VALUE: {
                        block: {
                          type: "procedures_callreturn",
                          id: "~O@7,n@#/0}X:9CI$!*N",
                          extraState: { name: "PropTypeIspSec" },
                        },
                      },
                    },
                    next: {
                      block: {
                        type: "set_attribute75273181-5733-4db0-a1cd-8195289e40c2",
                        id: "jhtJ@eU3sR4#Z$2i-i.7",
                        icons: {
                          comment: {
                            text: "Each attribute should be assigned an input at some point in your model.",
                            pinned: false,
                            height: 80,
                            width: 160,
                          },
                        },
                        inputs: {
                          VALUE: {
                            block: {
                              type: "procedures_callreturn",
                              id: "Ar,+!sZ7v)Z6T@lD#Q6}",
                              extraState: { name: "calcDeltaV" },
                            },
                          },
                        },
                        next: {
                          block: {
                            type: "set_attribute66638341-d8ee-4dcb-b400-8d40635d7cd2",
                            id: "HULDgu](rjzl1s7kP=le",
                            icons: {
                              comment: {
                                text: "Each attribute should be assigned an input at some point in your model.",
                                pinned: false,
                                height: 80,
                                width: 160,
                              },
                            },
                            inputs: {
                              VALUE: {
                                block: {
                                  type: "procedures_callreturn",
                                  id: "To^TYq;[~*0n7_@B1Gr(",
                                  extraState: { name: "DetermineCapability" },
                                },
                              },
                            },
                            next: {
                              block: {
                                type: "set_attributeb31f8ded-adcc-4e46-aca0-57bc5ee525e8",
                                id: ")qO;yoAj60dzt:)LS-(n",
                                icons: {
                                  comment: {
                                    text: "Each attribute should be assigned an input at some point in your model.",
                                    pinned: false,
                                    height: 80,
                                    width: 160,
                                  },
                                },
                                inputs: {
                                  VALUE: {
                                    block: {
                                      type: "procedures_callreturn",
                                      id: "1xZdUEvZs:0BFI8AR3Mf",
                                      extraState: { name: "DetermineSpeed" },
                                    },
                                  },
                                },
                                next: {
                                  block: {
                                    type: "set_attributed371a084-5265-4e2a-a2e2-631c6a83d634",
                                    id: "}*ky!]vJR|t4o7}/36%C",
                                    icons: {
                                      comment: {
                                        text: "Each attribute should be assigned an input at some point in your model.",
                                        pinned: false,
                                        height: 80,
                                        width: 160,
                                      },
                                    },
                                    inputs: {
                                      VALUE: {
                                        block: {
                                          type: "math_arithmetic",
                                          id: "2%B6s)g6h:{75mFdOKr}",
                                          fields: { OP: "DIVIDE" },
                                          inputs: {
                                            A: {
                                              block: {
                                                type: "math_arithmetic",
                                                id: "*t[hm%:3QAKF8CD?Y[LI",
                                                fields: { OP: "ADD" },
                                                inputs: {
                                                  A: {
                                                    block: {
                                                      type: "math_arithmetic",
                                                      id: "}T1cp(T?`uz_SugA4q-k",
                                                      fields: {
                                                        OP: "MULTIPLY",
                                                      },
                                                      inputs: {
                                                        A: {
                                                          block: {
                                                            type: "procedures_callreturn",
                                                            id: "XT+K]s;=LsA4O$W:K#;O",
                                                            extraState: {
                                                              name: "CostDry",
                                                            },
                                                          },
                                                        },
                                                        B: {
                                                          block: {
                                                            type: "variables_get",
                                                            id: "{c!.UAcb/=l{+X61/6lY",
                                                            fields: {
                                                              VAR: {
                                                                id: "0QB,!4]+$d9G8Lj!:?1)",
                                                              },
                                                            },
                                                          },
                                                        },
                                                      },
                                                    },
                                                  },
                                                  B: {
                                                    block: {
                                                      type: "math_arithmetic",
                                                      id: "NuNY74_1Rbyl;t`z+~+k",
                                                      fields: {
                                                        OP: "MULTIPLY",
                                                      },
                                                      inputs: {
                                                        A: {
                                                          block: {
                                                            type: "procedures_callreturn",
                                                            id: "-WNAypP.xP)y1H@z5_x.",
                                                            extraState: {
                                                              name: "Costwet",
                                                            },
                                                          },
                                                        },
                                                        B: {
                                                          block: {
                                                            type: "variables_get",
                                                            id: "^b_CLGw]7,E=4-BPF.qr",
                                                            fields: {
                                                              VAR: {
                                                                id: "@`g-`m]Y@{b@R~L86P-3",
                                                              },
                                                            },
                                                          },
                                                        },
                                                      },
                                                    },
                                                  },
                                                },
                                              },
                                            },
                                            B: {
                                              block: {
                                                type: "math_number",
                                                id: "|4ofRG[u`K8=He#viU9q",
                                                fields: { NUM: 1000 },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      {
        type: "procedures_defreturn",
        id: "DgKU[0hk:KVs84hQUS6x",
        x: 14,
        y: 357,
        icons: {
          comment: {
            text: "Describe this function...",
            pinned: false,
            height: 80,
            width: 160,
          },
        },
        fields: { NAME: "PropTypeBaseMassKg" },
        inputs: {
          STACK: {
            block: {
              type: "procedures_ifreturn",
              id: "#!7]||$982r{XOrW]/U0",
              extraState: '<mutation value="1"></mutation>',
              inputs: {
                CONDITION: {
                  block: {
                    type: "logic_compare",
                    id: "|/syNi8k0``S?FET3r!j",
                    fields: { OP: "EQ" },
                    inputs: {
                      A: {
                        block: {
                          type: "designvariable_text",
                          id: "=-y;8ed^K_OPVb8~1[;]",
                          fields: { DVNAME: "PropulsionType" },
                        },
                      },
                      B: {
                        block: {
                          type: "text",
                          id: "9,usNtchExO#Gue?;[C@",
                          fields: { TEXT: "Biprop" },
                        },
                      },
                    },
                  },
                },
                VALUE: {
                  block: {
                    type: "math_number",
                    id: "jwWF%X:eX7l}BB`O/sd1",
                    fields: { NUM: 0 },
                  },
                },
              },
              next: {
                block: {
                  type: "procedures_ifreturn",
                  id: "pX6S2Kb#lwwjIg#Vaqko",
                  extraState: '<mutation value="1"></mutation>',
                  inputs: {
                    CONDITION: {
                      block: {
                        type: "logic_compare",
                        id: "k0m{8.YWbW}rA(H7d#73",
                        fields: { OP: "EQ" },
                        inputs: {
                          A: {
                            block: {
                              type: "designvariable_text",
                              id: "[SlWzuw$z8{m*vC(CY-y",
                              fields: { DVNAME: "PropulsionType" },
                            },
                          },
                          B: {
                            block: {
                              type: "text",
                              id: "HjlZN+kqBR$8I8?e|{=^",
                              fields: { TEXT: "Cryo" },
                            },
                          },
                        },
                      },
                    },
                    VALUE: {
                      block: {
                        type: "math_number",
                        id: ",c`!ea`od,.JEr`PWT(Q",
                        fields: { NUM: 0 },
                      },
                    },
                  },
                  next: {
                    block: {
                      type: "procedures_ifreturn",
                      id: "!py$EzeT~obrwxb}V0.1",
                      extraState: '<mutation value="1"></mutation>',
                      inputs: {
                        CONDITION: {
                          block: {
                            type: "logic_compare",
                            id: "S^I|(f2J)4Yt2(yX_J|A",
                            fields: { OP: "EQ" },
                            inputs: {
                              A: {
                                block: {
                                  type: "designvariable_text",
                                  id: "$WzbSyCBzCkm4vPcsskE",
                                  fields: { DVNAME: "PropulsionType" },
                                },
                              },
                              B: {
                                block: {
                                  type: "text",
                                  id: "Qt^S~Y3K~f[c$AQ3+[9)",
                                  fields: { TEXT: "Electric" },
                                },
                              },
                            },
                          },
                        },
                        VALUE: {
                          block: {
                            type: "math_number",
                            id: "5+a{eDa#_h[,GzpX!#B3",
                            fields: { NUM: 25 },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          RETURN: {
            block: {
              type: "math_number",
              id: "(C8PBDVf/~JU^xHqF!{#",
              fields: { NUM: 1000 },
            },
          },
        },
      },
      {
        type: "procedures_defreturn",
        id: "${%a$VT$M.iNZWAuc*QM",
        x: 9,
        y: 626,
        icons: {
          comment: {
            text: "Describe this function...",
            pinned: false,
            height: 80,
            width: 160,
          },
        },
        fields: { NAME: "PropTypeMassFraction" },
        inputs: {
          STACK: {
            block: {
              type: "procedures_ifreturn",
              id: "ZsW,@3{R?YA}3=S^Zqf*",
              extraState: '<mutation value="1"></mutation>',
              inputs: {
                CONDITION: {
                  block: {
                    type: "logic_compare",
                    id: "-h;1;l5M6Y#kiQ7X9I(v",
                    fields: { OP: "EQ" },
                    inputs: {
                      A: {
                        block: {
                          type: "designvariable_text",
                          id: "T`nkK[K*F9|uPafD,*P,",
                          fields: { DVNAME: "PropulsionType" },
                        },
                      },
                      B: {
                        block: {
                          type: "text",
                          id: "Dmu~Q`v/U8fA0~2%jJTG",
                          fields: { TEXT: "Biprop" },
                        },
                      },
                    },
                  },
                },
                VALUE: {
                  block: {
                    type: "math_number",
                    id: "?1zSNVM`|IiO|5xMe,.B",
                    fields: { NUM: 0.12 },
                  },
                },
              },
              next: {
                block: {
                  type: "procedures_ifreturn",
                  id: "exIoZg~hJQ%:L586P[^b",
                  extraState: '<mutation value="1"></mutation>',
                  inputs: {
                    CONDITION: {
                      block: {
                        type: "logic_compare",
                        id: "BI/R,7QWO]3QlzjssIi^",
                        fields: { OP: "EQ" },
                        inputs: {
                          A: {
                            block: {
                              type: "designvariable_text",
                              id: "t23WY^}.zW;JVd(MRe+`",
                              fields: { DVNAME: "PropulsionType" },
                            },
                          },
                          B: {
                            block: {
                              type: "text",
                              id: "43$IV;(c(eKU`7*h6P81",
                              fields: { TEXT: "Cryo" },
                            },
                          },
                        },
                      },
                    },
                    VALUE: {
                      block: {
                        type: "math_number",
                        id: "{l.~|4Kj~4V.H08PD(IT",
                        fields: { NUM: 0.13 },
                      },
                    },
                  },
                  next: {
                    block: {
                      type: "procedures_ifreturn",
                      id: "q!eNsc0lLiX}qJvw;v2+",
                      extraState: '<mutation value="1"></mutation>',
                      inputs: {
                        CONDITION: {
                          block: {
                            type: "logic_compare",
                            id: "fC9eU+^4O-Lx7u]k3iGK",
                            fields: { OP: "EQ" },
                            inputs: {
                              A: {
                                block: {
                                  type: "designvariable_text",
                                  id: ":%QH!3`^FRHEN1}=GtjB",
                                  fields: { DVNAME: "PropulsionType" },
                                },
                              },
                              B: {
                                block: {
                                  type: "text",
                                  id: "R}[wk~1++siGzX]v19)9",
                                  fields: { TEXT: "Electric" },
                                },
                              },
                            },
                          },
                        },
                        VALUE: {
                          block: {
                            type: "math_number",
                            id: "jJ8!W|dQbmG.4z)x+~FK",
                            fields: { NUM: 0.3 },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          RETURN: {
            block: {
              type: "math_number",
              id: "=]h0l_=Aqc%};$V_},rU",
              fields: { NUM: 0.2 },
            },
          },
        },
      },
      {
        type: "procedures_defreturn",
        id: "DkYEyiLlM#d){79IJhGq",
        x: 4,
        y: 889,
        icons: {
          comment: {
            text: "Describe this function...",
            pinned: false,
            height: 80,
            width: 160,
          },
        },
        fields: { NAME: "PropTypeIspSec" },
        inputs: {
          STACK: {
            block: {
              type: "procedures_ifreturn",
              id: "kg*/!qdt/gp0s~q}8]Ac",
              extraState: '<mutation value="1"></mutation>',
              inputs: {
                CONDITION: {
                  block: {
                    type: "logic_compare",
                    id: "+srO9@.R6|lri!L?8m.F",
                    fields: { OP: "EQ" },
                    inputs: {
                      A: {
                        block: {
                          type: "designvariable_text",
                          id: ".eJz1_(gPIR)GZ~V/7*;",
                          fields: { DVNAME: "PropulsionType" },
                        },
                      },
                      B: {
                        block: {
                          type: "text",
                          id: "uqek2XP9H*,mN@l=cf3Z",
                          fields: { TEXT: "Biprop" },
                        },
                      },
                    },
                  },
                },
                VALUE: {
                  block: {
                    type: "math_number",
                    id: "SU1sPK#Va_JOk}ICGR~~",
                    fields: { NUM: 300 },
                  },
                },
              },
              next: {
                block: {
                  type: "procedures_ifreturn",
                  id: "IBsnHK^#+`+iO4qflAL|",
                  extraState: '<mutation value="1"></mutation>',
                  inputs: {
                    CONDITION: {
                      block: {
                        type: "logic_compare",
                        id: "S-B.+on#[gfpfiz;t8*U",
                        fields: { OP: "EQ" },
                        inputs: {
                          A: {
                            block: {
                              type: "designvariable_text",
                              id: "~jXAoo+!0rF2171jKnn=",
                              fields: { DVNAME: "PropulsionType" },
                            },
                          },
                          B: {
                            block: {
                              type: "text",
                              id: "JRo)=}^VtZMh!qDAp%sj",
                              fields: { TEXT: "Cryo" },
                            },
                          },
                        },
                      },
                    },
                    VALUE: {
                      block: {
                        type: "math_number",
                        id: "i;YXhj!#Gih1Un.ztN=(",
                        fields: { NUM: 450 },
                      },
                    },
                  },
                  next: {
                    block: {
                      type: "procedures_ifreturn",
                      id: "|2lu@X:`5kpDR6THe#Q[",
                      extraState: '<mutation value="1"></mutation>',
                      inputs: {
                        CONDITION: {
                          block: {
                            type: "logic_compare",
                            id: "$p+m{)))FDvI5%SfYOY{",
                            fields: { OP: "EQ" },
                            inputs: {
                              A: {
                                block: {
                                  type: "designvariable_text",
                                  id: "Fn=[LrR==8~58*[!]KT(",
                                  fields: { DVNAME: "PropulsionType" },
                                },
                              },
                              B: {
                                block: {
                                  type: "text",
                                  id: "Zr!JNF3F:$nd/^10t0He",
                                  fields: { TEXT: "Electric" },
                                },
                              },
                            },
                          },
                        },
                        VALUE: {
                          block: {
                            type: "math_number",
                            id: "wz#AENT==5hp@=%Ylw/P",
                            fields: { NUM: 2200 },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          RETURN: {
            block: {
              type: "math_number",
              id: "1^FFFCS#qNAb,3Q$Lr!N",
              fields: { NUM: 1500 },
            },
          },
        },
      },
      {
        type: "procedures_defreturn",
        id: "`hLz-1(i=a|kf%qvuSPC",
        x: -462,
        y: 363,
        icons: {
          comment: {
            text: "Describe this function...",
            pinned: false,
            height: 80,
            width: 160,
          },
        },
        fields: { NAME: "DetermineCapability" },
        inputs: {
          STACK: {
            block: {
              type: "procedures_ifreturn",
              id: ";o+:6~^=pq[;U;z+-@eu",
              extraState: '<mutation value="1"></mutation>',
              inputs: {
                CONDITION: {
                  block: {
                    type: "logic_compare",
                    id: "WI_;Sc~o10_@e,1WwQ-2",
                    fields: { OP: "EQ" },
                    inputs: {
                      A: {
                        block: {
                          type: "designvariable_num",
                          id: "ouF,`|;(*Wy-;aY{z.s:",
                          fields: { DVNAME: "Payload" },
                        },
                      },
                      B: {
                        block: {
                          type: "math_number",
                          id: "K#;QJ{7pB3^`eQp:3UJ9",
                          fields: { NUM: 300 },
                        },
                      },
                    },
                  },
                },
                VALUE: {
                  block: {
                    type: "math_number",
                    id: "u8+;C*:/#QNAR(!u}0eD",
                    fields: { NUM: 0 },
                  },
                },
              },
              next: {
                block: {
                  type: "procedures_ifreturn",
                  id: "L6GQn6`R02u=S9:Ch)|$",
                  extraState: '<mutation value="1"></mutation>',
                  inputs: {
                    CONDITION: {
                      block: {
                        type: "logic_compare",
                        id: "lGjEdq[+!rxc!sncN,$G",
                        fields: { OP: "EQ" },
                        inputs: {
                          A: {
                            block: {
                              type: "designvariable_num",
                              id: "7)F-p+$s%K?M4-QZd?$1",
                              fields: { DVNAME: "Payload" },
                            },
                          },
                          B: {
                            block: {
                              type: "math_number",
                              id: "b|EL=fq$(X_BJk~@]i1{",
                              fields: { NUM: 1000 },
                            },
                          },
                        },
                      },
                    },
                    VALUE: {
                      block: {
                        type: "math_number",
                        id: "0za7m37OG`}%be[u}nO[",
                        fields: { NUM: 1 },
                      },
                    },
                  },
                  next: {
                    block: {
                      type: "procedures_ifreturn",
                      id: "94Qz)wI@If]uf[M#eBZj",
                      extraState: '<mutation value="1"></mutation>',
                      inputs: {
                        CONDITION: {
                          block: {
                            type: "logic_compare",
                            id: "t*z|c8_oP:nZqo#b+3~%",
                            fields: { OP: "EQ" },
                            inputs: {
                              A: {
                                block: {
                                  type: "designvariable_num",
                                  id: "|z5/aHf{cbviYqZpY0`5",
                                  fields: { DVNAME: "Payload" },
                                },
                              },
                              B: {
                                block: {
                                  type: "math_number",
                                  id: "+y*-9aSsTkLs#ays[eNd",
                                  fields: { NUM: 3000 },
                                },
                              },
                            },
                          },
                        },
                        VALUE: {
                          block: {
                            type: "math_number",
                            id: "{!B!#CRy`1/N5syBY=K5",
                            fields: { NUM: 2 },
                          },
                        },
                      },
                      next: {
                        block: {
                          type: "procedures_ifreturn",
                          id: "u$qgHzwx}P??G|YRklwl",
                          extraState: '<mutation value="1"></mutation>',
                          inputs: {
                            CONDITION: {
                              block: {
                                type: "logic_compare",
                                id: "YH7{J]qPB]2AjBUka+YB",
                                fields: { OP: "EQ" },
                                inputs: {
                                  A: {
                                    block: {
                                      type: "designvariable_num",
                                      id: ")L0WS6*Fc^Q1hWAb]=o`",
                                      fields: { DVNAME: "Payload" },
                                    },
                                  },
                                  B: {
                                    block: {
                                      type: "math_number",
                                      id: "TlK(ir|~G{9#wUhv;boV",
                                      fields: { NUM: 5 },
                                    },
                                  },
                                },
                              },
                            },
                            VALUE: {
                              block: {
                                type: "math_number",
                                id: "*!?CG~K2F8WzFVroFxC^",
                                fields: { NUM: 3 },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          RETURN: {
            block: {
              type: "math_number",
              id: "vxO1`GIA|w/@zWV/@w#I",
              fields: { NUM: 4 },
            },
          },
        },
      },
      {
        type: "procedures_defreturn",
        id: "?tVqZg-@u-vl@4SflQ,l",
        x: -611,
        y: 208,
        icons: {
          comment: {
            text: "Describe this function...",
            pinned: false,
            height: 80,
            width: 160,
          },
        },
        fields: { NAME: "DetermineSpeed" },
        inputs: {
          STACK: {
            block: {
              type: "procedures_ifreturn",
              id: "fKPzbI8o5~)}3%MSOdxV",
              extraState: '<mutation value="1"></mutation>',
              inputs: {
                CONDITION: {
                  block: {
                    type: "logic_compare",
                    id: "I4/Z[[cul`+jnW8r;MGP",
                    fields: { OP: "EQ" },
                    inputs: {
                      A: {
                        block: {
                          type: "designvariable_text",
                          id: "!)EIH_7Ri^VQ|Z?exhvz",
                          fields: { DVNAME: "PropulsionType" },
                        },
                      },
                      B: {
                        block: {
                          type: "text",
                          id: "~*_?{cro:M@*ALc=AUO`",
                          fields: { TEXT: "Electric" },
                        },
                      },
                    },
                  },
                },
                VALUE: {
                  block: {
                    type: "math_number",
                    id: "e,}o*tgCXXQwu#$}ZT}x",
                    fields: { NUM: 1 },
                  },
                },
              },
            },
          },
          RETURN: {
            block: {
              type: "math_number",
              id: ",=%{$Zp:7*B*nl@,t4b!",
              fields: { NUM: 0 },
            },
          },
        },
      },
      {
        type: "procedures_defreturn",
        id: "(/g065E[kG8}yyKB0]f?",
        x: 667,
        y: 312,
        icons: {
          comment: {
            text: "Describe this function...",
            pinned: false,
            height: 80,
            width: 160,
          },
        },
        fields: { NAME: "calcDeltaV" },
        inputs: {
          STACK: {
            block: {
              type: "procedures_ifreturn",
              id: "KI+{!gDV(e=pYksxroI_",
              extraState: '<mutation value="1"></mutation>',
              inputs: {
                CONDITION: {
                  block: {
                    type: "logic_compare",
                    id: "NUnuFoS]Bf+5$j?7b.bo",
                    fields: { OP: "EQ" },
                    inputs: {
                      A: {
                        block: {
                          type: "contextvariable_num",
                          id: "ef^p!W-a]9ZL(:_wmB|m",
                          fields: { CVNAME: "EV1" },
                        },
                      },
                      B: {
                        block: {
                          type: "math_number",
                          id: ".q5-,M2`1nK-an%NhQg|",
                          fields: { NUM: 1 },
                        },
                      },
                    },
                  },
                },
                VALUE: {
                  block: {
                    type: "math_arithmetic",
                    id: "rZy4E:qQ}FpaSOeZrr`S",
                    fields: { OP: "DIVIDE" },
                    inputs: {
                      A: {
                        block: {
                          type: "math_arithmetic",
                          id: "T/YaL9`5v$7zyo]Tj/ZK",
                          fields: { OP: "MULTIPLY" },
                          inputs: {
                            A: {
                              block: {
                                type: "math_arithmetic",
                                id: "[e4!VB%Z#l(IZ/Wkbg^A",
                                fields: { OP: "MULTIPLY" },
                                inputs: {
                                  A: {
                                    block: {
                                      type: "procedures_callreturn",
                                      id: "QExPe^;)F2hXw.znmv$O",
                                      extraState: { name: "Gee" },
                                    },
                                  },
                                  B: {
                                    block: {
                                      type: "variables_get",
                                      id: "l{hA@D~Qii);ydT4Mtzv",
                                      fields: {
                                        VAR: { id: ".]6q4K,+=PiIU~i1TDZj" },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                            B: {
                              block: {
                                type: "math_single",
                                id: "o{n:dVH_?anz)TC,S`8V",
                                fields: { OP: "LN" },
                                inputs: {
                                  NUM: {
                                    block: {
                                      type: "math_arithmetic",
                                      id: "u#8.]W:6d#UwwY[G/KP)",
                                      fields: { OP: "DIVIDE" },
                                      inputs: {
                                        A: {
                                          block: {
                                            type: "variables_get",
                                            id: "Eszr$G$Y.7.XtgB!vJHH",
                                            fields: {
                                              VAR: {
                                                id: "@`g-`m]Y@{b@R~L86P-3",
                                              },
                                            },
                                          },
                                        },
                                        B: {
                                          block: {
                                            type: "variables_get",
                                            id: "xa*Vyq2~`Ku60En=T|IH",
                                            fields: {
                                              VAR: {
                                                id: "0QB,!4]+$d9G8Lj!:?1)",
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      B: {
                        block: {
                          type: "math_number",
                          id: "87YJer#r=l8OE#%Xlx[^",
                          fields: { NUM: 1000 },
                        },
                      },
                    },
                  },
                },
              },
              next: {
                block: {
                  type: "procedures_ifreturn",
                  id: "ca;nmKj]U^rap2;3csvd",
                  extraState: '<mutation value="1"></mutation>',
                  inputs: {
                    CONDITION: {
                      block: {
                        type: "logic_compare",
                        id: "oJNMcL!Pv`[wVsIE1`bz",
                        fields: { OP: "EQ" },
                        inputs: {
                          A: {
                            block: {
                              type: "contextvariable_num",
                              id: "5RDzwr:[5((eaOMGY5qS",
                              fields: { CVNAME: "EV1" },
                            },
                          },
                          B: {
                            block: {
                              type: "math_number",
                              id: "2I,igZ4#ar:jyhq|HP9j",
                              fields: { NUM: 2 },
                            },
                          },
                        },
                      },
                    },
                    VALUE: {
                      block: {
                        type: "math_arithmetic",
                        id: "T:d`^z?c8xnvlY,PB2yn",
                        fields: { OP: "DIVIDE" },
                        inputs: {
                          A: {
                            block: {
                              type: "math_arithmetic",
                              id: "|w6Y*{vTS$.@7]{-L7kT",
                              fields: { OP: "MULTIPLY" },
                              inputs: {
                                A: {
                                  block: {
                                    type: "math_arithmetic",
                                    id: ":Nv{2|6A4{dobuIXh;h3",
                                    fields: { OP: "MULTIPLY" },
                                    inputs: {
                                      A: {
                                        block: {
                                          type: "procedures_callreturn",
                                          id: ",3$-z@nMUVYuu77..-|s",
                                          extraState: { name: "Gee" },
                                        },
                                      },
                                      B: {
                                        block: {
                                          type: "variables_get",
                                          id: "eT;~@Q(}_ATU_2S-P{!I",
                                          fields: {
                                            VAR: { id: ".]6q4K,+=PiIU~i1TDZj" },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                                B: {
                                  block: {
                                    type: "math_single",
                                    id: "fcZk@EEq{2tCt?-]Eg#[",
                                    fields: { OP: "LN" },
                                    inputs: {
                                      NUM: {
                                        block: {
                                          type: "math_arithmetic",
                                          id: "O8uBcVkstG}q|5lJnmbe",
                                          fields: { OP: "DIVIDE" },
                                          inputs: {
                                            A: {
                                              block: {
                                                type: "variables_get",
                                                id: "ea7Y#i66#E)#[#ZsP9Bq",
                                                fields: {
                                                  VAR: {
                                                    id: "@`g-`m]Y@{b@R~L86P-3",
                                                  },
                                                },
                                              },
                                            },
                                            B: {
                                              block: {
                                                type: "variables_get",
                                                id: "3K^tC.m4bx0BbERV,#7Z",
                                                fields: {
                                                  VAR: {
                                                    id: "0QB,!4]+$d9G8Lj!:?1)",
                                                  },
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                          B: {
                            block: {
                              type: "math_number",
                              id: "0vyD$WP[f`^*#T).;.ng",
                              fields: { NUM: 700 },
                            },
                          },
                        },
                      },
                    },
                  },
                  next: {
                    block: {
                      type: "procedures_ifreturn",
                      id: "([Y,6Wghhfah=)R~bc$E",
                      extraState: '<mutation value="1"></mutation>',
                      inputs: {
                        CONDITION: {
                          block: {
                            type: "logic_compare",
                            id: "k|B~E*^1(NAm.:$3dRfZ",
                            fields: { OP: "EQ" },
                            inputs: {
                              A: {
                                block: {
                                  type: "contextvariable_num",
                                  id: "bAG!S#tRsfZ9D,ZgxIt;",
                                  fields: { CVNAME: "EV1" },
                                },
                              },
                              B: {
                                block: {
                                  type: "math_number",
                                  id: "0TG9WZKK5RiYj~B.~}h!",
                                  fields: { NUM: 3 },
                                },
                              },
                            },
                          },
                        },
                        VALUE: {
                          block: {
                            type: "math_arithmetic",
                            id: "vBcy}HCOz;;|uM~h5rGH",
                            fields: { OP: "DIVIDE" },
                            inputs: {
                              A: {
                                block: {
                                  type: "math_arithmetic",
                                  id: "t^tV6v{A3C$!QZYMB~;r",
                                  fields: { OP: "MULTIPLY" },
                                  inputs: {
                                    A: {
                                      block: {
                                        type: "math_arithmetic",
                                        id: "r7n9D4DMWHWo|Oc:7U)c",
                                        fields: { OP: "MULTIPLY" },
                                        inputs: {
                                          A: {
                                            block: {
                                              type: "procedures_callreturn",
                                              id: "VO0I,q;%kqD3lL/+F4BA",
                                              extraState: { name: "Gee" },
                                            },
                                          },
                                          B: {
                                            block: {
                                              type: "variables_get",
                                              id: "FMTzdv*XyW][Ki=B[9JB",
                                              fields: {
                                                VAR: {
                                                  id: ".]6q4K,+=PiIU~i1TDZj",
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                    B: {
                                      block: {
                                        type: "math_single",
                                        id: "jZyD`=@~_[8%O6:)I-/d",
                                        fields: { OP: "LN" },
                                        inputs: {
                                          NUM: {
                                            block: {
                                              type: "math_arithmetic",
                                              id: "qetZ*{NMtzNAZsSz))lN",
                                              fields: { OP: "DIVIDE" },
                                              inputs: {
                                                A: {
                                                  block: {
                                                    type: "variables_get",
                                                    id: "O%K|(r/wDu1bmkqILj:]",
                                                    fields: {
                                                      VAR: {
                                                        id: "@`g-`m]Y@{b@R~L86P-3",
                                                      },
                                                    },
                                                  },
                                                },
                                                B: {
                                                  block: {
                                                    type: "variables_get",
                                                    id: "JAfwaf2{plHL,~`Dpo]x",
                                                    fields: {
                                                      VAR: {
                                                        id: "0QB,!4]+$d9G8Lj!:?1)",
                                                      },
                                                    },
                                                  },
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                              B: {
                                block: {
                                  type: "math_number",
                                  id: "?2=wq+Vr%YeT(,GWLqJr",
                                  fields: { NUM: 500 },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          RETURN: {
            block: {
              type: "text",
              id: "v%m*[#W4jL-Huq6c:hw^",
              fields: { TEXT: "abc" },
            },
          },
        },
      },
    ],
  },
  variables: [
    { name: "BusMass", id: "hO6/*NtJ+@ahk8[nt`tQ" },
    { name: "DryMass", id: "0QB,!4]+$d9G8Lj!:?1)" },
    { name: "TotalMass", id: "@`g-`m]Y@{b@R~L86P-3" },
    { name: "Isp", id: ".]6q4K,+=PiIU~i1TDZj" },
  ],
});
export const spacetugexampledata = {
  study: {
    name: "SpaceTug Explore 2.0",
    stakeholders: [
      {
        vm: {
          "b31f8ded-adcc-4e46-aca0-57bc5ee525e8": {
            id: "b31f8ded-adcc-4e46-aca0-57bc5ee525e8",
            name: "ResponseTime",
            direction: "benefit",
            weight: "0.2",
            levels: [
              { value: 0, name: "0" },
              { name: "1", value: "1" },
            ],
          },
          "d371a084-5265-4e2a-a2e2-631c6a83d634": {
            levels: [],
            direction: "cost",
            id: "d371a084-5265-4e2a-a2e2-631c6a83d634",
            name: "TotalCost",
            weight: 1,
          },
          "66638341-d8ee-4dcb-b400-8d40635d7cd2": {
            id: "66638341-d8ee-4dcb-b400-8d40635d7cd2",
            name: "Capability",
            direction: "benefit",
            weight: "0.5",
            levels: [
              { value: "0", name: "0" },
              { value: "0.2", name: "1" },
              { value: "0.6", name: "2" },
              { value: "1", name: "3" },
            ],
          },
          "75273181-5733-4db0-a1cd-8195289e40c2": {
            id: "75273181-5733-4db0-a1cd-8195289e40c2",
            name: "DeltaV",
            direction: "ascending",
            weight: 1,
            levels: [],
          },
        },
        name: "Owner",
        id: "746496f0-3888-4190-8046-97e8b86a551f",
      },
      {
        name: "ClientX",
        vm: {
          "d371a084-5265-4e2a-a2e2-631c6a83d634": {
            levels: [],
            id: "d371a084-5265-4e2a-a2e2-631c6a83d634",
            direction: "cost",
            name: "TotalCost",
            weight: 1,
          },
          "66638341-d8ee-4dcb-b400-8d40635d7cd2": {
            id: "66638341-d8ee-4dcb-b400-8d40635d7cd2",
            name: "Capability",
            direction: "benefit",
            weight: 1,
            levels: [
              { value: 0, name: "0" },
              { name: "1", value: "0" },
              { value: "0.9", name: "2" },
              { name: "3", value: "1" },
            ],
          },
          "75273181-5733-4db0-a1cd-8195289e40c2": {
            id: "75273181-5733-4db0-a1cd-8195289e40c2",
            name: "DeltaV",
            direction: "ascending",
            weight: 1,
            levels: [],
          },
        },
        id: "05050c90-5c51-400c-9e64-f5aafa4d96ec",
      },
    ],
    valueCriteria: [
      {
        id: "75273181-5733-4db0-a1cd-8195289e40c2",
        units: "m/s",
        levels: [""],
        name: "DeltaV",
        type: "num",
      },
      {
        type: "text",
        name: "Capability",
        units: "",
        id: "66638341-d8ee-4dcb-b400-8d40635d7cd2",
        levels: ["0", "1", "2", "3"],
      },
      {
        id: "b31f8ded-adcc-4e46-aca0-57bc5ee525e8",
        type: "text",
        levels: ["0", "1"],
        name: "ResponseTime",
        units: "",
      },
      {
        units: "$",
        id: "d371a084-5265-4e2a-a2e2-631c6a83d634",
        name: "TotalCost",
        levels: [""],
        type: "num",
      },
    ],
    alternativeVariables: [
      {
        dcs: [
          {
            name: "DeltaV",
            levels: [""],
            type: "num",
            id: "75273181-5733-4db0-a1cd-8195289e40c2",
            level: "high",
            units: "m/s",
          },
          {
            id: "66638341-d8ee-4dcb-b400-8d40635d7cd2",
            type: "text",
            level: "high",
            levels: ["0", "1", "2", "3"],
            units: "",
            name: "Capability",
          },
          {
            levels: ["0", "1"],
            level: "low",
            id: "b31f8ded-adcc-4e46-aca0-57bc5ee525e8",
            units: "",
            type: "text",
            name: "ResponseTime",
          },
          {
            units: "$",
            name: "TotalCost",
            level: "high",
            levels: [""],
            type: "num",
            id: "d371a084-5265-4e2a-a2e2-631c6a83d634",
          },
        ],
        name: "Payload",
        type: "num",
        baseline: "300",
        levels: ["300", "1000", "3000", "5000"],
        id: "4f1c1d63-c354-4512-b37d-bebf84c0e874",
      },
      {
        name: "PropulsionType",
        levels: ["Biprop", "Cryo", "Electric", "Nuclear"],
        dcs: [
          {
            name: "DeltaV",
            type: "num",
            level: "high",
            id: "75273181-5733-4db0-a1cd-8195289e40c2",
            levels: [""],
            units: "m/s",
          },
          {
            levels: ["0", "1"],
            units: "",
            name: "ResponseTime",
            id: "b31f8ded-adcc-4e46-aca0-57bc5ee525e8",
            type: "text",
            level: "high",
          },
          {
            id: "d371a084-5265-4e2a-a2e2-631c6a83d634",
            level: "high",
            name: "TotalCost",
            units: "$",
            type: "num",
            levels: [""],
          },
        ],
        type: "text",
        baseline: "Biprop",
        id: "63d9ea50-e87b-4f34-87f1-97e21efef24f",
      },
      {
        type: "num",
        id: "c43df450-fca1-42c2-9fee-9ae2203a85d9",
        baseline: "100",
        dcs: [
          {
            units: "m/s",
            name: "DeltaV",
            type: "num",
            levels: [""],
            level: "med",
            id: "75273181-5733-4db0-a1cd-8195289e40c2",
          },
          {
            level: "med",
            levels: ["0", "1"],
            id: "b31f8ded-adcc-4e46-aca0-57bc5ee525e8",
            type: "text",
            name: "ResponseTime",
            units: "",
          },
          {
            name: "TotalCost",
            level: "med",
            id: "d371a084-5265-4e2a-a2e2-631c6a83d634",
            type: "num",
            units: "$",
            levels: [""],
          },
        ],
        name: "FuelLevel",
        levels: ["100", "300", "1200", "3000", "10000", "50000"],
      },
    ],
    epochVariables: [
      {
        id: "b4a40d0d-0f04-4008-b473-8106a312dea3",
        name: "EV1",
        type: "num",
        timescale: "days",
        levels: ["1", "2", "3"],
        context: true,
        preference: false,
        dcs: [
          {
            id: "75273181-5733-4db0-a1cd-8195289e40c2",
            units: "m/s",
            levels: [""],
            name: "DeltaV",
            type: "num",
            level: "low",
          },
        ],
        stakeholders: [],
        baseline: "1",
      },
    ],
    preparations: [],
    changeOptions: [],
    warnings: [],
    errors: [],
    settings: {
      VXy3p2R4k3U5XSjIsoYTnGoYYUA2: { activeNavTab: "Stakeholders" },
      showResponse: true,
      showUncertainty: true,
      showEWIinline: false,
    },
    activeUsers: [],
    patchHistory: [],
    notes: [],
    fbid: null,
    evaluations: [],
    valuations: {},
    redoStep: 0,
    lastModified: "2023-07-05T20:21:18.471Z",
    evalmodel: stevalmodel,
  },
  owner: "VXy3p2R4k3U5XSjIsoYTnGoYYUA2",
  shared: [],
};
