import { Col, Container, Row, Stack, Table } from "react-bootstrap";
import CustomNavIcon from "../../CustomNavIcon";
import { useEffect, useRef, useState } from "react";
import { colorRotator } from "../../../../util/helpers";
import EraEVLevelEditorCell from "./EraEVLevelEditorCell";

function EraEVLevelEditor({ epochVariables, ...props }) {
  const [selectedLevels, setSelectedLevels] = useState({});
  const [durations, setDurations] = useState(
    Array.from({ length: 6 }, (v, i) => 0)
  );
  const [eraLength, setEraLength] = useState(6);
  const [eraUnits, setEraUnits] = useState("epochs");
  const [cellsFlashing, setCellsFlashing] = useState([]);
  const [disabledCells, setDisabledCells] = useState([]);
  const oldDurations = useRef([]);
  const epochUnitsRef = useRef(true);
  const timescaleUnitsRef = useRef(false);
  const lastEvSelectedRef = useRef(null);
  const lastEvLevSelectedRef = useRef(null);
  const lastColSelected = useRef(null);
  const oldCrossEVRules = useRef([]);
  const oldIntraEpochRules = useRef([]);
  const eraTimescale = useRef(null);
  const eraTimestep = useRef(null);

  useEffect(() => {
    eraTimestep.current = epochVariables.find((ev) => ev.timescale === "days")
      ? "days"
      : epochVariables.find((ev) => ev.timescale === "months")
      ? "months"
      : "years";

    eraTimescale.current =
      epochVariables.map((ev) => ev.timescale).indexOf("years") >= 0
        ? "years"
        : epochVariables.map((ev) => ev.timescale).indexOf("months") >= 0
        ? "months"
        : "days";
    let newDurations = Array.from(
      {
        length:
          eraUnits === "epochs"
            ? eraLength
            : eraTimescale.current === "years" ||
              eraTimescale.current === "months"
            ? eraTimestep.current === "years"
              ? eraLength
              : eraTimestep.current === "months"
              ? eraLength * 12
              : eraLength * 30
            : "ERR",
      },
      (v, i) => 0
    );
    setDurations(newDurations);
  }, [
    epochVariables,
    setDurations,
    eraLength,
    eraTimescale,
    eraUnits,
    eraTimestep,
  ]);

  const changeEraUnits = (e) => {
    if (e.target.checked && e.target === epochUnitsRef.current)
      timescaleUnitsRef.current.checked = false;
    else if (e.target.checked && e.target === timescaleUnitsRef.current)
      epochUnitsRef.current.checked = false;

    setEraUnits(
      epochUnitsRef.current.checked ? "epochs" : eraTimescale.current
    );
  };
  const changeEraLength = (e) => {
    setEraLength(e.target.value);
  };

  useEffect(() => {
    let newSelectedLevels = { ...selectedLevels };
    if (oldDurations.current !== durations) {
      epochVariables.forEach((ev, i) => {
        newSelectedLevels[ev.name] = Array.from(
          { length: durations.length },
          (v, j) =>
            (selectedLevels[ev.name] && selectedLevels[ev.name][j]) ||
            ev.baseline
        );
      });
    }
    // if nothing changed in the rules (and nothing changed in the durations, checked above), just return
    else if (
      props.crossEpochVariableRules === oldCrossEVRules.current &&
      props.intraEpochRules === oldIntraEpochRules.current
    )
      return;
    // and now update all cells based on selections and on cross-epoch variable rules
    let newDisabledCells = [];
    props.crossEpochVariableRules
      .filter((rule) => oldCrossEVRules.current.indexOf(rule.id) < 0)
      .forEach((rule) => {
        durations.forEach((dur, col) => {
          epochVariables.forEach((cev) => {
            if (
              cev.name.trim() !== rule.firstVar.split(":")[0] &&
              cev.name.trim() !== rule.secondVar.split(":")[0]
            )
              return;
            let varToDisable = null;
            let disablingVar = null;
            if (
              cev.name.trim() === rule.firstVar.split(":")[0] &&
              newSelectedLevels[cev.name.trim()][col].trim() ===
                rule.firstVar.split(":")[1]
            ) {
              disablingVar = "firstVar";
              varToDisable = "secondVar";
            } else if (
              cev.name.trim() === rule.secondVar.split(":")[0] &&
              newSelectedLevels[cev.name.trim()][col].trim() ===
                rule.secondVar.split(":")[1]
            ) {
              disablingVar = "secondVar";
              varToDisable = "firstVar";
            } else return;

            if (
              newDisabledCells.findIndex(
                (ndc) =>
                  ndc.evName === rule[varToDisable].split(":")[0].trim() &&
                  ndc.evLev === rule[varToDisable].split(":")[1].trim() &&
                  ndc.col === col &&
                  ndc.disabler ===
                    rule[disablingVar].split(":")[0].trim() +
                      ":" +
                      rule[disablingVar].split(":")[1].trim()
              ) < 0
            )
              newDisabledCells.push({
                evName: rule[varToDisable].split(":")[0].trim(),
                evLev: rule[varToDisable].split(":")[1].trim(),
                col: col,
                disabler:
                  rule[disablingVar].split(":")[0].trim() +
                  ":" +
                  rule[disablingVar].split(":")[1].trim(),
              });
          });
        });
      });
    props.intraEpochRules
      .filter((rule) => oldIntraEpochRules.current.indexOf(rule.id) < 0)
      .forEach((rule) => {
        epochVariables.forEach((cev) => {
          durations.forEach((dur, col) => {
            if (cev.name.trim() !== rule.firstVarLevel.split(":")[0]) return;
            let colToDisable = null;
            if (
              newSelectedLevels[cev.name.trim()][col].trim() ===
              rule.firstVarLevel.split(":")[1]
            ) {
              colToDisable = col + 1;
            } else return;

            if (
              newDisabledCells.findIndex(
                (ndc) =>
                  ndc.evName === cev.name.trim() &&
                  ndc.evLev === rule.secondVarLevel.split(":")[1].trim() &&
                  ndc.col === colToDisable &&
                  ndc.disabler === rule.firstVarLevel
              ) < 0
            )
              newDisabledCells.push({
                evName: cev.name.trim(),
                evLev: rule.secondVarLevel.split(":")[1].trim(),
                col: colToDisable,
                disabler: rule.firstVarLevel,
              });
          });
        });
      });
    oldCrossEVRules.current = props.crossEpochVariableRules;
    oldIntraEpochRules.current = props.intraEpochRules;
    oldDurations.current = durations;
    setDisabledCells(newDisabledCells);
    setSelectedLevels(newSelectedLevels);
  }, [
    props.crossEpochVariableRules,
    props.intraEpochRules,
    durations,
    epochVariables,
    selectedLevels,
  ]);

  const flashCells = (toFlashOrNot, arrOfCells) => {
    let newCellsFlashing = [...cellsFlashing];
    if (toFlashOrNot)
      arrOfCells.forEach((cell) =>
        newCellsFlashing.push({
          col: cell.col,
          evName: cell.evName,
          evLev: cell.evLev,
        })
      );
    else
      arrOfCells.forEach(
        (cell) =>
          (newCellsFlashing = newCellsFlashing.filter(
            (c) =>
              c.col === cell.col &&
              c.evName === cell.evName &&
              c.evLev === cell.evLev
          ))
      );
    setCellsFlashing(newCellsFlashing);
  };

  const selectLevel = (e, ev, evLev, k, setRest = false) => {
    let newSelectedLevels = { ...selectedLevels };
    let rulesToFlash = [];
    let newDisabledCells = [...disabledCells];
    let newlyEnabledCells = [];
    if (setRest || (e.buttons && e.buttons === 1)) {
      if (
        !setRest &&
        ev.name === lastEvSelectedRef.current &&
        evLev === lastEvLevSelectedRef.current &&
        k === lastColSelected.current
      )
        return;
      lastEvSelectedRef.current = ev.name;
      lastEvLevSelectedRef.current = evLev;
      lastColSelected.current = k;
      for (
        let j = k;
        j < (setRest ? newSelectedLevels[ev.name].length : k + 1);
        j++
      ) {
        if (
          disabledCells.find(
            (ndc) =>
              ndc.evName === ev.name.trim() &&
              ndc.evLev === evLev.trim() &&
              ndc.col === j
          )
        ) {
          // figure out which rules/cells to flash
          let rulesViolated = [];
          props.crossEpochVariableRules.forEach((rule) => {
            if (
              (rule.firstVar.split(":")[0] === ev.name.trim() &&
                rule.firstVar.split(":")[1] === evLev.trim()) ||
              (rule.secondVar.split(":")[0] === ev.name.trim() &&
                rule.secondVar.split(":")[1] === evLev.trim())
            ) {
              let varToFlash =
                rule.firstVar.split(":")[0] === ev.name.trim()
                  ? "secondVar"
                  : "firstVar";

              // check other variable and relationship
              if (
                selectedLevels[rule[varToFlash].split(":")[0]][j].trim() ===
                  rule[varToFlash].split(":")[1].trim() &&
                rule.relationship === "cannot occur with"
              ) {
                rulesViolated.push({
                  secondVarName: rule[varToFlash].split(":")[0].trim(),
                  secondVarLev: rule[varToFlash].split(":")[1].trim(),
                  col: j,
                });
              }
            }
          });
          props.intraEpochRules.forEach((rule) => {
            if (
              rule.secondVarLevel.split(":")[0] === ev.name.trim() &&
              rule.secondVarLevel.split(":")[1] === evLev.trim()
            ) {
              let varToFlash = "firstVarLevel";
              if (
                j > 0 &&
                selectedLevels[ev.name.trim()][j - 1].trim() ===
                  rule[varToFlash].split(":")[1].trim() &&
                rule.relationship === "cannot transition to"
              )
                rulesViolated.push({
                  secondVarName: ev.name.trim(),
                  secondVarLev: rule[varToFlash].split(":")[1].trim(),
                  col: j - 1,
                });
            }
          });
          // flash corresponding boxes red to show constraint violation
          rulesToFlash = rulesToFlash.concat(
            rulesViolated.map((r) => {
              return {
                evName: r.secondVarName,
                evLev: r.secondVarLev,
                col: r.col,
              };
            })
          );
          // make sure we flash the selected cell too
          rulesToFlash.push({
            evName: ev.name.trim(),
            evLev: evLev.trim(),
            col: j,
          });
        } else {
          newSelectedLevels[ev.name][j] = evLev;
          // now manage cells that will be disabled/enabled by this selection
          //first by rules
          props.crossEpochVariableRules
            .filter(
              (rule) =>
                (rule.firstVar.split(":")[0] === ev.name.trim() &&
                  rule.firstVar.split(":")[1] === evLev.trim()) ||
                (rule.secondVar.split(":")[0] === ev.name.trim() &&
                  rule.secondVar.split(":")[1] === evLev.trim())
            )
            .forEach((rule) => {
              let varToDisable =
                rule.firstVar.split(":")[0] === ev.name.trim()
                  ? "secondVar"
                  : "firstVar";
              if (rule.relationship === "cannot occur with")
                if (
                  newDisabledCells.findIndex(
                    (ndc) =>
                      ndc.evName === rule[varToDisable].split(":")[0].trim() &&
                      ndc.evLev === rule[varToDisable].split(":")[1].trim() &&
                      ndc.col === j &&
                      ndc.disabler === ev.name.trim() + ":" + evLev.trim()
                  ) < 0
                )
                  newDisabledCells.push({
                    evName: rule[varToDisable].split(":")[0].trim(),
                    evLev: rule[varToDisable].split(":")[1].trim(),
                    col: j,
                    disabler: ev.name.trim() + ":" + evLev.trim(),
                  });
            });
          props.intraEpochRules
            .filter(
              (rule) =>
                rule.firstVarLevel.split(":")[0] === ev.name.trim() &&
                rule.firstVarLevel.split(":")[1] === evLev.trim()
            )
            .forEach((rule) => {
              let varToDisable = "secondVarLevel";
              if (rule.relationship === "cannot transition to")
                if (
                  newDisabledCells.findIndex(
                    (ndc) =>
                      ndc.evName === rule[varToDisable].split(":")[0].trim() &&
                      ndc.evLev === rule[varToDisable].split(":")[1].trim() &&
                      ndc.col === j + 1 &&
                      ndc.disabler === ev.name.trim() + ":" + evLev.trim()
                  ) < 0
                )
                  newDisabledCells.push({
                    evName: rule[varToDisable].split(":")[0].trim(),
                    evLev: rule[varToDisable].split(":")[1].trim(),
                    col: j + 1,
                    disabler: ev.name.trim() + ":" + evLev.trim(),
                  });
            });
          // then restore disabled cells that are now ok
          let filteredCells = disabledCells.reduce((matchingCells, cell, i) => {
            return (
              //first check for cross-epoch variable rules
              (cell.disabler.split(":")[0] !== cell.evName &&
                cell.col === j &&
                cell.disabler.split(":")[0] === ev.name.trim() &&
                cell.disabler.split(":")[1] !== evLev.trim()) ||
                // now check for intra-epoch variable rules
                (cell.disabler.split(":")[0] === cell.evName &&
                  cell.col === j + 1 &&
                  cell.disabler.split(":")[0] === ev.name.trim() &&
                  cell.disabler.split(":")[1] !== evLev.trim())
                ? matchingCells.concat(i)
                : matchingCells
            );
          }, []);
          newlyEnabledCells = newlyEnabledCells.concat(filteredCells);
        }
      }
      flashCells(true, rulesToFlash);
      setTimeout(
        () =>
          // then turn flash off (revert state of the individual boxes)
          flashCells(false, rulesToFlash),
        1000
      );
      setSelectedLevels(newSelectedLevels);
      let reverseSortedIndices = newlyEnabledCells.sort((a, b) => a - b);
      for (let i = reverseSortedIndices.length - 1; i >= 0; i--)
        newDisabledCells.splice(reverseSortedIndices[i], 1);
      setDisabledCells(newDisabledCells);
    }
  };

  return (
    <Container lg="auto" style={{ overflowX: "auto" }}>
      <Row className="eraCreationRowHeader">
        <Col>
          <h5>Era Length: </h5>
          <input
            min={2}
            max={20}
            step={1}
            defaultValue={6}
            style={{ width: "50px" }}
            type="number"
            onChange={changeEraLength}
          />
          <input
            ref={epochUnitsRef}
            defaultChecked={true}
            onChange={(e) => changeEraUnits(e)}
            style={{ margin: "10px", marginRight: "0px" }}
            type="radio"
          />
          epochs
          <input
            ref={timescaleUnitsRef}
            onChange={(e) => changeEraUnits(e)}
            style={{ margin: "10px", marginRight: "0px" }}
            type="radio"
          />
          {eraTimescale.current}
          {eraUnits !== "epochs" &&
            ", with time steps of " + eraTimestep.current}
        </Col>
        <Col>
          <h5>Baseline Epoch:</h5>
          {epochVariables.map((ev, i) => (
            <div style={{ color: colorRotator(i) }}>
              {ev.name}: {ev.baseline}
            </div>
          ))}
        </Col>
        <Col><h5>Era Name:</h5><input type="text" /></Col>
        <Col>
          <button disabled={true}>Create Era</button>
        </Col>
      </Row>
      <Row>
        <Table>
          <thead style={{ userSelect: "none" }}>
            <tr>
              <td
                style={{
                  width: "1px",
                }}
              ></td>
              <td
                style={{
                  width: "1px",
                }}
              ></td>
              {Array.from({ length: durations.length }, (v, i) => i).map(
                (i) => (
                  <td key={i} align="center" style={{ width: "75px" }}>
                    {/* {eraUnits !== "epochs" && (
                  <input
                    type="number"
                    style={{ width: "50px" }}
                    min={0}
                    max={100}
                    onChange={(e) => setIthDuration(e, i)}
                  />
                )} */}
                  </td>
                )
              )}
            </tr>
            <tr className="variableHeader">
              <td
                style={{
                  width: "1px",
                }}
              >
                Name
              </td>
              <td
                style={{
                  width: "1px",
                }}
              >
                Level
              </td>
              {Array.from(
                {
                  length: eraUnits === "epochs" ? eraLength : durations.length,
                },
                (v, i) => i
              ).map((i) => (
                <td key={i} align="center">
                  <div style={{ width: "20px", maxWidth: "25px" }}>
                    {i + 1}
                    {Object.keys(selectedLevels).reduce(
                      (boolVal, evName) =>
                        boolVal &&
                        selectedLevels[evName][i] ===
                          epochVariables.find((ev) => ev.name === evName)
                            .baseline,
                      true
                    )
                      ? "*"
                      : ""}
                  </div>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {epochVariables.map((ev, i) =>
              ev.levels.map((evLev, j) => (
                <tr
                  key={ev + evLev}
                  style={{
                    fontWeight: "bold",
                    userSelect: "none",
                    color: colorRotator(i),
                    borderBottom:
                      j === ev.levels.length - 1
                        ? "3px solid " + colorRotator(i)
                        : "",
                    borderTop: j === 0 ? "3px solid " + colorRotator(i) : "",
                  }}
                >
                  {j === 0 && (
                    <td
                      style={{
                        width: "1px",
                        verticalAlign: "middle",
                        padding: "0px 50px 0px 0px",
                        whiteSpace: "nowrap",
                      }}
                      rowSpan={ev.levels.length}
                    >
                      <div
                        style={{
                          width: "25px",
                          fontSize: "0.8em",
                          cursor: "pointer",
                          float: "left",
                        }}
                        onClick={() => props.setActiveNavTab("Epoch Variables")}
                      >
                        <CustomNavIcon
                          type={
                            ev.context && ev.preference
                              ? "both"
                              : ev.context
                              ? "fas fa-calculator"
                              : "fas fa-eye"
                          }
                        />
                      </div>
                      {ev.name}
                      <div>({ev.timescale})</div>
                    </td>
                  )}
                  <td
                    style={{
                      width: "1px",
                      verticalAlign: "middle",
                      padding: "0px 50px 0px 0px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {evLev}
                  </td>
                  {Array.from({ length: durations.length }, (v, i) => i).map(
                    (k) => (
                      <td
                        align="center"
                        key={ev.name + evLev + k}
                        style={{
                          width:
                            (durations[k] ? durations[k] * 25 : "25") + "px",
                          padding: "5px 0px 5px 0px",
                        }}
                      >
                        <EraEVLevelEditorCell
                          ev={ev}
                          evInd={i}
                          evLev={evLev}
                          col={k}
                          selectLevel={selectLevel}
                          selectedLevels={selectedLevels}
                          flashOrNot={cellsFlashing.find(
                            (cf) =>
                              cf.col === k &&
                              cf.evLev === evLev.trim() &&
                              cf.evName === ev.name.trim()
                          )}
                          disabledCell={disabledCells.find(
                            (dc) =>
                              dc.col === k &&
                              dc.evName === ev.name.trim() &&
                              dc.evLev === evLev.trim()
                          )}
                        />
                      </td>
                    )
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}
export default EraEVLevelEditor;
