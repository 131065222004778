import * as d3 from "d3";

import React, { useRef, useEffect, useCallback, useMemo } from "react";
function ColorByLegend({
  colorByDV,
  alternativeVariables,
}) {
  const ref = useRef(null);
  const dvColorScale = useMemo(() => {
    return !colorByDV || colorByDV === "steelblue"
      ? "steelblue"
      : alternativeVariables.find((av) => "" + av.id === "" + colorByDV)
          .type === "num"
      ? d3
          .scaleLinear()
          .domain(
            d3.extent(
              alternativeVariables.find((av) => "" + av.id === "" + colorByDV)
                .levels.map((d) => +d)
            )
          )
          .range(["white", "blue"])
      : d3.scaleOrdinal(d3.schemeCategory10);
  }, [colorByDV, alternativeVariables]);
  
  useEffect(() => {
    if (!alternativeVariables || !colorByDV || colorByDV==="steelblue" || !ref) return;
    const svg = d3
      .select(ref.current)
      .style("border", "1px solid black")
      .style("background", "white")
      .style("margin", "0px 0px 0px 30px")
      .style("height",() => alternativeVariables.find((av) => "" + av.id === "" + colorByDV)
      .levels.length*20+10);
    svg.selectAll("circle").remove();
    svg.selectAll("circle")
      .data(alternativeVariables.find((av) => "" + av.id === "" + colorByDV)
      .levels)
      .enter()
      .append("circle")
      .attr("cx", 10)
      .attr("cy", function(d,i){ return 10 + i*20}) // 100 is where the first dot appears. 25 is the distance between dots
      .attr("r", 7)
      .style("fill", function(d){ return dvColorScale(d)})
      .style("stroke","black");
    svg.selectAll("text").remove();
    svg.selectAll("text")
      .data(alternativeVariables.find((av) => "" + av.id === "" + colorByDV)
      .levels)
      .enter()
      .append("text")
      .attr("x", 35)
      .attr("y", function(d,i){ return 10 + i*20}) // 100 is where the first dot appears. 25 is the distance between dots
      .style("fill", function(d){ return dvColorScale(d)})
      .style("stroke","black")
      .text(function (d) {return d})
      .attr("text-anchor","left")
      .style("alignment-baseline","middle");
  }, [alternativeVariables,colorByDV,dvColorScale]);

  return (
    <div style={{position:"relative"}}><svg style={{position:"absolute",top:"-30px"}} ref={ref}></svg>
    </div>
  );
}

export default ColorByLegend;
