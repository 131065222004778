import { genNewId } from "../../util/helpers";
export default function alternativeVariablesLogic(draft, action) {
  switch (action.type) {
    case "added": {
      draft.alternativeVariables.push({
        id: genNewId(),
        name: action.av.name,
        type: action.av.type,
        levels:
          action.av.type === "num"
            ? action.av.levels.sort((a, b) => a - b)
            : action.av.levels,
        dcs: [],
        baseline: action.av.levels[0],
      });
      break;
    }
    case "setBaseline": {
      draft.alternativeVariables.find(
        (av) => av.id === action.avId
      ).baseline = action.baselineLevel;
      break;
    }
    case "changeVC": {
      let dvDc = draft.alternativeVariables.find(
        (av) => av.id === action.av.id
      ).dcs;
      let dvDcInd = dvDc.findIndex(
        (ddc) => ddc.id === action.vc.id
      );
      if (dvDcInd < 0) {
        dvDc.push({ ...action.vc, level: "low" });
      } else {
        if (dvDc[dvDcInd].level === "low")
          dvDc[dvDcInd].level = "med";
        else if (dvDc[dvDcInd].level === "med")
          dvDc[dvDcInd].level = "high";
        else {
          dvDc.splice(dvDcInd, 1);
        }
      }
      break;
    }
    //update (i.e., rename)
    case "updated": {
      draft.alternativeVariables[
        draft.alternativeVariables.findIndex(
          (av) => av.id === action.av.id
        )
      ] = action.av;
      break;
    }
    case "deleted": {
      draft.alternativeVariables =
        draft.alternativeVariables.filter(
          (s) => s.id !== action.id
        );
      break;
    }
    case "restored": {
      draft.alternativeVariables = action.alternativeVariables;
      break;
    }
    default: {
      console.log("alt vars unknown action: " + action.type);
      break;
    }
  }
}
