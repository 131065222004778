import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./helpDefine.css";

function HelpSectionDefine(props) {
  return (
    <div
      className={"helpDefineDiv" + (props.helpPoppedOut ? " poppedOut" : "")}
    >
      <h2
        className="helpSectionTitle"
        onClick={() => props.setHelpPoppedOut(!props.helpPoppedOut)}
      >
        {!props.helpPoppedOut && "? "}Welcome to Embarc!
      </h2>

      <Container>
        <Row>
          <h4></h4>
          <div>
            If you are here to explore a decision problem, you came to the right
            place.
            <br />
            Tradespace Exploration begins with defining the problem or
            opportunity. This involves describing the{" "}
            <em>motivating environment</em> of the decision: who is involved?
            what do they care about? what are their decision variables? what are
            the external variables?
            <details className="details">
              <summary>Stakeholders</summary>
              <div>
                All decision problems have at least one stakeholder affected by
                the outcome of the decision (i.e., from whom relevant Value
                Criteria are derived). In many decision problems, that same
                stakeholder controls the Alternative Variables.
              </div>
            </details>
            <details className="details">
              <summary>Value Criteria</summary>
              <div>
                Value Criteria are alternatives' measurable properties that a
                stakeholder cares about: these properties determine how much a
                stakeholder likes the outcome.
                <br />
                The two types of value are{" "}
                <em>Benefit</em> and <em>Cost</em> -- Benefit is received by a
                stakeholder, and Costs are resources "spent" (or required) by a
                stakeholder.
                <div className="smallNote">
                  (...also known as "performance attributes", "utility
                  attributes", "solution criteria", "outcomes".)
                </div>
              </div>
            </details>
            <details className="details">
              <summary>Alternative Variables</summary>
              <div>
                Alternative Variables are the variables under a stakeholder's
                control (for some resource differential): they are available
                actions or levels that a stakeholder is trying to decide between
                -- usually requiring different amounts of resources.
                <br />A combination of all alternative variables, each at a
                specified level, (i.e., a "permutation", "configuration", or
                "slice of the morphological matrix"), defines <em>an</em>{" "}
                alternative. All combinations of all possible levels forms the{" "}
                <em>alternatives space</em> -- i.e., the <em>design space.</em>
              </div>
            </details>
            <details className="details">
              <summary>Epoch Variables</summary>
              Epoch variables are variables revealed over time that can/do
              affect a decision but are outside stakeholders' control. They are
              also known as "uncertainty" or "external" variables. While they
              are often modeled elsewhere in probabilistic terms, they are
              captured and modularized in Epochs as fixed/known entities for
              better insight into their impacts on decision-making. They can
              affect the evaluative model (in Generate), in which case they are{" "}
              <em>Context Variables</em>; they can affect stakeholder value
              models, in which case they are <em>Preference Variables</em>; and
              they can affect both, in which case they are both a Context and
              Preference Variable.
              <div>
                <h5>Context Variables</h5>Context Variables are Epoch Variables
                that directly impact the evaluation of alternatives (i.e.,
                impact how value criteria are calculated by the Generate model).
                The combination of all context variables, each at a specified
                level, (i.e., a "permutation"), defines <em>a</em> context. All
                combinations of all possible levels forms the context space.
              </div>
              <div>
                <h5>Preference Variables</h5>
                Preference Variables are Epoch Variables that impact{" "}
                <em>how</em> a stakeholder cares about value criteria (i.e., the
                <em>valuation</em> of alternatives). For each combination of
                preference variables at a specified level, (i.e., a
                "permutation"), each stakeholder's value model needs to be
                defined.{" "}
                <em>
                  Currently, Embarc does not support this, but it may soon!
                </em>
              </div>
            </details>
            <details className="details">
              <summary>Number Types</summary>
              <div>
                Embarc currently supports two distinct number types for Value
                Criteria and Alternative Variables: "Number" (aka 'num') and
                "Categorical" (aka 'text'). These have implications for use in
                in value modeling:
                <p>
                  1) Stakeholder preferences on Numbers can be ascending (larger
                  is "better"), descending (smaller is "better"), or Cost
                  (smaller is better), and
                  <br />
                  2) Stakeholder preferences on Categorical variables require
                  specifying a percentage satisfied at each level of the
                  variable. (For a Cost categorical variable, the level of
                  dissatisfaction is specified.)
                </p>{" "}
                For ordinal variables, it is recommended at this time to use the
                Categorical type, making sure to self-enforce ordinality in your
                evaluative and value models.
              </div>
            </details>
            {/* <details className="details">
                <summary>Load Definition</summary>
                <div>
                  You may load a previously defined problem using the{" "}
                  <img src="/images/openDefine.png" className="smallIcon" />{" "}
                  icon in the Define title bar.
                  <div className="smallNote">
                    (NOTE: This will <em>not</em> load an evaluative model for
                    that definition! Definitions and evaluative models are
                    separate contructs.)
                  </div>
                </div>
              </details>
              <details className="details">
                <summary>Save Definition</summary>
                <div>
                  You may save a definition of a problem using the{" "}
                  <img src="/images/saveDefine.png" className="smallIcon" />{" "}
                  icon in the Define title bar.
                  <div className="smallNote">
                    (NOTE: This will <em>not</em> save any evaluative model for
                    that definition! Definitions and evaluative models are
                    separate constructs.)
                  </div>
                </div>
              </details> */}
          </div>
        </Row>
      </Container>
    </div>
  );
}
export default HelpSectionDefine;
