import { genNewId } from "../../util/helpers";
export default function preparations(draft, action) {
  switch (action.type) {
    case "added": {
      draft.preparations.push({
        id: genNewId(),
        name: action.prep.name,
        description: action.prep.description,
        dcs: [],
        levels: ["0", "1"],
      });
      break;
    }
    //update (i.e., rename)
    case "changeVC": {
      let dprep = draft.preparations.findIndex(
        (prep) => prep.id === action.prepId
      );
      let prepDc = draft.preparations[dprep].dcs;
      let prepDcInd = prepDc.findIndex(
        (pdc) => pdc.id === action.vc.id
      );
      if (prepDcInd < 0) {
        prepDc.push({ ...action.vc, level: "low" });
      } else {
        if (prepDc[prepDcInd].level === "low")
          prepDc[prepDcInd].level = "med";
        else if (prepDc[prepDcInd].level === "med")
          prepDc[prepDcInd].level = "high";
        else {
          prepDc.splice(prepDcInd, 1);
        }
      }
      break;
    }
    case "deleted": {
      draft.preparations = draft.preparations.filter(
        (prep) => prep.id !== action.id
      );
      break;
    }
    case "restored": {
      draft.preparations = action.preparations;
      break;
    }
    default: {
      console.log("Unknown action Preparations: " + action.type);
    }
  }
}
