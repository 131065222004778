import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./ewiDefine.css";
import { useStudy, useStudyDispatch } from "../../StudyContext";
import ErrorIcon from "../ErrorIcon";
import { navArray } from "../Navbar/navBarSections";

function EWIDefine(props) {
  const study = useStudy();
  const studyDispatch = useStudyDispatch();
  const errors = study.errors;
  const warnings = study.warnings;
  let sections = navArray(study.stakeholders);
  if (!study.settings.showUncertainty)
    sections = sections.filter((s) => s.section === "MATE");
  else if (!study.settings.showResponse)
    sections = sections.filter((s) => s.section !== "Response");
  return (
    <div className={"ewiDefineDiv" + (props.EWIPoppedOut ? " poppedOut" : "")}>
      <h3
        className="ewiSectionTitle"
        onClick={() => props.setEWIPoppedOut(!props.EWIPoppedOut)}
      >
        <i
          className="fas fa-notes-medical"
          style={{
            color:
              errors.filter((e) =>
              !study.settings.showUncertainty
                ? sections
                    .filter((s) => s.section === "MATE")
                    .find((s) => s.name === e.affects)
                : !study.settings.showResponse ?
                sections
                .filter((s) => s.section !== "Response")
                .find((s) => s.name === e.affects) : 
                true
            ).filter((e) => !e.ignored).length > 0
                ? "darkred"
                : warnings
                    .filter((w) =>
                      !study.settings.showUncertainty
                        ? sections
                            .filter((s) => s.section === "MATE")
                            .find((s) => s.name === w.affects)
                        : !study.settings.showResponse ?
                        sections
                        .filter((s) => s.section !== "Response")
                        .find((s) => s.name === w.affects) : 
                        true
                    )
                    .filter((w) => !w.ignored).length > 0
                ? "goldenrod"
                : "black",
          }}
        />{" "}
        Study Checkup
      </h3>
      <div className="ewiBody">
        <Container>
          <Row>
            {(!study.settings.showUncertainty
              ? sections.filter((s) => s.section === "MATE")
              : !study.settings.showResponse
              ? sections.filter((s) => s.section !== "Response")
              : sections
            )
              .filter(
                (s) =>
                  errors.map((e) => e.affects).indexOf(s.name) >= 0 ||
                  warnings.map((w) => w.affects).indexOf(s.name) >= 0
              )
              .map((section) => (
                <div key={section.name}>
                  <h5
                    style={{
                      color: errors.find(
                        (e) => e.affects === section.name && !e.ignored
                      )
                        ? "darkred"
                        : warnings.find((w) => w.affects === section.name) &&
                          !warnings.find((w) => w.affects === section.name)
                            .ignored
                        ? "goldenrod"
                        : "inherit",
                      borderBottomLeftRadius: "5px",
                      borderBottom: "2px solid black",
                      paddingTop: "10px",
                      cursor:
                        props.activeNavTab !== section.name
                          ? "pointer"
                          : "inherit",
                    }}
                    onClick={() => props.setActiveNavTab(section.name)}
                  >
                    <i className={section.class} />
                    &nbsp;{section.name}
                  </h5>
                  {errors
                    .filter((e) => e.affects === section.name)
                    .map((er) => (
                      <div
                        key={er.id}
                        className={
                          "sectionError" +
                          (er.ignored ? " sectionErrorIgnored" : "")
                        }
                      >
                        <ErrorIcon />
                        &nbsp;&nbsp;{er.description}{" "}
                        <span
                          onClick={() =>
                            studyDispatch({
                              section: "EWI",
                              type: "ignored",
                              ewi: "errors",
                              id: er.id,
                            })
                          }
                          style={{ fontWeight: "bold", cursor: "pointer" }}
                        ><div>
                          <div style={{ float: "right" }}>
                            {er.ignored ? "Un-dismiss" : "Dismiss"}
                          </div>
                          </div>
                        </span>
                      </div>
                    ))}
                  {warnings
                    .filter((w) => w.affects === section.name)
                    .map((er) => (
                      <div
                        key={er.id}
                        className={
                          "sectionWarning" +
                          (er.ignored ? " sectionErrorIgnored" : "")
                        }
                      >
                        <ErrorIcon type="warning" />
                        &nbsp;&nbsp;{er.description}{" "}
                        <span
                          onClick={() =>
                            studyDispatch({
                              section: "EWI",
                              type: "ignored",
                              ewi: "warnings",
                              id: er.id,
                            })
                          }
                          style={{ fontWeight: "bold", cursor: "pointer" }}
                        ><div>
                          <div style={{ float: "right" }}>
                            {er.ignored ? "Un-dismiss" : "Dismiss"}
                          </div>&nbsp;</div>
                        </span>{" "}
                      </div>
                    ))}
                </div>
              ))}

            {errors.length === 0 && warnings.length === 0 && (
              <div>Looks good from here!</div>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default EWIDefine;
