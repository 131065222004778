import { genNewId } from "../../util/helpers";
export default function epochVariablesLogic(draft, action) {
  switch (action.type) {
    case "added": {
      draft.epochVariables.push({
        id: genNewId(),
        name: action.ev.name,
        type: action.ev.type,
        timescale: action.ev.timescale,
        levels:
          action.ev.type === "num"
            ? action.ev.levels.sort((a, b) => a - b)
            : action.ev.levels,
        context: false,
        preference: false,
        dcs: [],
        stakeholders: [],
        baseline: action.ev.levels[0],
      });
      break;
    }
    case "setBaseline": {
      let newEpVar = draft.epochVariables.findIndex(
        (ev) => ev.id === action.evId
      );
      draft.epochVariables[newEpVar].baseline =
        action.baselineLevel;
      break;
    }
    case "changeVC": {
      let dev = draft.epochVariables.findIndex(
        (ev) => ev.id === action.ev.id
      );
      let evDc = draft.epochVariables[dev].dcs;
      let evDcInd = evDc.findIndex(
        (edc) => edc.id === action.vc.id
      );
      if (evDcInd < 0) {
        evDc.push({ ...action.vc, level: "low" });
        draft.epochVariables[dev].context = true;
      } else {
        if (evDc[evDcInd].level === "low")
          evDc[evDcInd].level = "med";
        else if (evDc[evDcInd].level === "med")
          evDc[evDcInd].level = "high";
        else {
          evDc.splice(evDcInd, 1);
          if (evDc.length < 1)
            draft.epochVariables[dev].context = false;
        }
      }
      break;
    }
    case "toggleSH": {
      let dev = draft.epochVariables.findIndex(
        (ev) => ev.id === action.ev.id
      );
      let evSHind = draft.epochVariables[
        dev
      ].stakeholders.findIndex(
        (stakeholder) => stakeholder.id === action.sh.id
      );
      if (evSHind < 0) {
        draft.epochVariables[dev].stakeholders.push({
          id: action.sh.id,
        });
        draft.epochVariables[dev].preference = true;
      } else {
        draft.epochVariables[dev].stakeholders.splice(evSHind, 1);
        if (draft.epochVariables[dev].stakeholders.length < 1)
          draft.epochVariables[dev].preference = false;
      }
      break;
    }
    //update (i.e., rename)
    case "updated": {
      draft[
        draft.epochVariables.findIndex(
          (ev) => ev.id === action.ev.id
        )
      ] = action.ev;
      break;
    }
    case "deleted": {
      draft.epochVariables = draft.epochVariables.filter(
        (s) => s.id !== action.id
      );
      break;
    }
    case "restored": {
      draft.epochVariables = action.epochVariables;
      break;
    }
    default: {
      console.log("Epoch Variables Unknown action: " + action.type);
    }
  }
}
