import { forwardRef } from "react";
import { useStudyDispatch } from "../../StudyContext";

export const SettingsPaneItem = forwardRef(function SettingsPaneItem({
  labelText,
  settingName,
  disabled = false,...props},ref
) {
  const studyDispatch = useStudyDispatch();
  return (
    <div className={"settingsItem"+(disabled?" settingDisabled":"")}>
      <input
        type="checkbox"
        id={settingName}
        ref={ref}
        disabled={disabled}
        onChange={(e) =>
          studyDispatch({
            section: "Study",
            type: "updateSetting",
            setting: settingName,
            val: e.target.checked,
          })
        }
        style={{cursor:!disabled?"pointer":"default"}}
      />
      <label for={settingName} style={{cursor:!disabled?"pointer":"default"}}>
        &nbsp;&nbsp;{labelText}
      </label>
    </div>
  );
});
