import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useStudy, useStudyDispatch } from "../../StudyContext";
import "./notesDefine.css";
import { navArray } from "../Navbar/navBarSections";

function NotesDefine(props) {
  const study = useStudy();
  const copyOfNotes = (study.notes && [...study.notes]) || [];
  const sectionsOrder = {
    Stakeholders: 1,
    "Value Criteria": 2,
    "Alternative Variables": 3,
    "Value Matrix": 4,
    "Epoch Variables": 5,
  };
  const defineNavClasses = navArray(study.stakeholders);
  return (
    <div
      className={"notesDefineDiv" + (props.NotesPoppedOut ? " poppedOut" : "")}
    >
      <h3
        className="notesSectionTitle"
        onClick={() => props.setNotesPoppedOut(!props.NotesPoppedOut)}
      >
        <i className="far fa-sticky-note" /> Study Notes
      </h3>
      <div className="notesBody">
        <Container>
          <Row>
            {copyOfNotes &&
              copyOfNotes
                .sort(
                  (a, b) => sectionsOrder[a.section] - sectionsOrder[b.section]
                )
                .map((noteInfo, i) => {
                  let entityName = "Unknown";
                  switch (noteInfo.section) {
                    case "Stakeholders":
                      entityName =
                        (study.stakeholders.find(
                          (sh) => sh.id === noteInfo.noteOnId
                        ) &&
                          study.stakeholders.find(
                            (sh) => sh.id === noteInfo.noteOnId
                          ).name) ||
                        noteInfo.noteOnId;
                      break;
                    case "Value Criteria":
                      entityName =
                        (study.valueCriteria.find(
                          (vc) => vc.id === noteInfo.noteOnId
                        ) &&
                          study.valueCriteria.find(
                            (vc) => vc.id === noteInfo.noteOnId
                          ).name) ||
                        noteInfo.noteOnId;
                      break;
                    case "Alternative Variables":
                      entityName =
                        (study.alternativeVariables.find(
                          (av) => av.id === noteInfo.noteOnId
                        ) &&
                          study.alternativeVariables.find(
                            (av) => av.id === noteInfo.noteOnId
                          ).name) ||
                        noteInfo.noteOnId;
                      break;
                    default:
                      entityName = noteInfo.noteOnId;
                  }
                  return (
                    <>
                      {(i === 0 ||
                        noteInfo.section !== copyOfNotes[i - 1].section) && (
                        <div
                          key={i}
                          className="mt-3"
                          style={{
                            backgroundColor: "rgb(245,230,230)",
                            borderRadius: "5px",
                            borderBottom: "2px solid black",
                            paddingTop: "10px",
                          }}
                        >
                          <h5
                            style={{
                              cursor:
                                props.activeNavTab !== noteInfo.section
                                  ? "pointer"
                                  : "inherit",
                              userSelect: "none",
                            }}
                            onClick={() =>
                              props.setActiveNavTab(noteInfo.section)
                            }
                          >
                            <i
                              className={
                                defineNavClasses &&
                                defineNavClasses.find(
                                  (na) => na.name === noteInfo.section
                                ).class
                              }
                            />
                            &nbsp;&nbsp;{noteInfo.section}
                          </h5>
                        </div>
                      )}
                      <div>
                        <small>
                          <small>
                            <i
                              className="fas fa-sticky-note"
                              style={{
                                color: "rgb(231, 158, 170)",
                              }}
                            />
                          </small>
                        </small>
                        &nbsp;<b>{entityName}</b>: {noteInfo.note}
                      </div>
                    </>
                  );
                })}

            {(!study.notes || study.notes.length === 0) && (
              <div>(No notes so far...)</div>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default NotesDefine;
