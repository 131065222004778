import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Table from "react-bootstrap/esm/Table";
import { useStudy } from "../../StudyContext";

function StakeholderWeightsEditModal(props) {
  const study = useStudy();
  const stakeholder = study.stakeholders.find((s) => s.id === props.shId);
  const oldWeight = useRef({});
  const [weightElColor, setWeightElColor] = useState(
    Object.keys(stakeholder.vm)
      .map((k) => stakeholder.vm[k])
      .reduce((o, vc) => Object.assign(o, { [vc.id]: "inherit" }), {})
  );
  useEffect(() => {
    Object.keys(stakeholder.vm)
      .map((k) => stakeholder.vm[k])
      .forEach((vc) => {
        oldWeight.current[vc.id] = vc.weight ? vc.weight : 1;
      });
    setWeightElColor(
      Object.keys(stakeholder.vm)
        .map((k) => stakeholder.vm[k])
        .reduce((o, vc) => Object.assign(o, { [vc.id]: "inherit" }), {})
    );
  }, [stakeholder]);

  // Make the DIV element draggable:
  useEffect(
    () => dragElement(document.getElementById("decisionCriteriaEditModal")),
    []
  );

  function dragElement(elmnt) {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    if (document.getElementById(elmnt.id + "header")) {
      // if present, the header is where you move the DIV from:
      document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
    } else {
      // otherwise, move the DIV from anywhere inside the DIV:
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = elmnt.offsetTop - pos2 + "px";
      elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  return (
    <div
      tabIndex={-1}
      className="decisionCriteriaEditModal"
      id="decisionCriteriaEditModal"
    >
      <div id="decisionCriteriaEditModalheader" style={{cursor:"move"}}>&nbsp;
        <div className="editModalCloseButton">
          <i
            className="fas fa-close"
            onClick={() => props.setShowWeightsModal(false)}
          />
        </div>
      </div>
      <h4>
        Weighting of{" "}
        <small>
          <small>
            <i className="fas fa-gem" />
          </small>
        </small>
        &nbsp;Value Criteria for &nbsp;
        <small>
          <small>
            <i className="fas fa-user" />
          </small>
        </small>
        &nbsp;{stakeholder.name}
      </h4>
      <Table striped={true} cellPadding={3}>
        <thead>
          <tr>
            <td>
              <small>
                <small>
                  <i className="fas fa-gem" />
                </small>
              </small>{" "}
              Value Criterion
            </td>
            <td align="center">
              <i className="fas fa-scale" />
              Swing Weight
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2} align="center">
              Benefit Criteria Weighting:
            </td>
          </tr>
          {Object.keys(stakeholder.vm)
            .filter((k) => stakeholder.vm[k].direction !== "cost")
            .map((k) => stakeholder.vm[k])
            .map((vc, i) => (
              <tr
                key={vc.id}
                className="editLevelRow"
                style={{
                  color: weightElColor[vc.id],
                  fontWeight:
                    weightElColor[vc.id] === "darkgoldenrod"
                      ? "bold"
                      : "inherit",
                }}
              >
                <td className="editLevelLevName">{study.valueCriteria.find(svc => svc.id===vc.id).name}</td>
                <td className="editLevelLevValue">
                  <span
                    style={vc.weight === 0 ? { opacity: 0.3 } : { opacity: 1 }}
                  >
                    0
                  </span>{" "}
                  <input
                    type="range"
                    style={{
                      width: "85%",
                      cursor: "pointer",
                      color: weightElColor[vc.id],
                    }}
                    id={"rangeFor" + vc.id}
                    defaultValue={vc.weight}
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={(e) => {
                      setWeightElColor({
                        ...weightElColor,
                        [vc.id]:
                          e.target.value * 10 ===
                          parseInt(oldWeight.current[vc.id] * 10)
                            ? "inherit"
                            : "darkgoldenrod",
                      });
                    }}
                    onMouseUp={(e) => {
                      console.log(e.target.value, oldWeight.current[vc.id]);
                      if (
                        e.target.value * 10 !==
                        parseInt(10 * oldWeight.current[vc.id])
                      )
                        props.studyDispatch({
                          section: "Stakeholders",
                          type: "changeVcWeight",
                          shId: stakeholder.id,
                          vcId: vc.id,
                          weight: e.target.value,
                        });
                    }}
                  />
                  {/* {shvmdc &&
                  shvmdc.levels.length > 0 &&
                  shvmdc.levels.find((shvmdclev) => shvmdclev.name === lev)
                    .valueLevel}
                {(!shvmdc || shvmdc.levels.length === 0) && "set"} */}
                  <span
                    style={vc.weight === 0 ? { opacity: 0.3 } : { opacity: 1 }}
                  >
                    {" "}
                    1
                  </span>
                </td>
              </tr>
            ))}
          <tr>
            <td colSpan={2} align="center">
              Cost Criteria Weighting:
            </td>
          </tr>
          {Object.keys(stakeholder.vm)
            .filter((k) => stakeholder.vm[k].direction === "cost")
            .map((k) => stakeholder.vm[k])
            .map((vc, i) => (
              <tr
                key={vc.id}
                className="editLevelRow"
                style={{
                  color: weightElColor[vc.id],
                  fontWeight:
                    weightElColor[vc.id] === "darkgoldenrod"
                      ? "bold"
                      : "inherit",
                }}
              >
                <td className="editLevelLevName">{study.valueCriteria.find(svc => svc.id===vc.id).name}</td>
                <td className="editLevelLevValue">
                  <span
                    style={vc.weight === 0 ? { opacity: 0.3 } : { opacity: 1 }}
                  >
                    0
                  </span>{" "}
                  <input
                    type="range"
                    style={{
                      width: "85%",
                      cursor: "pointer",
                      color: weightElColor[vc.id],
                    }}
                    id={"rangeFor" + vc.id}
                    defaultValue={vc.weight}
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={(e) => {
                      setWeightElColor({
                        ...weightElColor,
                        [vc.id]:
                          e.target.value * 10 ===
                          parseInt(oldWeight.current[vc.id] * 10)
                            ? "inherit"
                            : "darkgoldenrod",
                      });
                    }}
                    onMouseUp={(e) => {
                      console.log(e.target.value, oldWeight.current[vc.id]);
                      if (
                        e.target.value * 10 !==
                        parseInt(10 * oldWeight.current[vc.id])
                      )
                        props.studyDispatch({
                          section: "Stakeholders",
                          type: "changeVcWeight",
                          shId: stakeholder.id,
                          vcId: vc.id,
                          weight: e.target.value,
                        });
                    }}
                  />
                  {/* {shvmdc &&
                  shvmdc.levels.length > 0 &&
                  shvmdc.levels.find((shvmdclev) => shvmdclev.name === lev)
                    .valueLevel}
                {(!shvmdc || shvmdc.levels.length === 0) && "set"} */}
                  <span
                    style={vc.weight === 0 ? { opacity: 0.3 } : { opacity: 1 }}
                  >
                    {" "}
                    1
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div style={{ marginTop: "40px" }}>
        (<b>Swing Weight</b>: how satisfied would you be overall, when this
        criterion is at its "Best" while all other criteria are at their
        "Worst"? In other words, if this is the only criterion that performs
        well, how satisfied are you?)
      </div>
    </div>
  );
}
export default StakeholderWeightsEditModal;
