import React, { useRef } from "react";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Table from "react-bootstrap/esm/Table";
import SectionNotificationsHeader from "../SectionNotificationsHeader";
import { useStudy, useStudyDispatch } from "../../StudyContext";
import { NotesElement } from "../NotesElement";

function ShSection(props) {
  const [validatedSh, setValidatedSh] = useState(false);
  const shName = useRef();
  const study = useStudy();
  const stakeholders = study.stakeholders;

  const studyDispatch = useStudyDispatch();

  const validateSh = () => {
    let valid = false;
    if (
      shName.current.value.length > 0 &&
      stakeholders.filter((sh) => sh.name === shName.current.value).length < 1
    )
      valid = true;
    setValidatedSh(valid);
    return valid;
  };

  const addSh = () => {
    studyDispatch({
      section: "Stakeholders",
      type: "added",
      name: shName.current.value,
    });
    shName.current.value = "";
  };

  return (
    <Form.Group className="mb-3 MATESection">
      <h4>
        <NotesElement
          noteOnId={"All Stakeholders"}
          noteSection="Stakeholders"
          name={"All Stakeholders"}
        >
          Stakeholders
        </NotesElement>
      </h4>
      <SectionNotificationsHeader sectionName="Stakeholders" />
      <Table cellPadding={3} striped={true}>
        <thead></thead>
        <tbody>
          {stakeholders.map((sh) => {
            return (
              <tr key={sh.id} className="dcDetails">
                <td colSpan={2}>
                  <i
                    className="fas fa-minus-circle removeIcon"
                    onClick={() =>
                      studyDispatch({
                        section: "Stakeholders",
                        type: "deleted",
                        id: sh.id,
                      })
                    }
                  />{" "}
                  <i className="fas fa-user" />
                  &nbsp;&nbsp;&nbsp;
                  <NotesElement
                    noteOnId={sh.id}
                    noteSection="Stakeholders"
                    name={sh.name}
                  >
                    {sh.name}
                  </NotesElement>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <Form.Control
                type="name"
                ref={shName}
                placeholder="Stakeholder Name"
                onChange={validateSh}
                onKeyUp={(e) => {
                  if (e.key === "Enter" && validateSh()) addSh();
                }}
              />
            </td>
            <td>
              <button onClick={addSh} id="addAtt" disabled={!validatedSh}>
                Add
              </button>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Form.Group>
  );
}
export default ShSection;
