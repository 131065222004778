import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Table from "react-bootstrap/esm/Table";
import { useStudy } from "../../StudyContext";

function ValueCriteriaEditModal(props) {
  const study = useStudy();
  const stakeholder = study.stakeholders.find(s=>s.id===props.sh.id);
  const shvmdc = stakeholder.vm[props.dc.id];
  const levs = shvmdc && shvmdc.levels;
  const updateLev = (lev) => {};
  useEffect(() => {
    console.log("shvmdc changed: ",shvmdc)
  },[shvmdc])
  const toggleUnacceptable = (e, lev) => {
    if (e.target.checked)
      props.changeShVmForDc(stakeholder, props.dc, false, lev, -1);
    else
      props.changeShVmForDc(
        stakeholder,
        props.dc,
        false,
        lev,
        document.getElementById("rangeFor" + lev).value
      );
  };
  // uses hardcoded key -2 to indicate change cost/benefit assignment for categorical
  const updateShBen = (val) => {
    props.changeShVmForDc(
      stakeholder,
      props.dc,
      false,
      val,
      -2
    )
  }

  return (
    <div tabIndex={-1} className="decisionCriteriaEditModal">
      <div className="editModalCloseButton">
        <i
          className="fas fa-close"
          onClick={() => props.setShowDCModal(false)}
        />
      </div>
      <h4>
        Preferences of &nbsp;
        <small>
          <small>
            <i className="fas fa-user" />
          </small>
        </small>
        {props.sh.name} for &nbsp;
        <small>
          <small>
            <i className="fas fa-gem" />
          </small>
        </small>
        <em>{props.dc.name}</em> as a&nbsp;{" "}
        <small>
          <select defaultValue={shvmdc && shvmdc.direction} onChange={(ev) => updateShBen(ev.target.value)}>
            <option value="benefit">Benefit</option>
            <option value="cost">Cost</option>
          </select>
        </small>
      </h4>
      <Table striped={true} cellPadding={3}>
        <thead>
          <tr>
            <td>Level</td>
            <td align="center">{shvmdc && shvmdc.direction==="benefit"?"Unacceptable":"Dissatisfaction"}</td>
            <td align="center">{shvmdc && shvmdc.direction==="benefit"?"Satisfaction":"Unacceptable"}</td>
          </tr>
        </thead>
        <tbody>
          {props.dc.levels.map((lev, i) => (
            <tr key={lev} className="editLevelRow">
              <td className="editLevelLevName">{lev}</td>
              {shvmdc && shvmdc.direction==="benefit"?<>
              <td align="center">
                <input
                  defaultChecked={
                    levs && levs.find((l) => l.name === lev).value === -1
                  }
                  id={"unacceptable" + lev}
                  type="checkbox"
                  onChange={(e) => toggleUnacceptable(e, lev)}
                />
              </td>
              <td className="editLevelLevValue">
                <span
                  style={
                    levs && levs.find((l) => l.name === lev).value === -1
                      ? { opacity: 0.3 }
                      : { opacity: 1 }
                  }
                >
                  0%
                </span>{" "}
                <input
                  disabled={
                    levs && levs.find((l) => l.name === lev).value === -1
                  }
                  type="range"
                  style={{ width: "65%", cursor: "pointer" }}
                  id={"rangeFor" + lev}
                  defaultValue={levs && levs.find((l) => l.name === lev).value}
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={() => updateLev(lev)}
                  onMouseUp={(e) =>
                    props.changeShVmForDc(
                      stakeholder,
                      props.dc,
                      false,
                      lev,
                      e.target.value
                    )
                  }
                />
                {/* {shvmdc &&
                    shvmdc.levels.length > 0 &&
                    shvmdc.levels.find((shvmdclev) => shvmdclev.name === lev)
                      .valueLevel}
                  {(!shvmdc || shvmdc.levels.length === 0) && "set"} */}
                <span
                  style={
                    levs && levs.find((l) => l.name === lev).value === -1
                      ? { opacity: 0.3 }
                      : { opacity: 1 }
                  }
                >
                  {" "}
                  100%
                </span>
              </td></>
            : <>
            <td className="editLevelLevValue">
              <span
                style={
                  levs && levs.find((l) => l.name === lev).value === -1
                    ? { opacity: 0.3 }
                    : { opacity: 1 }
                }
              >
                0%
              </span>{" "}
              <input
                disabled={
                  levs && levs.find((l) => l.name === lev).value === -1
                }
                type="range"
                style={{ width: "65%", cursor: "pointer" }}
                id={"rangeFor" + lev}
                defaultValue={levs && levs.find((l) => l.name === lev).value}
                min={0}
                max={1}
                step={0.1}
                onChange={() => updateLev(lev)}
                onMouseUp={(e) =>
                  props.changeShVmForDc(
                    stakeholder,
                    props.dc,
                    false,
                    lev,
                    e.target.value
                  )
                }
              />
              {/* {shvmdc &&
                  shvmdc.levels.length > 0 &&
                  shvmdc.levels.find((shvmdclev) => shvmdclev.name === lev)
                    .valueLevel}
                {(!shvmdc || shvmdc.levels.length === 0) && "set"} */}
              <span
                style={
                  levs && levs.find((l) => l.name === lev).value === -1
                    ? { opacity: 0.3 }
                    : { opacity: 1 }
                }
              >
                {" "}
                100%
              </span>
            </td>
            <td align="center">
            <input
              defaultChecked={
                levs && levs.find((l) => l.name === lev).value === -1
              }
              id={"unacceptable" + lev}
              type="checkbox"
              onChange={(e) => toggleUnacceptable(e, lev)}
            />
          </td></>}
            </tr>
          ))}
        </tbody>
      </Table>
      <div
        style={{ cursor: "pointer", fontWeight: "bold" }}
        onClick={() => {
          props.changeShVmForDc(stakeholder, props.dc, true);
          props.setShowDCModal(false);
        }}
      >
        (or: remove all preferences.)
      </div>
    </div>
  );
}
export default ValueCriteriaEditModal;
