import { useCallback, useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ExploreScatterplot from "./ExploreScatterplot";
import { useStudy, useStudyDispatch } from "./StudyContext";
import "./ExploreSection.css";

function ExploreSection(props) {
  const study = useStudy();
  const evaluations = study.evaluations;
  const studyDispatch = useStudyDispatch();
  const oldShs = useRef(null);
  const oldEvals = useRef(null);
  const [busyMouse, setBusyMouse] = useState(false);

  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }
  const evalsChanged = useCallback(() => {
    let changed = false;
    console.log("evals: ",oldEvals.current,evaluations);
    if (oldEvals.current && oldEvals.current.length>0) {
      oldEvals.current.forEach((oe, i) => {
        Object.keys(oe).forEach((oek) => {
          try {
            if (
              ((isNaN(evaluations[i][oek]) || isNaN(oe[oek])) &&
                isNaN(evaluations[i][oek]) !== isNaN(oe[oek])) ||
              evaluations[i][oek] !== oe[oek]
            )
              changed = true;
          } catch {
            changed = true;
          }
        });
      });
    } else if (evaluations && evaluations.length>0 && (!oldEvals.current || oldEvals.current.length===0)) {
      console.log("oldEvals is nothing but evaluations exist");
      changed = true;
    }
    console.log("evals changed: ", changed);
    return changed;
  }, [evaluations]);
  const compLevels = (levArr1, levArr2) => {
    let compEqual = true;
    levArr1.forEach((la1) => {
      compEqual =
        compEqual &&
        levArr2.find((la2) => la2.name === la1.name).value === la1.value;
    });
    return compEqual;
  };
  const valueModelsChanged = useCallback(() => {
    // TODO: do some checks on all stakeholders
    let changed = false;
    if (oldShs.current && oldShs.current.length > 0) {
      study.stakeholders.forEach((sh) => {

        if (!sh.vm || !oldShs.current.find((os) => os.id === sh.id))
          changed = false;
        else if (
          Object.keys(sh.vm).length !==
          Object.keys(oldShs.current.find((os) => os.id === sh.id).vm).length
        )
          changed = true;
        else
          Object.keys(sh.vm).forEach((shvmk) => {
            let oldShsVM = oldShs.current.find((os) => os.id === sh.id).vm[
              shvmk
            ];
            changed =
              changed ||
              !oldShsVM ||
              oldShsVM.direction !== sh.vm[shvmk].direction ||
              oldShsVM.weight !== sh.vm[shvmk].weight ||
              !compLevels(oldShsVM.levels, sh.vm[shvmk].levels);
          });
      });
    }
    console.log("vals changed: ", changed);
    return changed;
  }, [study.stakeholders]);

  useEffect(() => console.log(props.modelRunError), [props.modelRunError]);
  useEffect(() => {
    if (
      evaluations &&
      evaluations.length > 0 &&
      (valueModelsChanged() || evalsChanged())
    ) {
      if (!busyMouse) {
        setBusyMouse(true);
        postData("/api/getvaluations", {
          stakeholders: study.stakeholders,
          valueCriteria: study.valueCriteria,
          evaluations: evaluations,
        })
          .then((data) => {
            //console.log("valuations length: ", data.valueScores);
            studyDispatch({
              section: "Study",
              type: "vals",
              vals: data.valueScores,
            });
            setBusyMouse(false);
          })
          .catch(() => {
            setBusyMouse(false);
            alert("Server error; please try again later.");
          })
          .finally(() => {
            oldShs.current = study.stakeholders;
            oldEvals.current = evaluations;
          });
      }
    }
    oldShs.current = study.stakeholders;
    oldEvals.current = evaluations;
  }, [
    study,
    studyDispatch,
    valueModelsChanged,
    evaluations,
    evalsChanged,
    busyMouse,
  ]);
  useEffect(() => {
    setBusyMouse(true);
    setBusyMouse(false);
  }, []);
  return (
    <Container style={{ cursor: busyMouse ? "wait" : "inherit" }}>
      <Row>
        {props.modelRunError && (
          <div className="modelErrorNotice">
            MODEL ERROR{" "}
            {window.LoopTrap === 0 && "(looped more than 999 times)"}
          </div>
        )}
      </Row>
      <Row>
        {study.valuations && Object.keys(study.valuations).length>0 ? <ExploreScatterplot /> : "Please Generate evaluations of alternatives to begin exploration."}
      </Row>
    </Container>
  );
}
export default ExploreSection;
