import { genNewId } from "../../../util/helpers";

export default function alternativeVariableErrorChecking(draft, action, study) {
  if (draft.alternativeVariables.length < study.alternativeVariables.length) {
    let deletedAV = study.alternativeVariables.find(
      (oav) => draft.alternativeVariables.map((av) => av.id).indexOf(oav.id) < 0
    );
    let existingError = draft.errors.findIndex(
      (er) =>
        er.affects === "Alternative Variables" && er.affectsId === deletedAV.id
    );
    draft.errors = draft.errors.filter((e, i) => i !== existingError);
  } else {
    // get alternative variables that don't have value criteria
    let avWithoutVC = draft.alternativeVariables.filter(
      (av) => av.dcs.length === 0
    );
    let avWithVC = draft.alternativeVariables.filter((av) => av.dcs.length > 0);
    // if no error exists already, add one
    avWithoutVC.forEach((av) => {
      if (
        !draft.errors.find(
          (er) =>
            er.affects === "Alternative Variables" && er.affectsId === av.id
        )
      )
        draft.errors.push({
          id: genNewId(),
          name: "No Value Criteria",
          affects: "Alternative Variables",
          affectsId: av.id,
          description:
            av.name +
            " does not affect any value criteria. Please map it to at least one value criterion, or consider removing it from the study.",
          ignored: false,
        });
    });
    // check for existing errors that have been resolved
    avWithVC.forEach((av) => {
      let existingError = draft.errors.findIndex(
        (er) => er.affects === "Alternative Variables" && er.affectsId === av.id
      );
      draft.errors = draft.errors.filter((e, i) => i !== existingError);
    });
  }
}
