import { useStudy, useStudyDispatch } from "../StudyContext";
import ErrorIcon from "./ErrorIcon";

function SectionNotificationsHeader({ sectionName, ...props }) {
  const study = useStudy();
  const studyDispatch = useStudyDispatch();
  const errors = study.errors;
  
  const warnings = study.warnings;
  return (study.settings["showEWIinline"] && 
    <>
      {errors
        .filter((er) => !er.ignored && er.affects === sectionName)
        .map((er) => (
          <div key={er.id} className="sectionError">
            <ErrorIcon />
            &nbsp;&nbsp;{er.description}{" "}
            <span
              onClick={() => studyDispatch({
                section: "EWI",
                type: "ignored",
                ewi: "errors",
                id: er.id,
              })}
              style={{ fontWeight: "bold", cursor: "pointer" }}
            >
              &nbsp;&nbsp;Dismiss
            </span>
          </div>
        ))}
      {warnings
        .filter((er) => !er.ignored && er.affects === sectionName)
        .map((er) => (
          <div key={er.id} className="sectionWarning">
            <ErrorIcon type="warning" />
            &nbsp;&nbsp;{er.description}{" "}
            <span
              onClick={() =>
                studyDispatch({
                  section: "EWI",
                  type: "ignored",
                  ewi: "warnings",
                  id: er.id,
                })
              }
              style={{ fontWeight: "bold", cursor: "pointer" }}
            >
              &nbsp;&nbsp;Dismiss
            </span>
          </div>
        ))}
    </>
  );
}
export default SectionNotificationsHeader;
