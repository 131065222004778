function CustomNavIcon(props) {
  return (
    <div style={{ position: "relative" }}>
      <i className="fas fa-globe-americas navTabIcon" />
      {props.type !== "both"?
      <i
        className={
          "customNavIcon " +
          props.type +
          (props.type === "fas fa-eye" ? " customNavIconEye" : " ")
        }
      /> : <>
      <i className="customNavIcon fas fa-calculator"/><i className="customNavIcon fas fa-eye customNavIconEye" /></>}
    </div>
  );
}
export default CustomNavIcon;
