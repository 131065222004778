import { Col, Row } from "react-bootstrap";
import CrossEpochVariableRules from "../CrossEpochVariableRules.js";
import IntraEpochVariableRules from "./IntraEpochVariableRules.js";
import { useRef, useState } from "react";
// import EpochVariableDurationRules from "./EpochVariableDurationRules.js";
import EpochVariableDistributions from "./EpochVariableDistributions.js";

function RulesBasedEraConstructor({ epochVariables, ...props }) {
  const [crossEpochVariableRules, setCrossEpochVariableRules] = useState([]);
  const [enableCrossVariableRules, setEnableCrossVariableRules] = useState(
    crossEpochVariableRules.length > 0
  );
  
  const [eraLength, setEraLength] = useState(6);
  const [intraEpochRules, setIntraEpochRules] = useState([]);
  const [enableIntraEpochVariableRules, setEnableIntraEpochVariableRules] =
    useState(crossEpochVariableRules.length > 0);
  // const [durationRules, setDurationRules] = useState([]);
  // const [enableDurationRules, setEnableDurationRules] = useState(
  //   crossEpochVariableRules.length > 0
  // );
  const eraTimescale =
    epochVariables.map((ev) => ev.timescale).indexOf("years") >= 0
      ? "years"
      : epochVariables.map((ev) => ev.timescale).indexOf("months") >= 0
      ? "months"
      : "days";
  const [eraUnits, setEraUnits] = useState(eraTimescale);

  const epochUnitsRef = useRef(true);
  const timescaleUnitsRef = useRef(false);
  const changeEraUnits = (e) => {
    if (e.target.checked && e.target === epochUnitsRef.current)
      timescaleUnitsRef.current.checked = false;
    else if (e.target.checked && e.target === timescaleUnitsRef.current)
      epochUnitsRef.current.checked = false;

    setEraUnits(
      epochUnitsRef.current.checked ? "epochs" : eraTimescale
    );
  };
  const changeEraLength = (e) => {
    setEraLength(e.target.value);
  };


  return (
    <>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <h4>Advanced Era Constructor</h4>
      </Row>
      <Row>
      <h5 className="eraCreationRowHeader">Era Length: </h5>
      <Col><input
        min={2}
        max={20}
        step={1}
        defaultValue={6}
        style={{ width: "50px" }}
        type="number"
        onChange={changeEraLength}
      />
      <input
      disabled={true}
        ref={epochUnitsRef}
        defaultChecked={false}
        onChange={(e) => changeEraUnits(e)}
        style={{ margin: "10px", marginRight: "0px" }}
        type="radio"
      />
      epochs
      <input
        ref={timescaleUnitsRef}
        defaultChecked={true}
        onChange={(e) => changeEraUnits(e)}
        style={{ margin: "10px", marginRight: "0px" }}
        type="radio"
      />
      {eraTimescale}</Col>
      </Row>
      <Row className="mt-4">
        <h5 className="eraCreationRowHeader">Duration Distributions</h5>
        <EpochVariableDistributions epochVariables={epochVariables} eraTimescale={eraTimescale} eraLength={eraLength} />
      </Row>
      {/* <Row className="mt-4">
        <Col xs={12}>
          <input
            type="checkbox"
            defaultChecked={false}
            onChange={(ev) => setEnableDurationRules(ev.target.checked)}
          />{" "}
          <span style={{ fontWeight: "bold" }}>
            Epoch Variable Duration Rules
          </span>
        </Col>
      </Row>
      <Row>
        {enableDurationRules && (
          <EpochVariableDurationRules
            epochVariables={epochVariables}
            durationRules={durationRules}
            setDurationRules={setDurationRules}
          />
        )}
      </Row> */}
      <Row className="mt-4">
        <h5 className="eraCreationRowHeader">
          <input
            type="checkbox"
            defaultChecked={false}
            onChange={(ev) =>
              setEnableIntraEpochVariableRules(ev.target.checked)
            }
          />{" "}
          Epoch Variable Transition Rules
        </h5>
      </Row>{" "}
      <Row>
        {enableIntraEpochVariableRules && (
          <IntraEpochVariableRules
            epochVariables={epochVariables}
            intraEpochRules={intraEpochRules}
            setIntraEpochRules={setIntraEpochRules}
          />
        )}
      </Row>{" "}
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <h5 className="eraCreationRowHeader">
          <input
            type="checkbox"
            defaultChecked={false}
            onChange={(ev) => setEnableCrossVariableRules(ev.target.checked)}
          />{" "}
          Cross-Variable Transition Rules
        </h5>
      </Row>
      <Row>
        {enableCrossVariableRules && (
          <CrossEpochVariableRules
            epochVariables={epochVariables}
            crossVariableRules={crossEpochVariableRules}
            setCrossVariableRules={setCrossEpochVariableRules}
          />
        )}
      </Row>
    </>
  );
}
export default RulesBasedEraConstructor;
