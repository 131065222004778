import React, { useCallback, useEffect, useMemo, useRef } from "react";

import "../bootstrap.css";
import "./ModelSection.css";
import "./Define/Define.css";
import { useState } from "react";
import DefineSection from "./Define/DefineSection";
import EvalModelSection from "./EvalModelSection";
import PreviewSection from "./PreviewSection";
import HomeSection from "./HomeSection";
import { cartesianProduct, cartesianProductObjects } from "../util/helpers";
import { useStudy, useStudyDispatch } from "./StudyContext";
import ExploreSection from "./ExploreSection";
import {
  createBlankItem,
  createItem,
  useItemsByOwner,
  useOSVitem,
} from "../util/db";
import { spacetugexampledata } from "../spacetugexample";

function ModelSection(props) {
  const [attributeEvaluations, setAttributeEvaluations] = useState({});
  const [intermediateEvaluations, setIntermediateEvaluations] = useState([]);
  const [lastRunPerf, setLastRunPerf] = useState("");
  const [modelRunError, setModelRunError] = useState(false);
  const [previewPoppedOut, setPPO] = useState(false);
  const [previewPin, setPreviewPin] = useState(false);
  const [activeTab, setActiveTab] = useState("define");
  const [enabledGenerate, setEnabledGenerate] = useState(false);
  const [enabledExplore, setEnabledExplore] = useState(false);
  const studyDispatch = useStudyDispatch();
  const loadedOneStudy = useRef(false);
  const study = useStudy();
  const [valuations, setValuations] = useState(study.valuations || null);
  const valueCriteria = study.valueCriteria;
  const alternativeVariables = study.alternativeVariables;
  const epochVariables = study.epochVariables;
  const cvs = epochVariables && epochVariables.filter((ep) => ep.context);
  const preparations = study.preparations;
  const oldValuations = useRef(study.valuations || {});
  const oneStudyVersionItem = useOSVitem();
  const usersItem = useItemsByOwner(props.user && props.user.uid);
  const [loadInitial,setLoadInitial] = useState(false);
  window["dc"] = {};
  window["dv"] = {};
  window["cv"] = {};
  window["prep"] = {};

  const setPreviewPoppedOut = (tOrF) => {
    setPPO(tOrF);
  };
  const oldModelCode = useRef("");
  const running = useRef(false);

  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  useEffect(() => {
    if (valuations && valuations !== oldValuations.current) {
      //console.log("valuations; ", valuations);
      studyDispatch({ section: "Study", type: "vals", vals: valuations });
      oldValuations.current = valuations;
    }
  }, [valuations, studyDispatch]);

  //keep enabledGenerate up to date with changes in VC, AV, and EV
  useEffect(() => {
    if (
      valueCriteria &&
      valueCriteria.length > 0 &&
      ((alternativeVariables && alternativeVariables.length > 0) ||
        (epochVariables && epochVariables.length > 0))
    ) {
      setEnabledGenerate(true);
      // for now, just enable Explore if Generate is enabled
      setEnabledExplore(true);
    } else {
      setEnabledGenerate(false);
      // for now, disable Explore if Generate is disabled
      setEnabledExplore(false);
    }
  }, [valueCriteria, alternativeVariables, epochVariables]);

  const runModel = (modelCode, diffOverride = false) => {
    // don't run if we are already running the model, or if the model is the same as the last run
    if (
      !running.current &&
      (diffOverride || modelCode !== oldModelCode.current)
    ) {
      document.getElementsByTagName("body").style = { cursor: "wait" };
      oldModelCode.current = modelCode;
      // saveAs(modelCode);
      running.current = true;
      let newEvals = [];
      console.log("running");
      // console.log(cartesianProductObjects(
      //       alternativeVariables
      //         .concat(cvs)
      //         .concat(preparations)
      //         .map((dvOrCv) => {return {[dvOrCv.name]:dvOrCv.levels}})
      //     ));
      cartesianProductObjects(
        alternativeVariables
          .concat(cvs)
          .concat(preparations)
          .map((dvOrCv) => {
            return { [dvOrCv.name]: dvOrCv.levels };
          })
      ).forEach((config) => {
        document.getElementsByClassName("runningModel")[0].style.display =
          "block";
        // track runtime of one config
        let startTime = performance.now();

        //reset evals
        let allEvals = [];
        window["intermediateVars"] = [];
        valueCriteria.forEach((att, i) => {
          window["dc"][att.name.trim()] = "NOT EVALUATED";
        });

        // assign dv and cv and prep variables to this config
        Object.keys(config).forEach((key, i) => {
          const lev = config[key];
          const varType = alternativeVariables.find((av) => av.name === key)
            ? alternativeVariables
            : cvs.find((cv) => cv.name === key)
            ? cvs
            : preparations;
          window[
            varType === alternativeVariables
              ? "dv"
              : varType === cvs
              ? "cv"
              : "prep"
          ][key] =
            varType.find((v) => v.name === key).type === "num"
              ? parseFloat(lev)
              : lev.trim();
        });

        try {
          let results = eval(modelCode);
          setModelRunError(false);

          valueCriteria.forEach((att) => {
            allEvals.push(window["dc"][att.name.trim()]);
          });
          // window["intermediateVars"].forEach((intVar) => {
          //   allEvals.push(intVar.level);
          // });
          let newEvaluation = { ...config };
          allEvals.forEach((evaluation, i) => {
            newEvaluation[valueCriteria[i].name] = evaluation;
          });
          newEvals.push(newEvaluation);
          let endTime = performance.now();
          setLastRunPerf(Math.ceil(endTime - startTime));
        } catch (e) {
          setModelRunError(true);
          console.log(e);
          //console.log(modelCode);
          // reset variables
          valueCriteria.forEach((att) => {
            window["dc"][att.name] = "NOT EVALUATED";
          });
          setLastRunPerf(0);
        }

        if (lastRunPerf > 500)
          document.getElementsByClassName("runningModel")[0].style.display =
            "none";
        else
          setTimeout(
            () =>
              (document.getElementsByClassName(
                "runningModel"
              )[0].style.display = "none"),
            500
          );
      });
      setIntermediateEvaluations(window["intermediateVars"]);
      studyDispatch({ section: "Study", type: "evals", evals: newEvals });

      setAttributeEvaluations(newEvals);

      running.current = false;
    }
  };

  const exportCsv = () => {
    // first create the attribute evaluations
    //attributeEvaluations[config.map((lev) => "l" + lev).join(",")] = allEvals;
    let evalsAsText = "";
    evalsAsText +=
      "" +
      (alternativeVariables.length > 0
        ? alternativeVariables.map((dv) => dv.name).join(",") + ","
        : "") +
      (cvs.length > 0 ? cvs.map((cv) => cv.name).join(",") + "," : "") +
      (preparations.length > 0
        ? preparations.map((prep) => prep.name).join(",") + ","
        : "") +
      valueCriteria.map((att) => att.name).join(",") +
      "," +
      window["intermediateVars"].map((intVar) => intVar.name).join(",") +
      "\n";
    Object.keys(attributeEvaluations).forEach((key) => {
      evalsAsText +=
        key
          .split(",")
          .map((kp, i) =>
            // remove the leading letter 'l' that we added for the config name
            kp.slice(1)
          )
          .join(",") +
        "," +
        attributeEvaluations[key].join(",") +
        "\n";
    });
    // now start the download
    var resultsCsvLink = document.createElement("a");
    resultsCsvLink.setAttribute(
      "href",
      "data:text/plain;charset=urf-8," + encodeURIComponent(evalsAsText)
    );
    resultsCsvLink.setAttribute("download", "results.csv");
    resultsCsvLink.click();
  };

  const restoreDefine = useCallback(
    (defineState) => {
      defineState.study &&
        studyDispatch({
          section: "Study",
          type: "restored",
          study: defineState.study,
        });
    },
    [studyDispatch]
  );

  const newStudy = useMemo(() => {
    return {
      study: {
        name: "New Study",
        stakeholders: [],
        valueCriteria: [],
        alternativeVariables: [],
        epochVariables: [],
        preparations: [],
        changeOptions: [],
        warnings: [],
        errors: [],
        settings: {
          showResponse: true,
          showUncertainty: true,
          showEWIinline: false,
        },
        activeUsers: [],
        patchHistory: [],
        notes: [],
        fbid: null,
        evaluations: [],
        valuations: {},
        redoStep: 0,
        lastModified: "2023-07-05T20:21:18.471Z",
        evalmodel: "{}",
      },
      owner: props.user && props.user.uid,
      shared: [],
    };
  }, [props.user]);

  const getFBItemStudy = useCallback((fbItem, user) => {
    let newsettings = {};
    newsettings[user.uid] = { activeNavTab: "Stakeholders" };
    return {
      study: {
        owner: user,
        fbid: fbItem.id,
        name: fbItem.study.name || "Unnamed Project",
        errors: fbItem.study.errors || [],
        warnings: fbItem.study.warnings || [],
        redoStep: fbItem.study.redoStep || 0,
        patchHistory: fbItem.study.patchHistory || [],
        preparations:
          fbItem.study.preparations ||
          fbItem.study.preps ||
          fbItem.study.pes ||
          [],
        changeOptions:
          fbItem.study.changeOptions ||
          fbItem.study.options ||
          fbItem.study.avrs ||
          [],
        epochVariables: fbItem.study.epochVariables || fbItem.study.evs || [],
        alternativeVariables:
          fbItem.study.alternativeVariables ||
          fbItem.study.avs ||
          fbItem.study.dvs ||
          [],
        valueCriteria:
          fbItem.study.valueCriteria ||
          fbItem.study.vcs ||
          fbItem.study.dcs ||
          [],
        stakeholders: fbItem.study.stakeholders || fbItem.study.shs || [],
        settings: fbItem.study.settings || newsettings,
        activeUsers: fbItem.study.activeUsers || {},
        notes: fbItem.study.notes || [],
        evaluations: fbItem.study.evaluations || [],
        valuations: fbItem.study.valuations || {},
        evalmodel: fbItem.study.evalmodel,
      },
    };
  }, []);

  // for One-study-version, load a user's study first if it exists;
  // otherwise load Space Tug Explore dataset on page load
  useEffect(() => {
    console.log("users item:", usersItem);
    if (
      props.user &&
      usersItem &&
      usersItem.status === "success" &&
      (!loadedOneStudy.current || loadedOneStudy.current === 1)
    )
      if (usersItem.data.length > 0) {
        restoreDefine(getFBItemStudy(usersItem.data[0], props.user));
        loadedOneStudy.current = 2;
      } else if (!loadedOneStudy.current) {
        console.log("creating");
        let projectState = newStudy;
        projectState.owner = props.user.uid;
        createItem(projectState).then((docRef) => {
          projectState.id = docRef.id;
          projectState.study.fbid = docRef.id;
          restoreDefine(getFBItemStudy(projectState, props.user));
        });
        loadedOneStudy.current = 1;
      }
  }, [usersItem, props.user, restoreDefine, getFBItemStudy, newStudy]);
  const resetToST = (studyToLoad) => {
    console.log(studyToLoad);
    if (studyToLoad) {
      setLoadInitial(false);
      studyDispatch({section:"Study",type:"evalmodel",evalmodel:"{}"})
    }
    let projectState = (studyToLoad && newStudy) || spacetugexampledata;
    projectState.owner = props.user.uid;
    projectState.id = study.fbid;
    projectState.study.fbid = study.fbid;
    restoreDefine(getFBItemStudy(projectState, props.user));
    if (!studyToLoad)
      setLoadInitial(false);
    alert(((studyToLoad && "New, blank study") || "Original Space Tug dataset") +" successfully loaded.");
  };

  return (
    study.stakeholders &&
    valueCriteria &&
    alternativeVariables &&
    epochVariables &&
    preparations && (
      <>
        <div className="tabBar">
          <div
            className={
              "topTab defineTopTab" +
              (activeTab === "define" ? " activeTab" : "")
            }
            onClick={() => setActiveTab("define")}
          >
            Define
          </div>
          <div
            className={
              "topTab evalTopTab" +
              (activeTab === "generate"
                ? " activeTab"
                : enabledGenerate
                ? ""
                : " disabledTab")
            }
            onClick={() => {
              if (enabledGenerate) {
                setActiveTab("generate");
              }
            }}
          >
            Generate
          </div>
          <div
            className={
              "topTab exploreTopTab" +
              (activeTab === "explore"
                ? " activeTab"
                : enabledExplore
                ? ""
                : " disabledTab")
            }
            onClick={() => {
              if (enabledExplore) {
                setActiveTab("explore");
              }
            }}
          >
            Explore
          </div>
        </div>
        <div
          className="exploreSection"
          style={activeTab !== "explore" ? { display: "none" } : {}}
        >
          <ExploreSection modelRunError={modelRunError} />
        </div>

        <div
          className={
            previewPoppedOut
              ? "previewSection poppedOutPreview"
              : "previewSection poppedInPreview"
          }
        >
          <PreviewSection
            previewPoppedOut={activeTab === "generate"}
            setPreviewPoppedOut={setPreviewPoppedOut}
            setPreviewPin={setPreviewPin}
            previewPin={previewPin}
            definePoppedOut={activeTab === "define"}
            lastRunPerf={lastRunPerf}
            exportCsv={exportCsv}
            intEvals={intermediateEvaluations}
          />
        </div>
        <div
          className="evalSection"
          style={activeTab !== "generate" ? { display: "none" } : {}}
        >
          <EvalModelSection
            runModel={runModel}
            modelRunError={modelRunError}
            lastRunPerf={lastRunPerf}
            setPreviewPoppedOut={setPreviewPoppedOut}
            previewPin={previewPin}
            loadInitial={loadInitial}
            setLoadInitial={setLoadInitial}
          />
        </div>

        <div
          className="defineSection"
          style={activeTab !== "define" ? { display: "none" } : {}}
        >
          <DefineSection user={props.user} resetToST={resetToST} />
        </div>
      </>
    )
  );
}

export default ModelSection;
