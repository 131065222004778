import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import AVSection from "./MATE/AVSection";
import DecisionCriteriaSection from "./MATE/ValueCriteriaSection";
import ShSection from "./MATE/ShSection";
import EVSection from "./Uncertainty/EVSection";
import HelpSectionDefine from "./Sidebar/HelpSectionDefine";
import ValueMatrixSection from "./ValueMatrixSection";
import ChangeOptionsSection from "./Response/ChangeOptionsSection";
import PreparationSection from "./Response/PreparationsSection";
import AnimatedBg from "./AnimatedBg";
import DefineNavBar from "./Navbar/defineNavBar";
import { navArray } from "./Navbar/navBarSections";
import EpVarTransitionSection from "./Uncertainty/EpochTransitionsSection";

import EraCreationSection from "./Uncertainty/EraCreationSection";
import { pressedCtrlY, pressedCtrlZ } from "../../util/helpers";
import { useStudy, useStudyDispatch } from "../StudyContext";
import UndoSectionDefine from "./Sidebar/UndoSectionDefine";
import EWIDefine from "./Sidebar/EWISectionDefine";
import NotesDefine from "./Sidebar/NotesSectionDefine";
import SettingsDefine from "./Sidebar/SettingsDefine";

function DefineSection(props) {
  const study = useStudy();
  const stakeholders = study.stakeholders;
  const valueCriteria = study.valueCriteria;
  const alternativeVariables = study.alternativeVariables;
  const studyDispatch = useStudyDispatch();

  const activeNavTab =
    (study.settings &&
      props.user &&
      study.settings[props.user.uid] &&
      study.settings[props.user.uid].activeNavTab) ||
    "Stakeholders";
  const setActiveNavTab = useCallback(
    (tabName) =>
      studyDispatch({
        section: "Study",
        type: "changeActiveNavTab",
        activeNavTab: tabName,
      }),
    [studyDispatch]
  );
  const [helpPoppedOut, setHelpPoppedOut] = useState(false);
  const [EWIPoppedOut, setEWIPoppedOut] = useState(false);
  const [NotesPoppedOut, setNotesPoppedOut] = useState(false);
  const [settingsPoppedOut, setSettingsPoppedOut] = useState(false);
  const navSections = useMemo(() => navArray(stakeholders), [stakeholders]);
  const [navReady, setNavReady] = useState(
    navSections.map((ns, i) => (i === 0 ? true : false))
  );
  
  const switchToNavTab = (secName) => {
    setActiveNavTab(secName);
  };
  // when starting a new project, reset the active nav tab to stakeholders
  useEffect(() => {
    // console.log("setting");
    // if (
    //   stakeholders.length === 0 &&
    //   valueCriteria.length === 0 &&
    //   alternativeVariables.length === 0
    // )
    //   setActiveNavTab("Stakeholders");
  }, [stakeholders, valueCriteria, alternativeVariables, setActiveNavTab]);

  return (
    <div
      className={
        "defineSection" +
        (navSections.find((ns) => ns.name === activeNavTab).section ===
        "Response"
          ? " activeSectionChange"
          : navSections.find((ns) => ns.name === activeNavTab).section ===
            "Uncertainty"
          ? " activeSectionEEA"
          : " activeSectionMATE")
      }
      style={props.activeTab > 0 ? { display: "none" } : {}}
      tabIndex={-1}
      onKeyDown={(e) =>
        pressedCtrlZ(e)
          ? studyDispatch({ section: "Study", type: "UNDO" })
          : pressedCtrlY(e)
          ? studyDispatch({ section: "Study", type: "REDO" })
          : ""
      }
    >
      {" "}
      {navSections.find((ns) => ns.name === activeNavTab).section ===
      "Response" ? (
        <AnimatedBg baseRGB={[158, 188, 108]} />
      ) : navSections.find((ns) => ns.name === activeNavTab).section ===
        "Uncertainty" ? (
        <AnimatedBg baseRGB={[196, 163, 141]} />
      ) : (
        <></>
      )}
      <div
        className={
          "defineSectionTitle" +
          (navSections.find((ns) => ns.name === activeNavTab).section ===
          "Response"
            ? " responseAnim"
            : "")
        }
      >
        {navSections.find((ns) => ns.name === activeNavTab).section}
      </div>
      {
        <SettingsDefine
          settingsPoppedOut={settingsPoppedOut}
          setSettingsPoppedOut={setSettingsPoppedOut}
          resetToST={props.resetToST}
          user={props.user}
        />
      }
      {
        <NotesDefine
          NotesPoppedOut={NotesPoppedOut}
          setNotesPoppedOut={setNotesPoppedOut}
          setActiveNavTab={setActiveNavTab}
          activeNavTab={activeNavTab}
        />
      }
      <EWIDefine
        EWIPoppedOut={EWIPoppedOut}
        setEWIPoppedOut={setEWIPoppedOut}
        setActiveNavTab={setActiveNavTab}
        activeNavTab={activeNavTab}
      />{" "}
      
      <HelpSectionDefine
        helpPoppedOut={helpPoppedOut}
        setHelpPoppedOut={setHelpPoppedOut}
      />
      <Container container={"true"} size={"md"}>
        <Row>
          <DefineNavBar
            user={props.user}
            navSections={navSections}
            navReady={navReady}
            activeNavTab={activeNavTab}
            setNavReady={setNavReady}
            setActiveNavTab={setActiveNavTab}
          />
        </Row>
        <Row>
          {activeNavTab === "Stakeholders" && <ShSection />}
          {activeNavTab === "Value Criteria" && (
            <DecisionCriteriaSection setActiveNavTab={switchToNavTab} />
          )}
          {activeNavTab === "Alternative Variables" && (
            <AVSection setActiveNavTab={switchToNavTab} />
          )}
          {activeNavTab === "MATE Value Matrix" && (
            <ValueMatrixSection preps={[]} defaultShowVarType={"alternative"} />
          )}
          {activeNavTab === "Epoch Variables" && (
            <EVSection setActiveNavTab={switchToNavTab} />
          )}
          {activeNavTab === "Uncertainty Value Matrix" && (
            <ValueMatrixSection preps={[]} defaultShowVarType={"epoch"} />
          )}
          {activeNavTab === "Epoch Transitions" && <EpVarTransitionSection />}
          {activeNavTab === "Era Creation" && <EraCreationSection />}
          {activeNavTab === "Change Options" && (
            <ChangeOptionsSection setActiveNavTab={switchToNavTab} />
          )}
          {activeNavTab === "Preparations" && (
            <PreparationSection setActiveNavTab={switchToNavTab} />
          )}
          {activeNavTab === "Response Value Matrix" && (
            <ValueMatrixSection defaultShowVarType={"prep"} />
          )}
        </Row>
        {/* <Row>
          {activeNavTab < navSections.length - 1 &&
            navReady[activeNavTab + 1] && (
              <h5
                style={{ cursor: "pointer" }}
                onClick={() => setActiveNavTab(activeNavTab + 1)}
                tabIndex={0}
                onKeyUp={(e) => {
                  if (e.key === "Enter") setActiveNavTab(activeNavTab + 1);
                }}
              >
                {">>"} Continue to {navSections[activeNavTab + 1].name}
              </h5>
            )}
        </Row> */}
      </Container>
    </div>
  );
}

export default DefineSection;
