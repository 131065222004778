import { useEffect, useRef } from "react";
import { colorRotator, flashWithCssClass } from "../../../../util/helpers";

export default function EraEVLevelEditorCell({
  selectedLevels,
  selectLevel,
  ev,
  evInd,
  evLev,
  col,
  ...props
}) {
  const divRef = useRef(null);

  useEffect(() => {
    props.flashOrNot &&
      divRef.current &&
      flashWithCssClass([divRef.current], 3, 1000, "errorCell");
  }, [props.flashOrNot]);

  return (
    <div
      ref={divRef}
      className={
        "eraEVLevelEditorCell " + (props.disabledCell ? "disabledLevelEditorCell ":"") +
        (selectedLevels[ev.name] && selectedLevels[ev.name][col] === evLev
          ? "eraEVLevelEditorSelected"
          : "")
      }
      style={{
        cursor: "default",
        backgroundColor: colorRotator(evInd),
      }}
      onMouseMove={(e) => selectLevel(e, ev, evLev, col)}
      onMouseDown={(e) => selectLevel(e, ev, evLev, col)}
      onDoubleClick={(e) => selectLevel(e, ev, evLev, col, true)}
    >
      -
    </div>
  );
}
