import { genNewId } from "../../../util/helpers";

export default function valueCriteriaErrorChecking(draft, action, study) {
  // if a value criterion has been removed, update errors
  if (draft.valueCriteria.length < study.valueCriteria.length) {
    let deletedVC = study.valueCriteria.find(
      (ovc) => draft.valueCriteria.map((vc) => vc.id).indexOf(ovc.id) < 0
    );
    let existingError = draft.errors.find(
      (er) => er.affects === "Value Criteria" && er.affectsId === deletedVC.id
    );
    if (existingError)
      draft.errors = draft.errors.filter((e) => e.id !== existingError.id);
  }
  // otherwise, if we didn't add a value criterion to a one-stakeholder study
  else if (
    (draft.valueCriteria !== study.valueCriteria ||
      draft.stakeholders !== study.stakeholders) &&
    !(
      draft.valueCriteria.length > study.valueCriteria.length &&
      draft.stakeholders.length === 1
    )
  ) {
    // get valueCriteria that don't have stakeholders
    let vcWithoutSh = draft.valueCriteria.filter(
      (vc) =>
        draft.stakeholders.filter(
          (sh) => Object.keys(sh.vm).indexOf(vc.id) >= 0
        ).length === 0
    );
    let vcWithSh = draft.valueCriteria.filter(
      (vc) => vcWithoutSh.map((vcws) => vcws.id).indexOf(vc.id) < 0
    );
    // if no error exists already, add one
    vcWithoutSh.forEach((vc) => {
      if (
        !draft.errors.find(
          (er) => er.affects === "Value Criteria" && er.affectsId === vc.id
        )
      )
        draft.errors.push({
          id: genNewId(),
          name: "No Stakeholders",
          affects: "Value Criteria",
          affectsId: vc.id,
          description:
            vc.name +
            " is not considered by any stakeholder. Please assign it to at least one stakeholder or remove from study.",
          ignored: false,
        });
    });
    // check for existing errors that have been resolved
    vcWithSh.forEach((vc) => {
      let existingError = draft.errors.find(
        (er) => er.affects === "Value Criteria" && er.affectsId === vc.id
      );
      if (existingError)
        draft.errors = draft.errors.filter((e) => e.id !== existingError.id);
    });
  }
  // also check to make sure we still have cost and benefit value criteria
  let vcsCosts = draft.valueCriteria
    .filter((vc) =>
      draft.stakeholders.reduce(
        (boolCost, sh) =>
          boolCost ||
          (sh.vm[vc.id] &&
            sh.vm[vc.id].direction === "cost"),
        false
      )
    )
    .map((vc) => vc.id);
  let vcsBenefits = draft.valueCriteria.filter((vc) =>
    draft.stakeholders.reduce(
      (boolBen, sh) =>
        boolBen ||
        (sh.vm[vc.id] &&
          (sh.vm[vc.id].direction ===
            "benefit" ||
            sh.vm[vc.id].direction ===
              "ascending" ||
            sh.vm[vc.id].direction ===
              "descending")),
      false
    )
  );
  if (
    vcsCosts.length < 1 &&
    !draft.errors.find(
      (er) => er.name === "No costs" && er.affects === "Value Criteria"
    )
  )
    draft.errors.push({
      id: genNewId(),
      name: "No costs",
      affects: "Value Criteria",
      affectsId: "NA",
      description: "No cost criteria are defined.",
      ignored: false,
    });
  else if (vcsCosts.length > 0) {
    let existingError = draft.errors.findIndex(
      (er) => er.name === "No costs" && er.affects === "Value Criteria"
    );
    draft.errors = draft.errors.filter(
        (er,i) => i !== existingError);
  }
  if (
    vcsBenefits.length < 1 &&
    !draft.errors.find(
      (er) => er.name === "No benefits" && er.affects === "Value Criteria"
    )
  )
    draft.errors.push({
      id: genNewId(),
      name: "No benefits",
      affects: "Value Criteria",
      affectsId: "NA",
      description: "No benefit criteria are defined.",
      ignored: false,
    });
  else if (vcsBenefits.length > 0) {
    let existingError = draft.errors.findIndex(
      (er) => er.name === "No benefits" && er.affects === "Value Criteria"
    );
    if (existingError >= 0)
      draft.errors = draft.errors.filter(
        (er) => er.id !== draft.errors[existingError].id
      );
  }
}
