import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useStudy, useStudyDispatch } from "../../StudyContext";
import "./settingsDefine.css";
import { forwardRef, useEffect, useRef, useState } from "react";
import { SettingsPaneItem } from "./settingsPaneItem";

function SettingsDefine(props) {
  const study = useStudy();

  const studyDispatch = useStudyDispatch();
  const showUncertaintyRef = useRef(study.settings["showUncertainty"]);

  

  const showEWIinline = useRef(study.settings["showEWIinline"]);
  return (
    <div
      className={"settingsDiv" + (props.settingsPoppedOut ? " poppedOut" : "")}
    >
      <h3
        className="settingsTitle"
        onClick={() => props.setSettingsPoppedOut(!props.settingsPoppedOut)}
      >
        <i className="fas fa-gear" /> Settings
      </h3>
      <div className="settingsBody">
        <Container>
          <Row className="mt-3">
            <SettingsPaneItem
              labelText="Show Errors, Warnings, Info at top of each page"
              settingName="showEWIinline"
              ref={showEWIinline}
            />
          </Row>
          <div style={{ marginTop: "50px" }}>
            <h4>RESET STUDY</h4>
            <div><button
              onClick={() =>
                window.confirm(
                  "All changes will be lost by resetting! Click OK to reset anyway."
                )
                  ? props.resetToST(true)
                  : ""
              }
            >
              Reset to new, blank study
            </button>
            </div><div className="mt-3 mb-2"><button
              onClick={() =>
                window.confirm(
                  "All changes will be lost by resetting! Click OK to reset anyway."
                )
                  ? props.resetToST()
                  : ""
              }
            >
              Load Space Tug example
            </button>
            </div>
            (all current work will be lost)
          </div>
        </Container>
      </div>
    </div>
  );
}
export default SettingsDefine;
