
export function genNewId(objArray) {
  // // generate new id past the maximum present
  // let newId = 0;
  // if (
  //   isFinite(
  //     Math.max.apply(
  //       Math.max,
  //       objArray.map((el) => el.id)
  //     )
  //   )
  // )
  //   newId =
  //     Math.max.apply(
  //       Math.max,
  //       objArray.map((el) => el.id)
  //     ) + 1;
  // else newId = objArray.length;
  return crypto.randomUUID();
}



export function cartesianProduct(arr) {
  return arr.reduce(
    function (a, b) {
      return a
        .map(function (x) {
          return b.map(function (y) {
            return x.concat([y]);
          });
        })
        .reduce(function (a, b) {
          return a.concat(b);
        }, []);
    },
    [[]]
  );
}
// thanks GameAlchemist https://stackoverflow.com/questions/18957972/cartesian-product-of-objects-in-javascript
export function cartesianProductObjects(input, current) {
  if (!input || !input.length) { return []; }

  var head = input[0];
  var tail = input.slice(1);
  var output = [];
  console.log(head,tail);
   for (var key in head) {
     for (var i = 0; i < head[key].length; i++) {
           var newCurrent = copy(current);         
           newCurrent[key] = head[key][i];
           if (tail.length) {
                var productOfTail = 
                        cartesianProductObjects(tail, newCurrent);
                output = output.concat(productOfTail);
           } else output.push(newCurrent);
      }
    }    
   return output;
}

function copy(obj) {
 var res = {};
 for (var p in obj) res[p] = obj[p];
 return res;
}
export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

export function flashWithCssClass(arrOfElements,numFlashes,totalTime,className) {

  for(let i=0;i<numFlashes;i++) {
    setTimeout(() => {
      arrOfElements.forEach(el => {
        el.classList.add(className);
      })
    },i*totalTime/numFlashes);
    setTimeout(() => {
      arrOfElements.forEach(el => {
        el.classList.remove(className);
      })
    },(totalTime/numFlashes)/2 + i*totalTime/numFlashes); 
  }
}
export function pressedCtrlZ(e) {
  if (e.keyCode === 90 && e.ctrlKey) {
    e.preventDefault();
    return true;
  } else return false;

}
export function pressedCtrlY(e) {
  return (e.keyCode===89 && e.ctrlKey);
}


export function colorRotator(i) {
  switch (i) {
    case 0:
      return "steelblue";
    case 1:
      return "goldenrod";
    case 2:
      return "darkgreen";
    case 3:
      return "darkred";
    case 4:
      return "gray";
    default:
      return colorRotator(i % 5);
  }
};

export function multiIndexOf (el,arr) { 
  var idxs = [];
  for (var i = arr.length - 1; i >= 0; i--) {
      if (arr[i] === el) {
          idxs.unshift(i);
      }
  }
  return idxs;
};
