import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Table from "react-bootstrap/esm/Table";

import { cartesianProduct } from "../../../util/helpers";

import ErrorIcon from "../ErrorIcon";
import SectionNotificationsHeader from "../SectionNotificationsHeader";
import { useStudy, useStudyDispatch } from "../../StudyContext";
import { NotesElement } from "../NotesElement";

function AVSection(props) {
  const dvName = useRef();
  const dvNumType = useRef();
  const dvLevels = useRef();
  const study = useStudy();
  const studyDispatch = useStudyDispatch();
  const valueCriteria = study.valueCriteria;
  const alternativeVariables = study.alternativeVariables;
  const contextVariables = study.epochVariables.filter((ev) => ev.context);
  const errors = study.errors;
  const warnings = study.warnings;

  const [validatedDV, setValidatedDV] = useState(false);
  /// NEED TO WRITE REAL REGEX VALIDATION PROBABLY AT SOME POINT
  const validateDV = () => {
    if (
      dvName.current.value.length > 0 &&
      alternativeVariables.filter((dv) => dv.name === dvName.current.value)
        .length < 1 &&
      contextVariables.filter((cv) => cv.name === dvName.current.value).length <
        1 &&
      dvLevels.current.value.length > 0
    )
      setValidatedDV(true);
    else setValidatedDV(false);
  };

  const addDV = () => {
    studyDispatch({section:"Alternative Variables",
      type: "added",
      av: {
        name: dvName.current.value,
        type: dvNumType.current.value,
        levels: dvLevels.current.value.split(",").map((lev) => lev.trim()),
        dcs: [],
      },
    });
    dvName.current.value = "";
    dvLevels.current.value = "";
  };
  const setBaseline = (altVarId, lev) => {
    studyDispatch({section:"Alternative Variables",
      type: "setBaseline",
      avId: altVarId,
      baselineLevel: lev,
    });
  };

  return (
    <Form.Group className="mb-3 MATESection">
      <h4><NotesElement noteOnId={"All Alternative Variables"} noteSection={"Alternative Variables"} name={"All Alternative Variables"}>Alternative Variables</NotesElement></h4>
      <div>
        Factors whose levels you can control directly; the decision itself is
        what level to assign each of these variables. AKA Decision Variables or
        Design Variables.
      </div>
      <SectionNotificationsHeader sectionName="Alternative Variables" />
      <Table striped={true} cellPadding={3}>
        <thead>
          {alternativeVariables && alternativeVariables.length > 0 && (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={valueCriteria.length} align="center">
                Est. Impact on{" "}
                <i
                  className="fas fa-gem"
                  onClick={() => props.setActiveNavTab("Value Criteria")}
                  style={{ cursor: "pointer" }}
                />
                's
              </td>
            </tr>
          )}
          <tr>
            <td></td>
            <td></td>
            <td>Name</td>
            <td>Number Type</td>
            <td>Levels</td>
            {alternativeVariables &&
              alternativeVariables.length > 0 &&
              valueCriteria &&
              valueCriteria.map((dc) => (
                <td key={dc.id} align="center">
                  {dc.name}
                  {warnings && warnings.filter(w => w.affects==="Alternative Variables" && w.affectsId===dc.id && !w.ignored).map(w =>
                        <i title={w.description} className="fa fa-exclamation-circle" />
                    )}
                </td>
              ))}
          </tr>
        </thead>
        <tbody>
          {alternativeVariables &&
            alternativeVariables.map((altVar) => {
              return (
                <tr key={altVar.name} className="dcDetails">
                  <td width={40}>
                    {errors
                      .filter(
                        (er) =>
                          !er.ignored &&
                          er.affects === "Alternative Variables" &&
                          er.affectsId === altVar.id
                      )
                      .map((er) => (
                        <ErrorIcon description={er.description} />
                      ))}
                  </td>
                  <td>
                    <i
                      onClick={() =>
                        studyDispatch({section:"Alternative Variables",
                          type: "deleted",
                          id: altVar.id,
                        })
                      }
                      className="fas fa-minus-circle removeIcon"
                    />
                  </td>
                  <td><NotesElement noteOnId={altVar.id} noteSection={"Alternative Variables"} name={altVar.name}>{altVar.name}</NotesElement></td>
                  <td>{altVar.type==="text"?"categorical":"number"}</td>
                  <td>
                    {altVar.levels.map((lev, i) => (
                      <span
                        key={altVar.name + lev}
                        onClick={() => {
                          altVar.baseline !== lev &&
                            setBaseline(altVar.id, lev);
                        }}
                        className={
                          "dvLevs" +
                          (altVar.baseline === lev ? " baseline" : "")
                        }
                      >
                        {lev}
                        {i === altVar.levels.length - 1 ? "" : ","}{" "}
                      </span>
                    ))}
                  </td>
                  {valueCriteria &&
                    valueCriteria.map((criterion) => (
                      <td
                        key={altVar.name + criterion.id}
                        align={"center"}
                        style={{ borderLeft: "1px solid gray" }}
                      >
                        <div
                          onClick={() =>
                            studyDispatch({section:"Alternative Variables",type:"changeVC",vc:criterion,av:altVar})
                          }
                          className="changePrefs"
                        >
                          <i
                            className={
                              !altVar.dcs.find(
                                (dvdc) => dvdc.id === criterion.id
                              )
                                ? "fas fa-ban noPrefs"
                                : altVar.dcs.find(
                                    (dvdc) => dvdc.id === criterion.id
                                  ).level === "low"
                                ? "fas fa-th low"
                                : altVar.dcs.find(
                                    (dvdc) => dvdc.id === criterion.id
                                  ).level === "med"
                                ? "fas fa-th med"
                                : "fas fa-th high"
                            }
                          />
                        </div>
                      </td>
                    ))}
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td>
              <Form.Control
                type="name"
                ref={dvName}
                placeholder="e.g., Supplier, Payload Size, Fuel Tank Size"
                onChange={validateDV}
              />
            </td>
            <td>
              <select name="NumType" ref={dvNumType}>
                <option value="num">Number</option>
                <option value="text">Categorical</option>
              </select>
            </td>
            <td colSpan={2}>
              <Form.Control
                type="name"
                ref={dvLevels}
                placeholder="Enter comma-separated levels"
                onChange={validateDV}
              />
            </td>
            <td>
              <button onClick={addDV} id="addDV" disabled={!validatedDV}>
                Add
              </button>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td colSpan={2}>
              {alternativeVariables &&
                alternativeVariables.length > 1 &&
                "Full factorial: " +
                  cartesianProduct(
                    alternativeVariables &&
                      alternativeVariables.map((dv) => dv.levels)
                  ).length}
              {alternativeVariables &&
                alternativeVariables.length === 1 &&
                alternativeVariables &&
                alternativeVariables[0].levels.length}
              {alternativeVariables && alternativeVariables.length === 0 && "0"}
              &nbsp;alternatives
            </td>
          </tr>
        </tfoot>
      </Table>
    </Form.Group>
  );
}
export default AVSection;
