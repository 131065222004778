import { useState } from "react";
import { Col, Stack } from "react-bootstrap";


function IntraEpochVariableRules({
  epochVariables,
  intraEpochRules,
  setIntraEpochRules,
  ...props
}) {
  const [firstVarLevel, setFirstVarLevel] = useState(null);
  const relationships = ["cannot transition to"];
  const [newRelationship, setRelationship] = useState(null);
  const [secondVarLevel, setSecondVarLevel] = useState(null);

  const addRule = () => {
    let newRules = [...intraEpochRules];
    newRules.push({
      firstVarLevel: firstVarLevel,
      relationship: newRelationship,
      secondVarLevel: secondVarLevel,
    });
    setIntraEpochRules(newRules);
    setFirstVarLevel(null);
    setSecondVarLevel(null);
    setRelationship(null);
  };
  const removeRule = (i) => {
    let newRules = [...intraEpochRules];
    newRules.splice(i,1);
    setIntraEpochRules(newRules);
  }

  return (
    <>
      <Col xs={12}>
        {intraEpochRules &&
          intraEpochRules.map((r, i) => (
            <Stack
              key={i}
              direction="horizontal"
              gap={3}
              style={{
                padding: "10px",
                margin: "5px",
                borderBottom: "1px solid gray",
              }}
            ><div style={{cursor:"pointer"}} onClick={()=>removeRule(i)}>X</div>
              <div>{i + 1}.</div>
              <div>{r.firstVarLevel}</div>
              <div>{r.relationship}</div>
              <div>{r.secondVarLevel}</div>
            </Stack>
          ))}
      </Col>
      <Col style={{padding:"10px",backgroundColor:"rgb(250,250,250)",borderRadius:"10px",marginLeft:"15px"}}>
        {firstVarLevel ? (
          <span
            className="varCrossVarRules"
            onClick={() => setFirstVarLevel(null)}
          >
            {firstVarLevel}
          </span>
        ) : (
          <select onChange={(ev) => setFirstVarLevel(ev.target.value)}>
            <option value={null}>Please Select</option>
            {epochVariables
              .reduce(
                (listOfLevels, ev) =>
                  [
                    ...listOfLevels,
                    ev.levels.map((evl) => ev.name.trim() + ":" + evl.trim()),
                  ].flat(1),
                []
              )
              .filter((ev) =>
                secondVarLevel
                  ? secondVarLevel.split(":")[0] === ev.split(":")[0]
                  : true
              )
              .map((nal) => (
                <option key={nal} value={nal}>
                  {nal}
                </option>
              ))}
          </select>
        )}
        {newRelationship ? (
          <span
            className="varCrossVarRules"
            onClick={() => setRelationship(null)}
          >
            {newRelationship}
          </span>
        ) : (
          <select
            defaultValue={"Please select"}
            onChange={(ev) => setRelationship(ev.target.value)}
          >
            <option value={null}>Please select</option>
            {relationships.map((r) => (
              <option key={r} value={r}>
                {r}
              </option>
            ))}
          </select>
        )}
        {secondVarLevel ? (
          <span
            className="varCrossVarRules"
            onClick={() => setSecondVarLevel(null)}
          >
            {secondVarLevel}
          </span>
        ) : (
          <select onChange={(ev) => setSecondVarLevel(ev.target.value)}>
            <option value={null}>Please Select</option>
            {epochVariables
              .reduce(
                (listOfLevels, ev) =>
                  [
                    ...listOfLevels,
                    ev.levels.map((evl) => ev.name.trim() + ":" + evl.trim()),
                  ].flat(1),
                []
              )
              .filter((ev) =>
                firstVarLevel
                  ? (firstVarLevel.split(":")[0] === ev.split(":")[0]) && (firstVarLevel.split(":")[1] !== ev.split(":")[1])
                  : true
              )
              .map((nal) => (
                <option key={nal} value={nal}>
                  {nal}
                </option>
              ))}
          </select>
        )}
        <button
          onClick={addRule}
          disabled={!firstVarLevel || !secondVarLevel || !newRelationship}
        >
          Add Rule
        </button>
      </Col><Col md={4}></Col>
    </>
  );
}
export default IntraEpochVariableRules;
