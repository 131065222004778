import { useEffect, useRef, useState } from "react";
import { useStudy, useStudyDispatch } from "../StudyContext";

export function NotesElement({ children, ...props }) {
  const study = useStudy();
  const studyDispatch = useStudyDispatch();
  const [note, setNote] = useState(
    (study.notes &&
      study.notes.find((n) => n.noteOnId === props.noteOnId) &&
      study.notes.find((n) => n.noteOnId === props.noteOnId).note) ||
      null
  );
  const [showNote, setShowNote] = useState(false);
  const [editingNote, setEditingNote] = useState(false);
  const oldNote = useRef(note);

  //color: rgb(218, 235, 30);
  useEffect(() => {
    if (note !== oldNote.current) {
      studyDispatch({
        section: "Study",
        type: "updateNote",
        noteOnId: props.noteOnId,
        noteSection: props.noteSection,
        note: note,
      });
      oldNote.current = note;
    } else if (study.notes &&
      study.notes.find((n) => n.noteOnId === props.noteOnId)) {
      oldNote.current = study.notes.find((n) => n.noteOnId === props.noteOnId).note;
      setNote(study.notes.find((n) => n.noteOnId === props.noteOnId).note);
      }
      else if ((!study.notes || 
        !study.notes.find((n) => n.noteOnId === props.noteOnId)) && note!=="") {
        oldNote.current = null;
        setNote(null);
        }
  }, [note, studyDispatch, props,setNote,study]);
  return (
    <span
      onMouseOver={() => {
        setShowNote(true);
      }}
      onMouseOut={() => {
        setShowNote(false);
      }}
      style={{ position: "relative" }}
    >
      {children}
      <i
        onClick={() => {
          setEditingNote(true);
        }}
        onMouseOver={(e) => {
          !note && e.target.classList.remove("far");
          e.target.classList.add("fas");
        }}
        onMouseOut={(e) => {
          !note && e.target.classList.remove("fas");
          e.target.classList.add("far");
        }}
        className={(note ? "fas" : "far") + " fa-sticky-note"}
        style={{
          color: "rgb(231, 158, 170)",
          cursor: "pointer",
          width: "25px",
          paddingLeft: "10px",
          position: "absolute",
          top: "0px",
          right: "-20px",
          display: showNote || note ? "block" : "none",
        }}
      />
      {editingNote && (
        <div
          style={{
            zIndex: "1000",
            position: "absolute",
            top: "0px",
            left: "0px",
            border: "3px solid rgb(231, 158, 170)",
            backgroundColor: "rgb(231, 158, 170)",
            boxShadow: "5px 5px gray",
            padding: "5px",
            borderRadius: "5px",
          }}
          className="editingNote"
        >
          <h5>Note on {props.name}</h5>
          <textarea
            autoFocus
            defaultValue={note}
            onBlur={(e) => {
              setEditingNote(null);
              setShowNote(false);
              setNote(e.target.value);
            }}
            style={{ resize: "none", width: "400px", height: "100px" }}
          />
        </div>
      )}
    </span>
  );
}
