import { genNewId } from "../../../util/helpers";

export default function epochVariableErrorChecking(draft, action, study) {
  if (
    draft.epochVariables.length < study.epochVariables.length
  ) {
    let deletedEV = study.epochVariables.find(
      (oev) => draft.epochVariables.map((av) => av.id).indexOf(oev.id) < 0
    );
    let existingError = draft.errors.findIndex(
      (er) =>
        er.affects === "Epoch Variables" && er.affectsId === deletedEV.id
    );
    draft.errors=draft.errors.filter((e,i) => i !== existingError);
  } else {
    // get epoch variables that don't have value criteria or stakeholders
    draft.epochVariables.filter((ev) => !ev.context && !ev.preference)
      .forEach((ev) => {
        // if no error exists already, add one
        if (
          !draft.errors.find(
            (er) => er.affects === "Epoch Variables" && er.affectsId === ev.id
          )
        )
        draft.errors.push({
          id: genNewId(),
            name: "No Value Criteria",
            affects: "Epoch Variables",
            affectsId: ev.id,
            description:
              ev.name +
              " must affect either value criteria or stakeholders.",
          ignored: false
        })
    });
    let evWithVC = draft.epochVariables.filter((ev) => ev.context || ev.preference);

    // check for existing errors that have been resolved
    evWithVC.forEach((ev) => {
      let existingError = draft.errors.findIndex(
        (er) => er.affects === "Epoch Variables" && er.affectsId === ev.id
      );
      draft.errors=draft.errors.filter((e,i) => i!==existingError);
    });
  }
}
