function ErrorIcon(props) {
    return <i
    className={props.type==="warning"?"fas fa-exclamation":"fa fa-times"}
    style={{
      color: props.type==="warning"?"black":"white",
      backgroundColor: props.type==="warning"?"goldenrod":"rgb(190,0,0)",
      borderRadius: props.type==="warning"?"0px":"15px",
      padding: "0px 3px 1px 3px",
      paddingTop:props.type==="warning"?"3px":"0px"
    }}
    title={props.description}
  />
}
export default ErrorIcon;