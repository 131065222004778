import { genNewId } from "../../util/helpers";
export default function valueCriteriaLogic(draft, action) {
  switch (action.type) {
    case "added": {
      draft.valueCriteria.push({
        id: genNewId(),
        name: action.vc.name.trim(),
        type: action.vc.type,
        levels: action.vc.levels.map((lev) => lev.trim()),
        units: action.vc.units,
      });
      // create a default value model for a single-stakeholder study
      if (draft.stakeholders.length === 1) {
        let newVal = {
          id: draft.valueCriteria[draft.valueCriteria.length - 1]
            .id,
          direction:
            draft.valueCriteria[draft.valueCriteria.length - 1].name
              .toLowerCase()
              .indexOf("cost") < 0
              ? "ascending"
              : "cost",
          weight: 1,
          levels:
            draft.valueCriteria[draft.valueCriteria.length - 1]
              .type === "num"
              ? []
              : draft.valueCriteria[
                  draft.valueCriteria.length - 1
                ].levels.map((lev) => {
                  return { name: lev, value: 0.5 };
                }),
        };
        draft.stakeholders[0].vm[newVal.id] = newVal;
      }
      break;
    }
    //update rename
    case "updated": {
      draft.valueCriteria[
        draft.valueCriteria.findIndex(
          (vc) => vc.id === action.vc.id
        )
      ] = action.vc;
      break;
    }
    case "deleted": {
      draft.valueCriteria = draft.valueCriteria.filter(
        (s) => s.id !== action.id
      );
      // update any stakeholders that referenced this VC
      draft.stakeholders.forEach((dsh, i) => {
        delete draft.stakeholders[i].vm[action.id]
      });
      // update any alternative variables that referenced this VC
      draft.alternativeVariables.forEach((dav, i) => {
        draft.alternativeVariables[i].dcs =
          draft.alternativeVariables[i].dcs.filter(
            (adc) => adc.id !== action.id
          );
      });
      // update any epoch variables that referenced this VC
      draft.epochVariables.forEach((dev, i) => {
        draft.epochVariables[i].dcs = draft.epochVariables[
          i
        ].dcs.filter((edc) => edc.id !== action.id);
      });
      // update any preparations that referenced this VC
      break;
    }
    case "restored": {
      draft.valueCriteria = action.valueCriteria;
      break;
    }
    default: {
      console.log("unknown value criteria action");
    }
  }
}
