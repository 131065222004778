import React, { useRef } from "react";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Table from "react-bootstrap/esm/Table";
import ValueCriteriaEditModal from "./ValueCriteriaEditModal";

import ErrorIcon from "../ErrorIcon";
import SectionNotificationsHeader from "../SectionNotificationsHeader";
import { useStudy, useStudyDispatch } from "../../StudyContext";
import StakeholderWeightsEditModal from "./StakeholderWeightsEditModal";
import { NotesElement } from "../NotesElement";

function ValueCriteriaSection(props) {
  const [validatedDC, setValidatedDC] = useState(false);
  const [showDCModal, setShowDCModal] = useState(false);
  const [showWeightsModal, setShowWeightsModal] = useState(false);
  const [inputNumType, setInputNumType] = useState("num");
  const [editingName, setEditingName] = useState(null);
  const dcName = useRef();
  const dcNumType = useRef("num");
  const dcLevels = useRef("");
  const dcUnits = useRef("");
  const editingNameRef = useRef(<input />);
  const study = useStudy();
  const valueCriteria = study.valueCriteria;
  const stakeholders = study.stakeholders;
  const studyDispatch = useStudyDispatch();
  const errors = study.errors;

  const updateDCName = () => {
    if (
      valueCriteria.map((dc) => dc.name).indexOf(editingNameRef.current.value) <
      0
    ) {
      let newValueCriterion = {
        ...valueCriteria.find((vc) => vc.id === editingName),
      };
      newValueCriterion.name = editingNameRef.current.value;
      studyDispatch({
        section: "Value Criteria",
        type: "updated",
        vc: newValueCriterion,
      });
    }
    setEditingName(null);
  };

  const validateDC = () => {
    let valid = false;
    if (
      dcName.current.value.length > 0 &&
      valueCriteria.filter((dc) => dc.name === dcName.current.value).length <
        1 &&
      (dcNumType.current.value === "num" ||
        dcLevels.current.value.trim().length > 0)
    )
      valid = true;
    setValidatedDC(valid);
    return valid;
  };

  const addDC = () => {
    studyDispatch({
      section: "Value Criteria",
      type: "added",
      vc: {
        name: dcName.current.value,
        type: inputNumType,
        levels: dcLevels.current.value.split(","),
        units: dcUnits.current.value,
        weight: 1,
      },
    });
    dcName.current.value = "";
    dcLevels.current.value = "";
    dcUnits.current.value = "";
  };

  const changeShVmForDc = (sh, dc, rmv = false, lev, newLevVal = -3) => {
    let shvmdc = Object.keys(sh.vm).map(k => sh.vm[k]).find((vmdc) => vmdc.id === dc.id);
    // create defaults if the stakeholder doesn't yet have it
    let newVal = {
      id: dc.id,
      name: dc.name,
      direction:
        (shvmdc && shvmdc.direction) ||
        (dc.type === "num"
          ? dc.name.toLowerCase().indexOf("cost") < 0
            ? "ascending"
            : "cost"
          : dc.name.toLowerCase().indexOf("cost") < 0
          ? "benefit"
          : "cost"),
      weight: (shvmdc && shvmdc.weight) || 1,
      levels:
        (shvmdc &&
          shvmdc.levels.map((l) => {
            return { ...l };
          })) ||
        [],
    };
    if (dc.type === "num") {
      if (shvmdc) {
        // if it is present and ascending value, change it to descending
        if (shvmdc.direction === "ascending") newVal.direction = "descending";
        else if (shvmdc.direction === "descending") newVal.direction = "cost";
        else rmv = true;
      }
    }
    // otherwise, if it is categorical, show the modal
    else {
      // if it isn't in the stakeholder's value model yet, add it
      if (!shvmdc) {
        newVal.levels = dc.levels.map((lev) => {
          return { name: lev, value: 0 };
        });
      }
      // otherwise if we're just updating the individual level:
      else if (newLevVal > -2) {
        let ind = newVal.levels.findIndex((l) => {
          return l.name === lev;
        });
        newVal.levels[ind].value = newLevVal;
      }
      // or if we're just setting cost/benefit on a categorical
      else if (newLevVal > -3) {
        newVal.direction = lev;
      }
      // and finally, show the modal
      setShowDCModal({ sh: sh, dc: dc });
    }
    //update stakeholder context if appropriate
    (dc.type === "num" || !shvmdc || rmv || newLevVal > -3) &&
      studyDispatch({
        section: "Stakeholders",
        type: "changeVC",
        stakeholder: sh,
        newVC: newVal,
        remove: rmv,
      });
  };

  return (
    <>
      <Form.Group className="mb-3 MATESection">
        <h4>
          <NotesElement
            noteOnId={"All Value Criteria"}
            noteSection={"Value Criteria"}
            name={"All Value Criteria"}
          >
            Value Criteria
          </NotesElement>
        </h4>
        <div>
          The measurable dimensions -- i.e., costs or benefits -- that
          stakeholders use to judge how good each alternative is.
        </div>
        <SectionNotificationsHeader sectionName="Value Criteria" />
        <Table striped={true} cellPadding={3}>
          <thead>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {valueCriteria && valueCriteria.length > 0 && (
                <td colSpan={stakeholders.length} align="center">
                  <i
                    className={
                      stakeholders.length > 1 ? "fas fa-users" : "fas fa-user"
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => props.setActiveNavTab("Stakeholders")}
                  />
                  {stakeholders.length > 1 ? "'s" : ""}
                </td>
              )}
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Name</td>
              <td>Number Type</td>
              <td>Levels (or Units)</td>
              {valueCriteria &&
                valueCriteria.length > 0 &&
                stakeholders.map((sh) => (
                  <td key={sh.id} align="center">
                    <div
                      className="editStakeholderPrefs"
                      onClick={() => setShowWeightsModal(sh.id)}
                    >
                      {sh.name}
                      {Object.keys(sh.vm).length < 1 && (
                        <a
                          href="#"
                          title={
                            "This stakeholder doesn't derive value from any listed criteria. Consider adding (missing?) criteria, or removing the (unneeded?) stakeholder from your study."
                          }
                        >
                          {" "}
                          <i className="fa fa-exclamation-circle" />
                        </a>
                      )}
                    </div>
                  </td>
                ))}
            </tr>
          </thead>
          <tbody>
            {valueCriteria &&
              valueCriteria.map((dc) => {
                return (
                  <tr key={dc.name} className="dcDetails">
                    <td>
                      <i
                        className="fas fa-minus-circle removeIcon"
                        onClick={() =>
                          studyDispatch({
                            section: "Value Criteria",
                            type: "deleted",
                            id: dc.id,
                          })
                        }
                      />
                    </td>

                    <td width={40}>
                      {errors
                        .filter(
                          (er) =>
                            !er.ignored &&
                            er.affects === "Value Criteria" &&
                            er.affectsId === dc.id
                        )
                        .map((er) => (
                          <ErrorIcon key={er.id} description={er.description} />
                        ))}
                    </td>
                    <td>
                      {editingName === dc.id ? (
                        <input
                          ref={editingNameRef}
                          defaultValue={dc.name}
                          autoFocus={true}
                          onBlur={() => {
                            updateDCName();
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") updateDCName();
                          }}
                        ></input>
                      ) : (
                        <NotesElement
                          noteOnId={dc.id}
                          noteSection="Value Criteria"
                          name={dc.name}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => setEditingName(dc.id)}
                          >
                            {dc.name}
                          </span>
                        </NotesElement>
                      )}
                    </td>
                    <td>{dc.type === "text" ? "categorical" : "number"}</td>
                    <td>
                      {dc.type === "text" &&
                        dc.levels.map((lev, i) => (
                          <span key={dc + lev} className="dvLevs">
                            {lev}
                            {i === dc.levels.length - 1 ? "" : ","}{" "}
                          </span>
                        ))}
                      {dc.type === "num" && "(" + dc.units + ")"}
                    </td>
                    {stakeholders.map((sh) => (
                      <td key={sh.id} align="center">
                        <i
                          onClick={() => changeShVmForDc(sh, dc)}
                          className={
                            "changePrefs " +
                            (!Object.keys(sh.vm).map(k => sh.vm[k]).find((vmdc) => vmdc.id === dc.id)
                              ? "fa fa-eye-slash noPrefs"
                              : dc.type === "num"
                              ? Object.keys(sh.vm).map(k => sh.vm[k]).find((vmdc) => vmdc.id === dc.id)
                                  .direction === "cost"
                                ? "fas fa-donate"
                                : Object.keys(sh.vm).map(k => sh.vm[k]).find((vmdc) => vmdc.id === dc.id)
                                    .direction === "ascending"
                                ? "fa fa-sort-amount-down ascending"
                                : "fa fa-sort-amount-down descending"
                              : "fa fa-edit")
                          }
                        />
                      </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td colSpan={2}>
                <Form.Control
                  type="name"
                  ref={dcName}
                  placeholder="e.g., Speed, Cost"
                  onChange={validateDC}
                  onKeyUp={(e) => {
                    if (e.key === "Enter" && validatedDC) addDC();
                  }}
                />
              </td>
              <td>
                <select
                  name="NumType"
                  ref={dcNumType}
                  onChange={() => {
                    setInputNumType(dcNumType.current.value);
                    validateDC();
                  }}
                >
                  <option value="num">Number</option>
                  <option value="text">Categorical</option>
                </select>
              </td>
              <td colSpan={4}>
                <Form.Control
                  type="name"
                  ref={dcUnits}
                  placeholder="Units (e.g., mph, m^2, kg)"
                  style={
                    inputNumType === "num"
                      ? { display: "block" }
                      : { display: "none" }
                  }
                />
                <Form.Control
                  type="name"
                  ref={dcLevels}
                  placeholder="Comma-separated levels"
                  onChange={validateDC}
                  style={
                    inputNumType === "text"
                      ? { display: "block" }
                      : { display: "none" }
                  }
                />
              </td>
              <td>
                <button onClick={addDC} id="addAtt" disabled={!validatedDC}>
                  Add
                </button>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Form.Group>
      {showDCModal && (
        <ValueCriteriaEditModal
          sh={showDCModal.sh}
          dc={showDCModal.dc}
          setShowDCModal={setShowDCModal}
          changeShVmForDc={changeShVmForDc}
        />
      )}
      {showWeightsModal && (
        <StakeholderWeightsEditModal
          shId={showWeightsModal}
          setShowWeightsModal={setShowWeightsModal}
          studyDispatch={studyDispatch}
        />
      )}
    </>
  );
}
export default ValueCriteriaSection;
