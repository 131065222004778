import React, { useState } from "react";

import EpochWeighting from "./EpochWeighting";
import ESFTransitions from "./MarkovTransitions";
import "./Uncertainty.css";
import { useStudy } from "../../StudyContext";

function EpVarTransitionSection() {
  const [epVarTransitionMethod, setEpVarTransitionMethod] = useState(0);
  const study = useStudy();
  const epochVariables = study.epochVariables;

  return (
    <div className="mb-3 EEASection">
      <h4>Epoch Transitions</h4>
      How an epoch variable's levels relate to one another, treating every epoch variable independently.
      <div>
        Desired Method:&nbsp;
        <select
          defaultValue="0"
          onChange={(ev) => setEpVarTransitionMethod(parseInt(ev.target.value))}
        >
          <option value="0">None</option>
          <option value="1">Weighting</option>
          <option value="2">Markov Chains</option>
        </select>
        {epVarTransitionMethod === 1 && (
          <div>
            {" "}
            {/* <i className="fas fa-bars fa-rotate-90" /> */}
            (% of system lifecycle spent at each epoch variable level)
          </div>
        )}
        {epVarTransitionMethod === 2 && (
          <span>
            {" "}
            {/* <i className="fas fa-dice" /> */}
            (e.g., used in ESF)
          </span>
        )}

      </div>
      {epVarTransitionMethod === 1 && (
        <EpochWeighting epochVariables={epochVariables} />
      )}
      {epVarTransitionMethod === 2 && (
        <ESFTransitions epochVariables={epochVariables} />
      )}
    </div>
  );
}
export default EpVarTransitionSection;
