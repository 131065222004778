import { genNewId } from "../../util/helpers";
export default function changeOptionsLogic(draft, action) {
  switch (action.type) {
    case "added": {
      draft.changeOptions.push({
        id: genNewId(),
        name: action.option.name,
        evs: action.option.evs,
        preps: [],
        avs: [],
      });
      break;
    }
    case "changePrep": {
      let coi = draft.changeOptions.findIndex(
        (co) => co.id === action.coid
      );

      let optionPrepInd = draft.changeOptions[coi].preps.findIndex(
        (optionPrep) => optionPrep.id === action.prep.id
      );
      if (optionPrepInd < 0) {
        draft.changeOptions[coi].preps.push({...action.prep,level: "req"});
      } else {
        if (
          draft.changeOptions[coi].preps[optionPrepInd].level ===
          "req"
        )
          draft.changeOptions[coi].preps[optionPrepInd].level =
            "enhance";
        else
          draft.changeOptions[coi].preps.splice(optionPrepInd, 1);
      }
      break;
    }

    case "changeEV": {
      let coi = draft.changeOptions.findIndex(
        (co) => co.id === action.coid
      );
      let evInd = draft.changeOptions[coi].evs.findIndex(
        (dcoeid) => dcoeid === action.evid
      );
      if (evInd < 0) draft.changeOptions[coi].evs.push(action.evid);
      else draft.changeOptions[coi].evs.splice(evInd, 1);
      break;
    }

    case "changeAV": {
      let coi = draft.changeOptions.findIndex(
        (co) => co.id === action.coid
      );
      let coAv = draft.changeOptions[coi].avs;
      let coAvInd = coAv.findIndex(
        (cav) => cav.id === action.av.id
      );

      if (coAvInd < 0) {
        draft.changeOptions[coi].avs.push({
          ...action.av,
          level: action.av.type === "num" ? "up" : "modified",
        });
      } else if (
        draft.changeOptions[coi].avs[coAvInd].level === "down"
      )
        draft.changeOptions[coi].avs.splice(coAvInd, 1);
      else
        draft.changeOptions[coi].avs[coAvInd].level =
          draft.changeOptions[coi].avs[coAvInd].level === "up"
            ? "upanddown"
            : "down";
      break;
    }

    case "deleted": {
      draft.changeOptions = draft.changeOptions.filter(
        (co) => co.id !== action.id
      );
      break;
    }
    case "restored": {
      draft.changeOptions = action.changeOptions;
      break;
    }
    default: {
      throw Error("Unknown action change options: " + action.type);
    }
  }
}
