import { genNewId } from "../../util/helpers";
export default function stakeholderLogic(draft, action) {
  switch (action.type) {
    case "added": {
      draft.stakeholders.push({
        id: genNewId(),
        name: action.name,
        vm: {},
      });
      break;
    }
    //update Value Model of a stakeholder
    case "changeVC": {
      let stk = draft.stakeholders.find(
        (sh) => sh.id === action.stakeholder.id
      );
      // if we're removing a vc from a stakeholder
      if (action.remove)
        delete stk.vm[action.newVC.id];
      // if we've removed a VC from the study
      else if (action.removeAll) {
        draft.stakeholders.forEach(
          (nsh, i) =>
            delete draft.stakeholders[i].vm[action.id]
        );
      }
      // else if we're updating this criterion
      else {
        stk.vm[action.newVC.id] = action.newVC;
      }
      break;
    }
    //update weight of a criterion for a stakeholder
    case "changeVcWeight": {
      let stk = draft.stakeholders.find(
        (sh) => sh.id === action.shId
      );
      stk.vm[action.vcId].weight = action.weight;
      break;
    }
    case "deleted": {
      draft.stakeholders = draft.stakeholders.filter((s) => s.id !== action.id);
      // update any epoch variables that referenced this stakeholder
      draft.epochVariables.forEach((nev, i) => {
        draft.epochVariables[i].stakeholders = draft.epochVariables[
          i
        ].stakeholders.filter((sh) => sh.id !== action.id);
        if (draft.epochVariables[i].stakeholders.length === 0)
          draft.epochVariables[i].preference = false;
      });
      break;
    }
    case "restored": {
      draft.stakeholders = action.stakeholders;
      break;
    }
    default: {
      console.log("stakeholders: unknown action");
      break;
    }
  }
}
