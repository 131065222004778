import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../util/auth";
import { Form } from "react-bootstrap";
import { useStudy, useStudyDispatch } from "./StudyContext";
import { colorRotator } from "../util/helpers";

function TitleBar({ user, ...props }) {
  const auth = useAuth();
  const defineNameRef = useRef(null);
  const [editingName, setEditingName] = useState(false);
  const study = useStudy();
  const studyDispatch = useStudyDispatch();

  const beginEditing = (e) => {
    setEditingName(true);
    setTimeout(() => {
      document.getElementById("formProblemName").focus();
    }, 1);
  };
  const validStartCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const checkForEnter = (e) => {
    return e.key === "Enter";
  };
  const validateDefineName = () => {
    let renamedName = "(Unnamed Project)";
    if (
      defineNameRef.current.value[0] &&
      validStartCharacters.indexOf(
        defineNameRef.current.value[0].toUpperCase()
      ) >= 0
    ) {
      renamedName = defineNameRef.current.value;
    }
    studyDispatch({ section: "Study", type: "rename", newName: renamedName });
    setEditingName(false);
  };
  useEffect(() => {
    if (defineNameRef && defineNameRef.current)
      defineNameRef.current.value = study.name;
  }, [study]);
  useEffect(()=>{
    console.log("user:",user)
  },[user])

  return (
    <div className="titlebar">
      <div className="appTitle">
        <img src="favicon.ico" alt="Embarc" width="32" height="32" />
        Embarc
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        {editingName && (
          <div className="inputName">
            <Form.Control
              type="name"
              id="formProblemName"
              ref={defineNameRef}
              onKeyUp={(e) => {
                if (checkForEnter(e)) validateDefineName();
              }}
              onBlur={validateDefineName}
              defaultValue={study.name || "Name of Study"}
            />
            <div className="nameButton">
              <i className="fa fa-check" />
            </div>
            {/* <Form.Text className="text-muted"></Form.Text> */}
          </div>
        )}
        {!editingName && (
          <div
            tabIndex={3}
            className="studyName"
            onKeyUp={(e) => {
              if (checkForEnter(e)) beginEditing();
            }}
            onClick={beginEditing}
          >
            {study.name} <i className="fa fa-edit editStudyNameIcon" />
          </div>
        )}
      </div>
      <div className="otherUsers">
        {user && study.activeUsers && Object.keys(study.activeUsers)
          .map((k,i) => <i className="fas fa-user" title={(study.activeUsers[k].displayName || "Guest") + " is here"} style={{color:colorRotator(i)}} />)}
      </div>
      <div className="fakeSignin"></div>
      <div className="signinbar">
        {user && user.isAnonymous && (
          <div onClick={props.startSignin}>
            <i className="fas fa-user-circle" /> Guest
          </div>
        )}
        {user && !user.isAnonymous && (
          <div onClick={auth.signout}>
            <i className="fas fa-user-circle" /> {user.displayName}
          </div>
        )}
        <div id="offlineIndicator">Offline Mode</div>
      </div>
    </div>
  );
}
export default TitleBar;
