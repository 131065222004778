import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Table from "react-bootstrap/esm/Table";
import { useStudy, useStudyDispatch } from "../../StudyContext";

function PreparationSection(props) {
  const study = useStudy();
  const studyDispatch = useStudyDispatch();
  const preparations = study.preparations;
  const valueCriteria = study.valueCriteria;
  const prepName = useRef();
  const prepDesc = useRef();
  const [validatedPrep, setValidatedPrep] = useState(false);
  const validatePrep = () => {
    if (
      prepName.current.value.length > 0 &&
      preparations.filter((prep) => prep.name === prepName.current.value).length < 1
    )
      setValidatedPrep(true);
    else setValidatedPrep(false);
  };
  const addPrep = () => {
    studyDispatch({section:"Preparations",type:"added",prep:{name: prepName.current.value,
      description: prepDesc.current.value}}
    );
    prepName.current.value = "";
    prepDesc.current.value = "";
  };

  return (
    <Form.Group className="mb-3 ChangeSection">
      <h4>Preparations</h4>
      <div>
        Resource allocations (i.e., decisions) in the present that enable execution of change options in the future.
      </div>
      <Table striped={true} cellPadding={3}>
        <thead className="tableHeader">
          {preparations && preparations.length > 0 && (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={valueCriteria.length} align="center">
                Est. Impact on{" "}
                <i
                  className="fas fa-gem"
                  onClick={() => props.setActiveNavTab("Value Criteria")}
                  style={{ cursor: "pointer" }}
                />
                's
              </td>
            </tr>
          )}
          <tr>
            <td></td>
            <td></td>
            <td>Name</td>
            <td>Description</td>
            {preparations && preparations.length > 0 &&
              valueCriteria.map((dc) => (
                <td key={dc.id} align="center">
                  {dc.name}
                </td>
              ))}
          </tr>
        </thead>
        <tbody>
          {preparations && preparations.map((prep) => {
            return (
              <tr key={prep.name} className="dcDetails">
                <td width={40}>
                  {prep.dcs.length < 1 && (
                    <a
                      href="#"
                      title={
                        "This preparation doesn't impact any criteria -- i.e., it is latent in the alternatives. Is that right?"
                      }
                    >
                      <i className="fa fa-exclamation-circle" />
                    </a>
                  )}
                </td>
                <td>
                  <i
                    className="fas fa-minus-circle removeIcon"
                    onClick={() => studyDispatch({section:"Preparations",type:"deleted",id:prep.id})}
                  />
                </td>
                <td>{prep.name}</td>
                <td>{prep.description}</td>
                {valueCriteria && valueCriteria.map((vc) => (
                  <td
                    key={prep.name + vc.id}
                    align="center"
                    style={{ borderLeft: "1px solid gray" }}
                  >
                    <div
                      onClick={() => studyDispatch({section:"Preparations",type:"changeVC",prepId:prep.id,vc:vc})}
                      className="changePrefs"
                    >
                      <i
                        className={
                          !prep.dcs.find((prepdc) => prepdc.id === vc.id)
                            ? "fas fa-ban noPrefs"
                            : prep.dcs.find((prepdc) => prepdc.id === vc.id).level ===
                              "low"
                            ? "fas fa-th low"
                            : prep.dcs.find((prepdc) => prepdc.id === vc.id).level ===
                              "med"
                            ? "fas fa-th med"
                            : "fas fa-th high"
                        }
                      />
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td>
              <Form.Control
                type="name"
                ref={prepName}
                placeholder="e.g., Modular Fuel Tank"
                onChange={validatePrep}
              />
            </td>
            <td align="center">
            <Form.Control
              type="desc"
              ref={prepDesc}
              placeholder="Description"
            />
          </td>
            <td>
              <button onClick={addPrep} id="addPrep" disabled={!validatedPrep}>
                Add
              </button>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Form.Group>
  );
}
export default PreparationSection;
