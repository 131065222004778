export default function AVandEVWarningsCheck(draft, action, study) {
  // if we've changed an alternative variable's impact on value criteria, check for warnings

  // run through all alternative and epoch variables
  let listOfAllIdsWithDrivers = draft.alternativeVariables
    .concat(draft.epochVariables)
    .reduce((partialList, altOrEpVar) => {
      return partialList.concat(altOrEpVar.dcs.map((vc) => vc.id));
    }, []);
  // and remove any warnings that no longer apply
  listOfAllIdsWithDrivers.forEach((vcId) => {
    draft.warnings = draft.warnings.filter(
      (w) => !(
        (w.affects === "Alternative Variables" ||
          w.affects === "Epoch Variables") &&
        w.affectsId === vcId))
  });
  //now limit to only alternative variables -- if they drive something, it's ok if no epoch variables do
  let listOfIdsWithDrivers = draft.alternativeVariables
    .concat(draft.epochVariables)
    .reduce((partialList, altOrEpVar) => {
      return partialList.concat(altOrEpVar.dcs.map((vc) => vc.id));
    }, []);
  let vcsWithoutAvDrivers = draft.valueCriteria.filter(
    (vc) => listOfIdsWithDrivers.indexOf(vc.id) < 0
  );

  let vcsWithoutEpDrivers = [...vcsWithoutAvDrivers];
  draft.epochVariables.forEach((epvar) => {
    epvar.dcs.forEach((vc) => {
      vcsWithoutEpDrivers = vcsWithoutEpDrivers.filter(
        (vcwd) => vcwd.id !== vc.id
      );
    });
  });
  // now add warnings for whatever is left
  vcsWithoutAvDrivers.forEach((vcwd) => {
    let existingWarning = draft.warnings.find(
      (w) => w.affects === "Alternative Variables" && w.affectsId === vcwd.id
    );
    if (!existingWarning)
      draft.warnings.push({
        id: crypto.randomUUID(),
        name: "Add Alternative Variable",
        affects: "Alternative Variables",
        affectsId: vcwd.id,
        description:
          "Add an Alternative Variable that drives " + vcwd.name + ".",
        ignored: false,
      });
  });
  vcsWithoutEpDrivers.forEach((vcwd) => {
    let existingWarning = draft.warnings.find(
      (w) => w.affects === "Epoch Variables" && w.affectsId === vcwd.id
    );
    if (!existingWarning)
      draft.warnings.push({
        id: crypto.randomUUID(),
        name: "Add Epoch Variable",
        affects: "Epoch Variables",
        affectsId: vcwd.id,
        description: "Add an Epoch Variable that drives " + vcwd.name + ".",
        ignored: false,
      });
  });

  // if we've removed any value criteria, remove the errors associated with them
  if (draft.valueCriteria.length < study.valueCriteria.length) {
    let deletedVC = study.valueCriteria.find(
      (ovc) => draft.valueCriteria.map((vc) => vc.id).indexOf(ovc.id) < 0
    );
    let existingWarningIds = draft.warnings
      .filter(
        (warning) =>
          (warning.affects === "Alternative Variables" ||
            warning.affects === "Epoch Variables") &&
          warning.affectsId === deletedVC.id
      )
      .map((ew) => ew.id);
    draft.warnings = draft.warnings.filter(
      (warning) => existingWarningIds.indexOf(warning.id) < 0
    );
  }
}
