import { Grid } from "blockly";
import { useEffect, useRef } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import CustomNavIcon from "../CustomNavIcon";
import { cartesianProduct } from "../../../util/helpers";

function ESFTransitions({ epochVariables, ...props }) {

  const epochVariablesRefs = useRef({});
  epochVariables.forEach((ev) => {
    ev.levels.forEach(l => {
      epochVariablesRefs.current[ev.name+l] = [];
    })
  });
  useEffect(() => {
    epochVariablesRefs.current = { ...epochVariablesRefs.current };
  }, [epochVariables]);

  const changeWeight = (event, l, evl) => {
    let matchingEv = epochVariables.find(
      (ev) => ev.name === event.target.getAttribute("data-evname")
    );
    // if there are only two levels, auto-adjust the other level to make 100%
    if (matchingEv.levels.length === 2) {
      let otherBox = epochVariablesRefs.current[event.target.getAttribute("data-evname")+l].find(
        (evr) => evr.getAttribute("data-evevl") !== evl
      );
      otherBox.value = Math.round(100 - event.target.value * 100) / 100;
    } else {
      // check to make sure we're not going over 1
      let otherBoxesSum = epochVariablesRefs.current[event.target.getAttribute("data-evname")+l]
        .filter((evr) => evr.getAttribute("data-evevl") !== evl)
        .map((evr) => evr.value)
        .reduce((partialSum, a) => partialSum + parseInt(a * 100), 0);
      if (parseInt(event.target.value * 100) + otherBoxesSum > 100) {
        event.target.value = (100 - otherBoxesSum) / 100;
        epochVariablesRefs.current[event.target.getAttribute("data-evname")+l]
        .forEach(evr => {
          evr.classList.add("redColor");
          setTimeout(() => evr.classList.add("transitionAll"), 100);
          setTimeout(() => {
          evr.classList.remove("redColor");
        }, 300);
          setTimeout(() => {
          evr.classList.remove("transitionAll");
        }, 1300);})
      }
    }
  };

  return (
    <Container>
      <Row  className="justify-content-start">
      {epochVariables.map((ev) => (
        <Col md="auto">
        <Container>
          <Row className="mt-5 markovVariableHeader">
            <Col xs="auto"><small><small><CustomNavIcon type={
                            ev.context && ev.preference
                              ? "both"
                              : ev.context
                              ? "fas fa-calculator"
                              : "fas fa-eye"
                          } /></small></small></Col><Col md="auto">{ev.name} ({ev.timescale})</Col>
          </Row>
          <Row>
            <Col className="markovTable">
              <table cellPadding={5} >
                <tr>
                  <td></td>
                  {ev.levels.map((l) => (
                    <td>To {l}</td>
                  ))}
                </tr>
                {ev.levels.map((l) => (
                  <tr style={{borderBottom:"1px dotted gray"}}>
                    <td>From {l}</td>
                    {ev.levels.map((evl,j) => (
                      <td key={ev.name+l+evl}>
                        <input
                          type="number"
                          style={{ width: "50px" }}
                          ref={el => (epochVariablesRefs.current[ev.name+l][j] = el)}
                          min={0}
                          max={1}
                          step={0.05}
                          maxLength={3}
                          data-evname={ev.name}
                          data-evl={l}
                          data-evevl={evl}
                          defaultValue={Math.round(100/ev.levels.length)/100}
                          onChange={(event) => changeWeight(event, l, evl)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </table></Col>
          </Row></Container></Col>
      ))}</Row>
      <Row className="mt-5">
        <h4>Effective Weights of Individual Epochs:</h4>
      </Row>
      <Row className="variableHeader">
        <Col>Epoch #</Col>
        {epochVariables.map((ev) => (
          <Col key={ev.id}>
            <div>{ev.name} ({ev.timescale})</div>
          </Col>
        ))}
        <Col xs={1}>Weight</Col>
      </Row>
      {cartesianProduct(epochVariables.map((ev) => ev.levels)).map(
        (config, i) => (
          <Row className="epochWeightRow" key={i}>
            <Col>Epoch {i}</Col>
            {config.map((evLev) => (
              <Col key={evLev + i}>{evLev}</Col>
            ))}
            <Col
              xs={1}
            >
              
            </Col>
          </Row>
        )
      )}
    </Container>
  );
}
export default ESFTransitions;
