import { useCallback, useEffect } from "react";
import ErrorIcon from "../ErrorIcon";
import { useStudy } from "../../StudyContext";
import { colorRotator } from "../../../util/helpers";

function DefineNavBar({ user, navSections, setNavReady, ...props }) {
  const study = useStudy();
  const stakeholders = study.stakeholders;
  const valueCriteria = study.valueCriteria;
  const alternativeVariables = study.alternativeVariables;
  const epochVariables = study.epochVariables;
  const preparations = study.preparations;
  const errors = study.errors;
  const warnings = study.warnings;
  const updateNavEnabled = useCallback(() => {
    let newNavReady = {};
    newNavReady["Stakeholders"] = true;
    if (stakeholders && stakeholders.length > 0) {
      newNavReady["Value Criteria"] = true;
      newNavReady["Alternative Variables"] = true;
    }
    if (valueCriteria && valueCriteria.length > 0) {
      newNavReady["Epoch Variables"] = true;
      if (alternativeVariables.length > 0)
        newNavReady["MATE Value Matrix"] = true;
    }
    if (epochVariables.length > 0) {
      newNavReady["Uncertainty Value Matrix"] =
        epochVariables.filter((ev) => ev.context).length > 0;
      newNavReady["Epoch Transitions"] = true;
      newNavReady["Era Creation"] = true;
      newNavReady["Change Options"] =
        epochVariables.filter((ev) => ev.context).length > 0 ||
        epochVariables.filter((ev) => ev.preference).length > 0;
      newNavReady["Preparations"] =
        epochVariables.filter((ev) => ev.context).length > 0 ||
        epochVariables.filter((ev) => ev.preference).length > 0;
    }
    if (preparations.length > 0) {
      newNavReady["Response Value Matrix"] = true;
    }
    setNavReady(newNavReady);
  }, [
    setNavReady,
    stakeholders,
    valueCriteria,
    alternativeVariables,
    epochVariables,
    preparations,
  ]);

  useEffect(() => {
    updateNavEnabled();
  }, [updateNavEnabled]);

  return (
    <div className="navTabBar">
      <div
        className={
          "navMATE" +
          (navSections.find((ns) => ns.name === props.activeNavTab).section ===
          "MATE"
            ? " activeNavSection"
            : "")
        }
      >
        {navSections
          .filter((section) => section.section === "MATE")
          .map((navSection, i) => (
            <div
              key={i}
              tabIndex={4 + i}
              className={
                "navTab " +
                (props.activeNavTab === navSection.name ? "activeNavTab" : "") +
                (props.navReady[navSection.name] ? "" : "disabledNavTab")
              }
              onClick={() =>
                props.navReady[navSection.name] &&
                props.setActiveNavTab(navSection.name)
              }
            >
              <i
                className={"navTabIcon " + navSections[i].class}
                title={navSection.name}
              />
              {user &&
                study.activeUsers &&
                Object.keys(study.activeUsers)
                  .filter(
                    (k) =>
                      k !== user.uid && study.settings[k] && 
                      study.settings[k].activeNavTab === navSection.name
                  )
                  .map((k, i) => (
                    <i
                      className="navTabUser fas fa-user-circle"
                      style={{ backgroundColor: colorRotator(i) }}
                      title={study.activeUsers[k].displayName || "Guest"}
                    />
                  ))}
              {props.navReady[navSection.name] &&
                warnings.find(
                  (w) => !w.ignored && w.affects === navSection.name
                ) && (
                  <div className="navTabWarning">
                    <ErrorIcon
                      type="warning"
                      description={warnings
                        .filter(
                          (w) => !w.ignored && w.affects === navSection.name
                        )
                        .map((w) => "* " + w.description)
                        .join("\n")}
                    />
                  </div>
                )}
              {props.navReady[navSection.name] &&
                errors.find(
                  (er) => !er.ignored && er.affects === navSection.name
                ) && (
                  <div className="navTabError">
                    <ErrorIcon
                      description={errors
                        .filter(
                          (er) => !er.ignored && er.affects === navSection.name
                        )
                        .map((er) => "* " + er.description)
                        .join("\n")}
                    />
                  </div>
                )}
            </div>
          ))}
      </div>
      <div
        className={
          "navEEA" +
          (navSections.find((ns) => ns.name === props.activeNavTab).section ===
          "Uncertainty"
            ? " activeNavSection"
            : "")
        }
      >
        {study.settings["showUncertainty"] && navSections
          .filter((section) => section.section === "Uncertainty")
          .map((navSection, i) => {
            let tabInd =
              i +
              navSections.filter((section) => section.section === "MATE")
                .length;
            return (
              <div
                key={i}
                tabIndex={4 + tabInd}
                className={
                  "navTab " +
                  (props.activeNavTab === navSection.name
                    ? "activeNavTab"
                    : "") +
                  (props.navReady[navSection.name] ? "" : "disabledNavTab")
                }
                onClick={() =>
                  props.navReady[navSection.name] &&
                  props.setActiveNavTab(navSection.name)
                }
              >
                <i
                  className={"navTabIcon " + navSection.class}
                  title={navSection.name}
                />
                {user &&
                  study.activeUsers &&
                  Object.keys(study.activeUsers)
                    .filter(
                      (k) =>
                        k !== user.uid && study.settings[k] &&
                        study.settings[k].activeNavTab === navSection.name
                    )
                    .map((k, i) => (
                      <i
                        className="navTabUser fas fa-user-circle"
                        style={{ backgroundColor: colorRotator(i) }}
                        title={study.activeUsers[k].displayName || "Guest"}
                      />
                    ))}
                {warnings.find(
                  (w) => !w.ignored && w.affects === navSection.name
                ) && (
                  <div className="navTabWarning">
                    <ErrorIcon
                      type="warning"
                      description={warnings
                        .filter(
                          (w) => !w.ignored && w.affects === navSection.name
                        )
                        .map((w) => "* " + w.description)
                        .join("\n")}
                    />
                  </div>
                )}
                {errors.find(
                  (er) => !er.ignored && er.affects === navSection.name
                ) && (
                  <div className="navTabError">
                    <ErrorIcon
                      description={errors
                        .filter(
                          (er) => !er.ignored && er.affects === navSection.name
                        )
                        .map((er) => "* " + er.description)
                        .join("\n")}
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <div
        className={
          "navChange" +
          (navSections.find((ns) => ns.name === props.activeNavTab).section ===
          "Response"
            ? " activeNavSection"
            : "")
        }
      >
        {study.settings["showUncertainty"] && study.settings["showResponse"] && navSections
          .filter((section) => section.section === "Response")
          .map((navSection, i) => {
            let tabInd =
              i +
              navSections.length -
              navSections.filter((section) => section.section === "Response")
                .length;
            return (
              <div
                key={tabInd}
                tabIndex={4 + tabInd}
                className={
                  "navTab " +
                  (props.activeNavTab === navSection.name
                    ? "activeNavTab"
                    : "") +
                  (props.navReady[navSection.name] ? "" : "disabledNavTab")
                }
                onClick={() =>
                  props.navReady[navSection.name] &&
                  props.setActiveNavTab(navSection.name)
                }
              >
                <i
                  className={"navTabIcon " + navSection.class}
                  title={navSection.name}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
export default DefineNavBar;
