export default function stakeholderWarningsCheck(draft,action, study) {
// check for stakeholders without value criteria, or those that don't need warnings anymore
draft.stakeholders.forEach((sh) => {
    if (Object.keys(sh.vm).length < 1) {
      let existingWarning = draft.warnings.find(
        (w) => w.affects === "Value Criteria" && w.affectsId === sh.id
      );
      // if this stakeholder has no value criteria, create a warning
      if (!existingWarning)
        draft.warnings.push({
          id: crypto.randomUUID(),
            name: "Add Value Criteria",
            affects: "Value Criteria",
            affectsId: sh.id,
            description:
              "Add Value Criteria that " + sh.name + " cares about.",
            ignored: false
          });
    } else {
        // else if the stakeholder now has value criteria and there was previously a warning about none, remove the warning
      let existingWarningIds = draft.warnings.filter(
        (w) => w.affects === "Value Criteria" && w.affectsId === sh.id
      ).map(ew=>ew.id);
      if (existingWarningIds.length >= 0)
        draft.warnings = draft.warnings.filter(w => existingWarningIds.indexOf(w.id)<0);
    }
  });
  // and check for previous warnings about now-deleted stakeholders
  if (study.stakeholders.length > draft.stakeholders.length) {
    let deletedStakeholder = study.stakeholders.find(
      (osh) => draft.stakeholders.map((sh) => sh.id).indexOf(osh.id) < 0
    );
    let existingWarningIds = draft.warnings.filter(
      (w) =>
        w.affects === "Value Criteria" &&
        w.affectsId === deletedStakeholder.id
    ).map(ew=>ew.id);
    draft.warnings = draft.warnings.filter(w => existingWarningIds.indexOf(w.id)<0);  
  }
}