import * as d3 from "d3";
import React, { useRef, useEffect, useCallback, useMemo, useState } from "react";
import { useStudy, useStudyDispatch } from "./StudyContext";
import { Table } from "react-bootstrap";
import ValueCriteriaEditModal from "./Define/MATE/ValueCriteriaEditModal";
import StakeholderWeightsEditModal from "./Define/MATE/StakeholderWeightsEditModal";
import ErrorIcon from "./Define/ErrorIcon";

function ExploreValueEditor({ stakeholder }) {
  const study=useStudy();
  const valueCriteria = study.valueCriteria;
  const stakeholders = study.stakeholders;
  const sh = stakeholders.find(s => s.id===stakeholder);
  const errors = study.errors;
  const studyDispatch = useStudyDispatch();
  
  const [showDCModal, setShowDCModal] = useState(false);
  const [showWeightsModal, setShowWeightsModal] = useState(false);

  const changeShVmForDc = (sh, dc, rmv = false, lev, newLevVal = -3) => {
    let shvmdc = Object.keys(sh.vm).map(k => sh.vm[k]).find((vmdc) => vmdc.id === dc.id);
    // create defaults if the stakeholder doesn't yet have it
    let newVal = {
      id: dc.id,
      name: dc.name,
      direction:
        (shvmdc && shvmdc.direction) ||
        (dc.type === "num"
          ? dc.name.toLowerCase().indexOf("cost") < 0
            ? "ascending"
            : "cost"
          : dc.name.toLowerCase().indexOf("cost") < 0
          ? "benefit"
          : "cost"),
      weight: (shvmdc && shvmdc.weight) || 1,
      levels:
        (shvmdc &&
          shvmdc.levels.map((l) => {
            return { ...l };
          })) ||
        [],
    };
    if (dc.type === "num") {
      if (shvmdc) {
        // if it is present and ascending value, change it to descending
        if (shvmdc.direction === "ascending") newVal.direction = "descending";
        else if (shvmdc.direction === "descending") newVal.direction = "cost";
        else rmv = true;
      }
    }
    // otherwise, if it is categorical, show the modal
    else {
      // if it isn't in the stakeholder's value model yet, add it
      if (!shvmdc) {
        newVal.levels = dc.levels.map((lev) => {
          return { name: lev, value: 0 };
        });
      }
      // otherwise if we're just updating the individual level:
      else if (newLevVal > -2) {
        let ind = newVal.levels.findIndex((l) => {
          return l.name === lev;
        });
        newVal.levels[ind].value = newLevVal;
      }
      // or if we're just setting cost/benefit on a categorical
      else if (newLevVal > -3) {
        newVal.direction = lev;
      }
      // and finally, show the modal
      setShowDCModal({ sh: sh, dc: dc });
    }
    //update stakeholder context if appropriate
    (dc.type === "num" || !shvmdc || rmv || newLevVal > -3) &&
      studyDispatch({
        section: "Stakeholders",
        type: "changeVC",
        stakeholder: sh,
        newVC: newVal,
        remove: rmv,
      });
  };

  return <div style={{marginLeft:"50px"}}>
    <h4>{sh.name}'s Value Function</h4>
    <Table striped={true} cellPadding={3}>
          <thead>
            <tr>
              <td></td>
              {valueCriteria && valueCriteria.length > 0 && (
                <td align="center" colSpan={2}>
                  <i
                    className={
                      "fas fa-user"
                    }
                  />
                </td>
              )}
            </tr>
            <tr>
              <td>Name</td>
              <td key={stakeholder} align="center" colSpan={2}>
                    <div
                      className="editStakeholderPrefs"
                      onClick={() => setShowWeightsModal(sh.id)}
                    >
                      {sh.name}
                      {Object.keys(sh.vm).length < 1 && (
                        <a
                          href="#"
                          title={
                            "This stakeholder doesn't derive value from any listed criteria. Consider adding (missing?) criteria, or removing the (unneeded?) stakeholder from your study."
                          }
                        >
                          {" "}
                          <i className="fa fa-exclamation-circle" />
                        </a>
                      )}
                    </div>
                  </td>
            </tr>
          </thead>
          <tbody>
            {valueCriteria &&
              valueCriteria.map((dc) => {
                return (
                  <tr key={dc.name} className="dcDetails">
                    <td>
                          <span
                            style={{ cursor: "pointer" }}
                          >
                            {dc.name}
                          </span>
                    </td>
                    <td>
                      {dc.type === "text" &&
                        dc.levels.map((lev, i) => (
                          <span key={dc + lev} className="dvLevs">
                            {lev}
                            {i === dc.levels.length - 1 ? "" : ","}{" "}
                          </span>
                        ))}
                      {dc.type === "num" && "(" + dc.units + ")"}
                    </td>
                    <td key={sh.id} align="center">
                        <i
                          onClick={() => changeShVmForDc(sh, dc)}
                          className={
                            "changePrefs " +
                            (!Object.keys(sh.vm).map(k => sh.vm[k]).find((vmdc) => vmdc.id === dc.id)
                              ? "fa fa-eye-slash noPrefs"
                              : dc.type === "num"
                              ? Object.keys(sh.vm).map(k => sh.vm[k]).find((vmdc) => vmdc.id === dc.id)
                                  .direction === "cost"
                                ? "fas fa-donate"
                                : Object.keys(sh.vm).map(k => sh.vm[k]).find((vmdc) => vmdc.id === dc.id)
                                    .direction === "ascending"
                                ? "fa fa-sort-amount-down ascending"
                                : "fa fa-sort-amount-down descending"
                              : "fa fa-edit")
                          }
                        />
                      </td>
                  </tr>
                );
              })}
          </tbody>
          
        </Table>
        {showDCModal && (
        <ValueCriteriaEditModal
          sh={showDCModal.sh}
          dc={showDCModal.dc}
          setShowDCModal={setShowDCModal}
          changeShVmForDc={changeShVmForDc}
        />
      )}
      {showWeightsModal && (
        <StakeholderWeightsEditModal
          shId={showWeightsModal}
          setShowWeightsModal={setShowWeightsModal}
          studyDispatch={studyDispatch}
        />
      )}
  </div>
}

export default ExploreValueEditor;
