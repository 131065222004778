import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { cartesianProduct } from "../util/helpers";
import { useStudy } from "./StudyContext";

function PreviewSection(props) {
  const study = useStudy();
  const valueCriteria = study.valueCriteria;
  const alternativeVariables = study.alternativeVariables;
  const contextVariables = study.epochVariables&&study.epochVariables.filter((ev) => ev.context);
  const preparations = study.preparations;

  return (
    <>
      <div style={{ minHeight: "600px" }}>
        <Container>
          <Row>
            <h2
              id="previewTopLabel"
              onClick={() => {
                props.setPreviewPoppedOut(false);
              }}
            >
              Export Evaluations
            </h2>
          </Row>
          <Row className="mt-3">
            <h4>Summary</h4>
          </Row>
          <Row>
            <Col>Full Factorial of AV Levels:</Col>{" "}
            <Col>
              {
                cartesianProduct(alternativeVariables.map((dv) => dv.levels))
                  .length
              }{" "}
              Alternative
              {cartesianProduct(alternativeVariables.map((dv) => dv.levels))
                .length > 1
                ? "s"
                : ""}
            </Col>
          </Row>
          <Row>
            <Col>Full Factorial of CV Levels:</Col>{" "}
            <Col>
              {cartesianProduct(contextVariables.map((cv) => cv.levels)).length}{" "}
              Context
              {cartesianProduct(contextVariables.map((cv) => cv.levels))
                .length > 1
                ? "s"
                : ""}
            </Col>
          </Row>
          <Row>
            <Col>Full Factorial of Preps (on/off):</Col>{" "}
            <Col>
              {
                cartesianProduct(preparations.map((prep) => prep.levels || []))
                  .length
              }{" "}
              Preparation Levels
            </Col>
          </Row>
          <Row>
            <Col>Full Factorial of AVs and CVs and Preps:</Col>{" "}
            <Col>
              {cartesianProduct(alternativeVariables.map((dv) => dv.levels))
                .length *
                cartesianProduct(contextVariables.map((cv) => cv.levels))
                  .length *
                cartesianProduct(preparations.map((prep) => prep.levels || []))
                  .length}{" "}
              Evaluations
            </Col>
          </Row>

          {/* <Row>
            <Col>Estimated Full Factorial Runtime:</Col>
            <Col>
              {Math.round(
                cartesianProduct(alternativeVariables.map((dv) => dv.levels))
                  .length *cartesianProduct(contextVariables.map((cv) => cv.levels)).length*
                  props.lastRunPerf *
                  100
              ) / 100}{" "}
              ms (based on last run)
            </Col>
          </Row> */}
          <Row className="mt-3">
            <center>
              <Button
                variant="primary"
                size="md"
                style={{ width: "300px" }}
                onClick={props.exportCsv}
              >
                Create .csv
              </Button>
            </center>
          </Row>
          <Row className="mt-5">
            <h4>Export Preview:</h4>
            {/* <h6>
              (DEPRECATED LINK TO POP OUT DEFINE
              )
            </h6> */}
          </Row>
          <Row>
            <Table striped={true} bordered={true}>
              <thead>
                <tr>
                  {alternativeVariables.map((dv) => {
                    return <th key={dv.name}>(AV) {dv.name}</th>;
                  })}
                  {contextVariables.map((cv) => {
                    return <th key={cv.name}>(CV) {cv.name}</th>;
                  })}
                  {preparations.map((prep) => {
                    return <th key={prep.name}>(Prep) {prep.name}</th>;
                  })}
                  {valueCriteria.map((att) => {
                    return <th key={att.name}>(VC) {att.name}</th>;
                  })}
                  {props.intEvals.map((intVar) => {
                    return <th key={intVar.name}>(VAR) {intVar.name}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {alternativeVariables.length === 0 && (
                  <tr>
                    {valueCriteria.map((att, i) => {
                      return (
                        <td key={att.name} className={"failed"}>
                          {window["dc"][att.name.trim()]}
                        </td>
                      );
                    })}
                  </tr>
                )}
                {study.evaluations && study.evaluations.map((evaluation, i) => {
                  return (
                    <tr key={Object.keys(evaluation).concat(Object.values(evaluation)).join(",")}>
                      {Object.keys(evaluation).map((evalKey, i) => {
                        const valCrit = study.valueCriteria.find(
                          (vc) => vc.name === evalKey
                        );
                        return (
                          <td
                            key={evalKey}
                            className={
                              !valCrit
                                ? ""
                                : evaluation[evalKey] === "NOT EVALUATED"
                                ? "failed"
                                : "passed"
                            }
                          >
                            
                            {valCrit &&
                            evaluation[evalKey] !== "NOT EVALUATED" &&
                            valueCriteria[i] &&
                            valueCriteria[i].type === "num"
                              ? Math.round(evaluation[evalKey] * 100) / 100
                              : evaluation[evalKey]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default PreviewSection;
