import * as d3module from "d3";
import d3tip from "d3-tip";

import React, { useRef, useEffect, useCallback, useMemo } from "react";
const d3 = {
  ...d3module,
  tip: d3tip,
};
function ExplorePlot({
  width,
  height,
  inputData,
  selectedData,
  hoverData,
  colorByDV,
  alternativeVariables,
  absolutePlotRange,
  stakeholder,
}) {
  const ref = useRef();
  const xAxisRef = useRef();
  const yAxisRef = useRef();
  const data = useMemo(
    () => (inputData && Object.keys(inputData).map((k) => inputData[k])) || [],
    [inputData]
  );
  const createTip = useCallback((svg) => {
    const tip = d3
      .tip()
      .attr("class", "d3-tip")
      .offset([-10, 0])
      .html(function (d) {
        return (
          "<table class='" +
          (d.altAvs ? "scatterTip" : "scatterTipHidden") +
          "'>" +
          "<tr><td align='right'><strong>Alternative Id</strong>:</td><td>&nbsp;&nbsp;</td><td align='left'>" +
          d.altId +
          "</td></tr>" +
          d.altAvs
            .map(
              (obj, i) =>
                "<tr><td align='right'><strong>" +
                Object.keys(obj)[0] +
                "</strong>:</td><td>&nbsp;&nbsp;</td><td align='left'>" +
                Object.values(obj)[0] +
                "</td></tr>"
            )
            .join("") +
          "</table>"
        );
      });
    svg.call(tip);
    return tip;
  }, []);
  const drawSelectedData = useCallback(() => {
    var x = d3
      .scaleLinear()
      .domain(
        absolutePlotRange
          ? [-0.01, 1]
          : [
              -0.01,
              Math.max.apply(
                Math,
                data.map((d) => d.altData[1])
              ) + 0.01,
            ]
      )
      .range([0, width]);
    var y = d3
      .scaleLinear()
      .domain(
        absolutePlotRange
          ? [-0.01, 1]
          : [
              -0.01,
              Math.max.apply(
                Math,
                data.map((d) => d.altData[0])
              ) + 0.01,
            ]
      )
      .range([height, 0]);

    const svg = d3.select(ref.current);
    var selection = svg
      .selectAll("circle .selectedData")
      .data(selectedData, function (d) {
        return parseInt(d.altId);
      })
      .join(
        (enter) =>
          enter
            .append("circle")
            .attr("class", "selectedData")
            .attr("cx", (d) =>
              x(data.find((ad) => ad.altId === d.altId).altData[1])
            )
            .attr("cy", (d) =>
              y(data.find((ad) => ad.altId === d.altId).altData[0])
            )
            .attr("r", 10)
            .attr("fill", "red")
            .attr("stroke", "black")
            .attr("fill-opacity", 1)
            .on("mouseout", (e, d) => {
              Array.from(document.getElementsByClassName("d3-tip")).forEach(
                (el) => el.remove()
              );
              hoverData(d);
            }),
        (update) =>
          update.call((update) =>
            update
              .transition()
              .duration(600)
              .attr("cx", (d) => x(d.altData[1]) || 0)
              .attr("cy", (d) => y(d.altData[0]))
          ),
        (exit) => exit.remove()
      );
  }, [hoverData, selectedData, data, height, width, absolutePlotRange]);

  const dvColorScale = useMemo(() => {
    return !colorByDV || colorByDV === "steelblue"
      ? "steelblue"
      : alternativeVariables.find((av) => "" + av.id === "" + colorByDV)
          .type === "num"
      ? d3
          .scaleLinear()
          .domain(
            d3.extent(
              alternativeVariables
                .find((av) => "" + av.id === "" + colorByDV)
                .levels.map((d) => +d)
            )
          )
          .range(["white", "blue"])
      : d3.scaleOrdinal(d3.schemeCategory10);
  }, [colorByDV, alternativeVariables]);

  const draw = useCallback(() => {
    var x = d3
      .scaleLinear()
      .domain(
        absolutePlotRange
          ? [-0.01, 1]
          : [
              -0.01,
              Math.max.apply(
                Math,
                data.map((d) => d.altData[1])
              ) + 0.01,
            ]
      )
      .range([0, width]);
    var y = d3
      .scaleLinear()
      .domain(
        absolutePlotRange
          ? [-0.01, 1]
          : [
              -0.01,
              Math.max.apply(
                Math,
                data.map((d) => d.altData[0])
              ) + 0.01,
            ]
      )
      .range([height, 0]);
    const t = d3.transition().duration(600);
    d3.select(xAxisRef.current)
      .attr("transform", "translate(0," + height + ")")
      .transition(t)
      .call(d3.axisBottom(x));
    d3.select(yAxisRef.current)
      .attr("transform", "translate(0,0)")
      .transition(t)
      .call(d3.axisLeft(y));
    const svg = d3.select(ref.current);

    var selection = svg
      .selectAll("circle")
      .data(
        data.filter((d) => d.altData[0] >= 0),
        function (d) {
          return parseInt(d.altId);
        }
      )
      .join(
        (enter) =>
          enter
            .append("circle")
            .attr("cx", (d) => x(d.altData[1]) || width / 2)
            .attr("cy", height)
            .attr("r", 5)
            .attr("stroke", "gray")
            .attr(
              "fill",
              !colorByDV || colorByDV === "steelblue"
                ? "steelblue"
                : (d) => {
                    
                    let av = alternativeVariables.find(
                      (av) => av.id === colorByDV
                    );
                    return dvColorScale(
                      d.altAvs[
                        d.altAvs.findIndex(
                          (daav) => Object.keys(daav)[0] === av.name
                        )
                      ][av.name]
                    );
                  }
            )
            .attr(
              "fill-opacity",
              !colorByDV || colorByDV === "steelblue" ? 0.3 : 0.8
            )
            .on("mouseover", function (e, d) {
              createTip(svg).show(d, this);
              hoverData(d);
            })
            .call((enter) =>
              enter
                .transition(300)
                .attr("cx", (d) => x(d.altData[1]) || 0)
                .attr("cy", (d) => y(d.altData[0]))
            ),
        (update) =>
          update
            .attr(
              "fill",
              !colorByDV || colorByDV === "steelblue"
                ? "steelblue"
                : (d) => {
                    // console.log(dvColorScale.domain());
                    // console.log(
                    //   d.altAvs[
                    //     d.altAvs.findIndex(
                    //       (daav) => Object.keys(daav)[0] === "Payload"
                    //     )
                    //   ]["Payload"]
                    // );
                    let av = alternativeVariables.find(
                      (av) => av.id === colorByDV
                    );
                    return dvColorScale(
                      d.altAvs[
                        d.altAvs.findIndex(
                          (daav) => Object.keys(daav)[0] === av.name
                        )
                      ][av.name]
                    );
                  }
            )
            .attr(
              "fill-opacity",
              !colorByDV || colorByDV === "steelblue" ? 0.3 : 0.8
            )
            .call((update) =>
              update
                .transition()
                .duration(600)
                .attr("cx", (d) => x(d.altData[1]) || 0)
                .attr("cy", (d) => y(d.altData[0]))
            ),
        (exit) =>
          exit.call((exit) =>
            exit
              .transition()
              .duration(600)
              .attr("fill-opacity", 0)
              .attr("stroke-opacity", 0)
              .remove()
          )
      );
    // selection
    //   .transition()
    //   .duration(300)
    //   .attr("cx", (d) => x(d.altData[1]))
    //   .attr("cy", (d) => height - yScale(d.altData[0]));

    // selection
    //   .
    //   .transition()
    //   .duration(300)
    //   .attr("cx", (d) => x(d.altData[1]))
    //   .attr("cy", (d) => yScale(d.altData[0]));

    // selection
    //   .exit()
    //   .transition()
    //   .duration(300)
    //   .attr("cy", (d) => height)
    //   .attr("cx", (d) => 0)
    //   .attr("height", 0)
    //   .remove();
  }, [
    data,
    height,
    width,
    hoverData,
    createTip,
    alternativeVariables,
    dvColorScale,
    colorByDV,
    absolutePlotRange,
  ]);

  useEffect(() => {
    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .style("border", "1px solid black")
      .style("background", "white")
      .style("margin", "0px 0px 30px 30px")
      .style("overflow", "visible")
      .on("mouseleave", function (e, d) {
        Array.from(document.getElementsByClassName("d3-tip")).forEach((el) =>
          el.remove()
        );
      });
  }, [width, height]);

  useEffect(() => {
    draw();
  }, [data, draw]);

  useEffect(() => {
    drawSelectedData();
  }, [selectedData, drawSelectedData]);

  return (
    <div className="chart" style={{ position: "relative" }}>
      <div
        style={{
          rotate: "270deg",
          position: "absolute",
          left: "-90px",
          top: "200px",
          fontSize: "2em",
        }}
      >
        "Benefit"
      </div>
      <svg ref={ref}>
        <g ref={xAxisRef}></g>
        <g ref={yAxisRef}></g>
      </svg>
      <div style={{ fontSize: "2em", marginLeft: "270px" }}>
        {!Object.values(stakeholder.vm).find((vc) => vc.direction === "cost")
          ? "Please Assign Cost Criteria"
          : '"Cost"'}
      </div>
    </div>
  );
}

export default ExplorePlot;
