import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'

// Make sure it hasn't already been initialized
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket:process.env.REACT_APP_STORAGE_BUCKET,
    senderId: process.env.REACT_MESSAGING_SENDER_ID,
    appId:process.env.REACT_APP_ID
    
  });
}

export default firebase;
