import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { produce, applyPatches } from "immer";
import stakeholderLogic from "./StudyContextLogic/stakeholders";
import valueCriteriaLogic from "./StudyContextLogic/valueCriteria";
import alternativeVariablesLogic from "./StudyContextLogic/alternativeVariables";
import epochVariablesLogic from "./StudyContextLogic/epochVariables";
import changeOptionsLogic from "./StudyContextLogic/changeOptions";
import preparationsLogic from "./StudyContextLogic/preparations";
import stakeholderWarningsCheck from "./StudyContextLogic/warnings/stakeholderWarnings";
import valueCriteriaWarningsCheck from "./StudyContextLogic/warnings/valueCriteriaWarnings";
import AVandEVWarningsCheck from "./StudyContextLogic/warnings/AVandEVWarnings";
import valueCriteriaErrorChecking from "./StudyContextLogic/errors/valueCriteriaErrors";
import alternativeVariableErrorChecking from "./StudyContextLogic/errors/alternativeVariablesErrors";
import epochVariablesErrorChecking from "./StudyContextLogic/errors/epochVariablesErrors";
import firebase from "../util/firebase";
import "firebase/compat/firestore";
import { createBlankItem, createItem, updateItem, useItemsByOwner } from "../util/db";
import {spacetugexampledata} from "../spacetugexample";

const StudyContext = createContext(null);
const StudyDispatchContext = createContext(null);
const firebaseApp = firebase.firestore();

export function StudyProvider({ children, ...props }) {
  const redoStep = useRef(0);
  const patchHistory = useRef([]);
  const userItem = useItemsByOwner(props.user && props.user.uid);
  const [study, setStudy] = useState(initialStudy);
  const onetimecreation = useRef(false);

  const studyDispatch = useCallback((action) => {
    console.log(action);
    setStudy({
      ...produce(
        study,
        (draft) => {
          switch (action.section) {
            case "Study": {
              switch (action.type) {
                case "giveID": {
                  draft.fbid = action.fbid;
                  break;
                }
                case "updateNote": {
                  if (!draft.notes) draft.notes = [];
                  if (!action.note)
                    draft.notes = draft.notes.filter(
                      (n) => n.noteOnId !== action.noteOnId
                    );
                  else {
                    let noteIndex = draft.notes.findIndex(
                      (n) => n.noteOnId === action.noteOnId
                    );
                    if (noteIndex < 0)
                      draft.notes.push({
                        noteOnId: action.noteOnId,
                        section: action.noteSection,
                        note: action.note,
                      });
                    else
                      draft.notes[noteIndex] = {
                        noteOnId: action.noteOnId,
                        section: action.noteSection,
                        note: action.note,
                      };
                  }
                  break;
                }
                case "vals": {
                  draft.valuations = action.vals;
                  break;
                }
                case "evals": {
                  draft.evaluations = action.evals;
                  break;
                }
                case "evalmodel": {
                  draft.evalmodel = action.evalmodel;
                  break;
                }
                case "updateSetting": {
                  draft.settings[action.setting] = action.val;
                  break;
                }
                case "updateEntity": {
                  draft[action.entityName] = action.entity;
                  break;
                }
                case "rename": {
                  draft.name = action.newName;
                  if (!draft.settings[props.user.uid]) {
                    draft.settings[props.user.uid] = {};
                    draft.settings[props.user.uid]["activeNavTab"] =
                      "Stakeholders";
                  }
                  break;
                }
                case "changeActiveNavTab": {
                  if (props.user) {
                    if (!draft.settings) draft.settings = {};
                    if (!draft.settings[props.user.uid])
                      draft.settings[props.user.uid] = {};
                    draft.settings[props.user.uid].activeNavTab =
                      action.activeNavTab;
                  }
                  break;
                }
                case "restored": {
                  redoStep.current = -1;
                  draft.fbid = action.study.fbid || null;
                  draft.name = action.study.name;
                  draft.valueCriteria = action.study.valueCriteria;
                  draft.stakeholders = action.study.stakeholders;
                  draft.alternativeVariables =
                    action.study.alternativeVariables;
                  draft.epochVariables = action.study.epochVariables;
                  draft.preparations = action.study.preparations;
                  draft.changeOptions = action.study.changeOptions;
                  draft.warnings = action.study.warnings;
                  draft.errors = action.study.errors;
                  draft.settings = action.study.settings;
                  draft.activeUsers = action.study.activeUsers;
                  draft.notes = action.study.notes;
                  // draft.activeNavTab =
                  //   (action.study.settings[props.user.uid] && action.study.settings[props.user.uid].activeNavTab) || ;
                  draft.patchHistory = action.study.patchHistory;
                  patchHistory.current = [...action.study.patchHistory];
                  redoStep.current = action.study.redoStep;
                  draft.evaluations = action.study.evaluations;
                  draft.valuations = action.study.valuations;
                  draft.owner = action.study.owner.uid;
                  draft.evalmodel = action.study.evalmodel;
                  break;
                }
                case "UNDO": {
                  if (redoStep.current > 0) {
                    redoStep.current--;
                    console.log(
                      "UNDO:",
                      patchHistory.current[redoStep.current]
                    );
                    return applyPatches(
                      study,
                      patchHistory.current[redoStep.current].undo
                    );
                  }
                  break;
                }
                case "REDO": {
                  if (redoStep.current < patchHistory.current.length) {
                    redoStep.current++;
                    return applyPatches(
                      study,
                      patchHistory.current[redoStep.current - 1].redo
                    );
                  }
                  break;
                }
                default: {
                  console.log("unknown action");
                  break;
                }
              }
              break;
            }
            case "Stakeholders": {
              stakeholderLogic(draft, action);
              stakeholderWarningsCheck(draft, action, study);
              valueCriteriaErrorChecking(draft, action, study);
              break;
            }
            case "Value Criteria": {
              valueCriteriaLogic(draft, action);
              stakeholderWarningsCheck(draft, action, study);
              valueCriteriaWarningsCheck(draft, action, study);
              valueCriteriaErrorChecking(draft, action, study);
              AVandEVWarningsCheck(draft, action, study);
              alternativeVariableErrorChecking(draft, action, study);
              epochVariablesErrorChecking(draft, action, study);
              break;
            }
            case "Alternative Variables": {
              alternativeVariablesLogic(draft, action);
              AVandEVWarningsCheck(draft, action, study);
              alternativeVariableErrorChecking(draft, action, study);
              break;
            }
            case "Epoch Variables": {
              epochVariablesLogic(draft, action);
              AVandEVWarningsCheck(draft, action, study);
              epochVariablesErrorChecking(draft, action, study);
              break;
            }
            case "Preparations": {
              preparationsLogic(draft, action);
              break;
            }
            case "Change Options": {
              changeOptionsLogic(draft, action);
              break;
            }
            case "EWI": {
              switch (action.type) {
                case "added": {
                  if (
                    !draft[action.ewi].find(
                      (w) =>
                        w.name === action[action.ewi].name &&
                        w.affects === action[action.ewi].affects &&
                        w.affectsId === action[action.ewi].affectsId
                    )
                  )
                    draft[action.ewi].push({
                      id: crypto.randomUUID(),
                      name: action[action.ewi].name,
                      affects: action[action.ewi].affects,
                      affectsId: action[action.ewi].affectsId,
                      description: action[action.ewi].description,
                      ignored: false,
                    });
                  break;
                }

                case "ignored": {
                  draft[action.ewi][
                    draft[action.ewi].findIndex((w) => w.id === action.id)
                  ].ignored =
                    !draft[action.ewi][
                      draft[action.ewi].findIndex((w) => w.id === action.id)
                    ].ignored;
                  break;
                }

                case "deleted": {
                  draft[action.ewi] = draft[action.ewi].filter(
                    (w) => w.id !== action.id
                  );
                  break;
                }

                case "restored": {
                  draft[action.ewi] = action[action.ewi];
                  break;
                }
                default: {
                  console.log(
                    "Unknown action ewi: " + action.ewi + ", " + action.type
                  );
                }
              }
              break;
            }
            default: {
              console.log("uhhhhhhhhnknown action?");
              break;
            }
          }
        },
        (patches, inversePatches) => {
          // let allPaths = [];

          // patches.forEach(patch => {
          //   let wholePath = "study" + patch.path.reduce((pl,p)=>pl+"/"+p,"");
          //   console.log(wholePath);
          //   console.log(patch.value);
          //   // update firebase for each patch
          //   updateItem(study.fbid,`study/{{wholePath}}`:patch.value)
          // })
          // console.log(allPaths);
          if (
            action &&
            !action.noUndo &&
            action.type !== "giveID" &&
            action.type !== "UNDO" &&
            action.type !== "REDO" &&
            action.type !== "updateSetting" &&
            action.type !== "restored" &&
            action.type !== "evals" &&
            action.type !== "vals" &&
            action.type !== "evalmodel"
          ) {
            if (patches.length > 0 || inversePatches.length > 0)
              redoStep.current++;
            patchHistory.current.push({
              redo: patches,
              undo: inversePatches,
              timestamp: Date.now(),
              user: props.user && {
                displayName: props.user.displayName,
                uid: props.user.uid,
              },
            });
          }
        }
      ),
      patchHistory: [...patchHistory.current],
      redoStep: redoStep.current,
      activeUsers: study.activeUsers || [],
      // activeUsers:
      //   study.activeUsers && props.user
      //     ? !study.activeUsers.find((au) => au.id === props.user.uid)
      //       ? [
      //           { displayName: props.user.displayName, id: props.user.uid },
      //           ...study.activeUsers,
      //         ]
      //       : study.activeUsers
      //     : [],
    });
  },[props.user,study]);

  // // set up a listener if we open a Firebase doc study
  // useEffect(() => {
  //   console.log(study.fbid);
  //   if (!study.fbid) return;
  //   const handleStudyChanges = (snap) => {
  //     setStudy({ ...snap.data().study, fbid: study.fbid });
  //   };

  //   const query = study.fbid && firebaseApp.collection("items").doc(study.fbid);

  //   // Create the DB listener
  //   const unsuscribe = query.onSnapshot(handleStudyChanges, (err) =>
  //     console.log(err)
  //   );
  //   return () => {
  //     unsuscribe();
  //   };
  // }, [study.fbid]);

  // store any changes in localStorage
  useEffect(() => {
    if (study.name !== "Unnamed Project") {
      console.log("study:", study);
      if (study.fbid) updateItem(study.fbid, { study: study });
      localStorage.setItem("study", JSON.stringify(study));
    } else {
      console.log("Named Unnamed: ",study);
    }
  }, [study,studyDispatch]);

  return (
    <StudyContext.Provider value={study}>
      <StudyDispatchContext.Provider value={studyDispatch}>
        {children}
      </StudyDispatchContext.Provider>
    </StudyContext.Provider>
  );
}

export function useStudy() {
  return useContext(StudyContext);
}
export function useStudyDispatch() {
  return useContext(StudyDispatchContext);
}

const initialStudy = (localStorage.getItem("study") && JSON.parse(localStorage.getItem("study"))) || {
study: {
  name: "New Study",
  stakeholders: [
  ],
  valueCriteria: [
  ],
  alternativeVariables: [
    
  ],
  epochVariables: [
    
  ],
  preparations: [],
  changeOptions: [],
  warnings: [],
  errors: [],
  settings: {
    showResponse: true,
    showUncertainty: true,
    showEWIinline: false,
  },
  activeUsers: [],
  patchHistory: [],
  notes: [],
  fbid: null,
  evaluations: [],
  valuations: {},
  redoStep: 0,
  lastModified: "2023-07-05T20:21:18.471Z",
  evalmodel: {},
},
owner: "VXy3p2R4k3U5XSjIsoYTnGoYYUA2",
shared: []};
