import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Table from "react-bootstrap/esm/Table";
import ErrorIcon from "../ErrorIcon";
import CustomNavIcon from "../CustomNavIcon";

import { useStudy, useStudyDispatch } from "../../StudyContext";

function ChangeOptionsSection(props) {
  const study = useStudy();
  const studyDispatch = useStudyDispatch();
  const alternativeVariables = study.alternativeVariables;
  const epochVariables = study.epochVariables;
  const changeOptions = study.changeOptions;
  const preparations = study.preparations;

  const optionName = useRef("");
  const optionEvs = useRef([]);
  const [validatedOption, setValidatedOption] = useState(false);
  const validateOption = () => {
    if (
      optionName.current.value.length > 0 &&
      changeOptions.filter((dv) => dv.name === optionName.current.value)
        .length < 1 &&
      optionEvs.current.length > 0
    )
      setValidatedOption(true);
    else setValidatedOption(false);
  };
  const changeEvInRef = (event, ev) => {
    if (event.target.checked && optionEvs.current.indexOf(ev.id) < 0)
      optionEvs.current.push(ev.id);
    else if (!event.target.checked && optionEvs.current.indexOf(ev.id) >= 0)
      optionEvs.current.splice(optionEvs.current.indexOf(ev.id), 1);
    validateOption();
  };

  const addOption = () => {
    studyDispatch({
      section: "Change Options",
      type: "added",
      option: { name: optionName.current.value, evs: optionEvs.current },
    });
    optionName.current.value = "";
    Array.from(document.getElementsByClassName("cvsCheckbox")).forEach(
      (el) => (el.checked = false)
    );
    validateOption();
  };

  return (
    <Form.Group className="mb-3 ChangeSection">
      <h4>Change Options</h4>
      <div>
        Defining dynamic responses to uncertainty by specifying preparations and
        effects.
      </div>
      <Table striped={true} cellPadding={3}>
        <thead>
          <tr>
            <td
              colSpan={3}
              align="center"
              style={{ borderRight: "1px solid gray" }}
            >
              Option...
            </td>
            <td
              align="center"
              colSpan={(epochVariables && epochVariables.length) || 1}
              style={{ borderRight: "1px solid gray" }}
            >
              ...responds to changing epochs...
            </td>
            {changeOptions && changeOptions.length > 0 ? (
              <>
                <td colSpan={preparations.length} align="center">
                  ...by using preparations&nbsp;
                  <span
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => props.setActiveNavTab("Preparations")}
                  >
                    <i className="fas fa-chess-rook" />
                  </span>
                  ...
                </td>
                <td
                  colSpan={alternativeVariables.length}
                  align="center"
                  style={{ borderLeft: "1px solid gray" }}
                >
                  ...to effect changes on alternatives&nbsp;
                  <i
                    onClick={() =>
                      props.setActiveNavTab("Alternative Variables")
                    }
                    style={{ cursor: "pointer" }}
                    className="fas fa-drafting-compass"
                  />
                </td>
              </>
            ) : (
              <td colSpan={2}> </td>
            )}
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            {epochVariables &&
              epochVariables.length > 0 &&
              epochVariables.map((ev, i) => (
                <td
                  key={ev.id}
                  align="center"
                  style={{
                    borderRight:
                      i === epochVariables && epochVariables.length - 1
                        ? "1px solid gray"
                        : "none",
                    borderLeft: i === 0 ? "1px solid gray" : "none",
                  }}
                >
                  <div
                    style={{
                      width: "25px",
                      fontSize: "0.8em",
                      cursor: "pointer",
                    }}
                    onClick={() => props.setActiveNavTab("Epoch Variables")}
                  >
                    <CustomNavIcon
                      type={
                        ev.context && ev.preference
                          ? "both"
                          : ev.context
                          ? "fas fa-calculator"
                          : "fas fa-eye"
                      }
                    />
                  </div>{" "}
                  {ev.name}{" "}
                </td>
              ))}

            {preparations.length > 0 &&
            changeOptions &&
            changeOptions.length > 0 ? (
              preparations.map((prep, i) => (
                <td
                  key={prep.id}
                  align="center"
                  style={{
                    borderRight:
                      i === epochVariables && epochVariables.length - 1
                        ? "1px solid gray"
                        : "none",
                  }}
                >
                  {prep.name}
                </td>
              ))
            ) : changeOptions && changeOptions.length > 0 ? (
              <td align="center" style={{ borderRight: "1px solid gray" }}>
                (None)
              </td>
            ) : (
              ""
            )}
            {changeOptions &&
              changeOptions.length > 0 &&
              alternativeVariables.map((dv, i) => (
                <td
                  key={dv.id}
                  align="center"
                  style={{
                    borderRight:
                      i === alternativeVariables.length - 1
                        ? "1px solid gray"
                        : "none",
                  }}
                >
                  {dv.name}
                </td>
              ))}
          </tr>
        </thead>
        <tbody>
          {changeOptions &&
            changeOptions.map((option) => {
              return (
                <tr key={option.name} className="dcDetails">
                  <td width={40}>
                    {option.avs.length < 1 ? (
                      <a
                        href="#"
                        title={
                          "Option must change at least one Alternative Variable..."
                        }
                      >
                        <ErrorIcon />
                      </a>
                    ) : option.evs.length < 1 || option.preps.length < 1 ? (
                      <a
                        href="#"
                        title={
                          (option.evs.length < 1
                            ? "-Every option is a response to uncertainty...consider adding an epoch variable for justification."
                            : "") +
                          (option.preps.length < 1
                            ? "\n-No preparation selected. Is this right? (i.e., alternatives inherently enable this option?)"
                            : "")
                        }
                      >
                        <i className="fa fa-exclamation-circle" />
                      </a>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td>
                    <i
                      onClick={() =>
                        studyDispatch({
                          section: "Change Options",
                          type: "deleted",
                          id: option.id,
                        })
                      }
                      className="fas fa-minus-circle removeIcon"
                    />
                  </td>
                  <td>{option.name}</td>
                  {epochVariables &&
                    epochVariables.map((ev) => (
                      <td
                        align="center"
                        key={ev.id}
                        style={{ borderLeft: "1px solid gray" }}
                      >
                        {" "}
                        {ev.levels.length > 1 && (
                          <input
                            type="checkbox"
                            defaultChecked={option.evs.indexOf(ev.id) >= 0}
                            onChange={(event) => {
                              studyDispatch({
                                section: "Change Options",
                                type: "changeEV",
                                coid: option.id,
                                evid: ev.id,
                              });
                            }}
                          />
                        )}
                      </td>
                    ))}

                  {preparations.length > 0 ? (
                    preparations.map((prep) => (
                      <td
                        align="center"
                        key={prep.id}
                        style={{ borderLeft: "1px solid gray" }}
                      >
                        <div
                          onClick={() =>
                            studyDispatch({
                              section: "Change Options",
                              type: "changePrep",
                              prep: prep,
                              coid: option.id,
                            })
                          }
                          className="changePrefs"
                        >
                          <i
                            className={
                              !option.preps.find(
                                (optionPrep) => optionPrep.id === prep.id
                              )
                                ? "fas fa-ban noPrefs"
                                : option.preps.find(
                                    (optionPrep) => optionPrep.id === prep.id
                                  ).level === "req"
                                ? "fas fa-link"
                                : "fas fa-hands-helping"
                            }
                          />
                        </div>
                      </td>
                    ))
                  ) : (
                    <td align="center" style={{ borderLeft: "1px solid gray" }}>
                      <span
                        style={{
                          textDecoration: "underline",
                          fontSize: "0.7em",
                          cursor: "pointer",
                        }}
                        onClick={() => props.setActiveNavTab("Preparations")}
                      >
                        Add Preparation
                      </span>
                    </td>
                  )}
                  {alternativeVariables.map((dv, i) => (
                    <td
                      key={option.name + dv.id}
                      align={"center"}
                      style={{
                        borderLeft: "1px solid gray",
                        borderRight:
                          i === alternativeVariables.length - 1
                            ? "1px solid gray"
                            : "none",
                      }}
                    >
                      <div
                        onClick={() =>
                          studyDispatch({
                            section: "Change Options",
                            type: "changeAV",
                            av: dv,
                            coid: option.id,
                          })
                        }
                        className="changePrefs"
                      >
                        <i
                          className={
                            !option.avs.find(
                              (optionDv) => optionDv.id === dv.id
                            )
                              ? "fas fa-ban noPrefs"
                              : option.avs.find(
                                  (optionDv) => optionDv.id === dv.id
                                ).level === "up"
                              ? "fas fa-long-arrow-alt-up"
                              : option.avs.find(
                                  (optionDv) => optionDv.id === dv.id
                                ).level === "upanddown"
                              ? "fas fa-exchange-alt fa-rotate-90"
                              : option.avs.find(
                                  (optionDv) => optionDv.id === dv.id
                                ).level === "down"
                              ? "fas fa-long-arrow-alt-down"
                              : "fas fa-exchange-alt"
                          }
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td>
              <Form.Control
                type="name"
                ref={optionName}
                placeholder="e.g., Change orbit"
                onChange={validateOption}
              />
            </td>
            {epochVariables &&
              epochVariables.map((ev) => (
                <td align="center" key={ev.id}>
                  <input
                    type="checkbox"
                    className="cvsCheckbox"
                    defaultChecked={optionEvs.current.indexOf(ev.id) >= 0}
                    onChange={(event) => {
                      changeEvInRef(event, ev);
                    }}
                  />
                </td>
              ))}
            <td>
              <button
                onClick={addOption}
                id="addDV"
                disabled={!validatedOption}
              >
                Add
              </button>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td colSpan={2}></td>
          </tr>
        </tfoot>
      </Table>
    </Form.Group>
  );
}
export default ChangeOptionsSection;
