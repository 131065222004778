import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Table from "react-bootstrap/esm/Table";
import CustomNavIcon from "./CustomNavIcon";
import { useStudy, useStudyDispatch } from "../StudyContext";

function ValueMatrixSection(props) {
  const study = useStudy();
  const studyDispatch = useStudyDispatch();
  const stakeholders = study.stakeholders;
  const valueCriteria = study.valueCriteria;
  const alternativeVariables = study.alternativeVariables;
  const contextVariables = study.epochVariables.filter(ev => ev.context);
  const preparations = study.preparations;
  
  const numericValues = { none: 0, low: 1, med: 3, high: 9 };
  const [aggregate, setAggregate] = useState(
    stakeholders && stakeholders.length > 1 ? true : false
  );
  const [showLevels, setShowLevels] = useState({});
  const [showingVarType, setShowingVarType] = useState(
    props.defaultShowVarType
  );
  const showStakeholders = aggregate
    ? [{ name: "All Stakeholders", vm: ["all"] }]
    : stakeholders;
  const changeShowingVarType = (avevpe) => {
    if (showingVarType === avevpe) setShowingVarType(null);
    else setShowingVarType(avevpe);
  };
  const setNewShowLevel = (dvName) => {
    let newShowLevels = {};
    alternativeVariables.concat(contextVariables).forEach((dvev) => {
      newShowLevels[dvev.name] =
        dvev.name === dvName && !showLevels[dvName] ? true : false;
    });
    setShowLevels(newShowLevels);
  };
  return (
    <Form.Group
      className={
        "mb-3" +
        (props.defaultShowVarType === "prep"
          ? " ChangeSection"
          : props.defaultShowVarType === "epoch"
          ? " EEASection"
          : " MATESection")
      }
    >
      <h4>Value Matrix</h4>
      <div>
        The estimated impact of {alternativeVariables.length > 0 ? "Alternative " : ""}
        {alternativeVariables.length > 0 && contextVariables.length > 0 ? "and " : ""}
        {contextVariables.length > 0 ? "Epoch " : ""}
        Variables {preparations.length > 0 ? " and Preparations " : ""} on
        Stakeholders' Value Criteria.
      </div>

      <Table striped={true} cellPadding={3} className="vmTable">
        {showStakeholders.map((stakeholder, i) => (
          <>
            <thead className="vmTableHeader" key={stakeholder.name}>
              {alternativeVariables.concat(contextVariables).length > 0 && (
                <>
                  <tr>
                    <td colSpan={3} align="center" valign="bottom">
                      {i === 0 &&
                        (alternativeVariables.length > 0 ? "Alternative" : "") +
                          (alternativeVariables.length > 0 && contextVariables.length > 0
                            ? " and "
                            : "") +
                          (contextVariables.length > 0 ? "Epoch" : "") +
                          " Variables"}
                    </td>
                    <td colSpan={1 + valueCriteria?valueCriteria.length:0} align="center">
                      <div
                        className="vmStakeholderName"
                        onClick={() => setAggregate(!aggregate)}
                      >
                        {stakeholder.name}
                      </div>
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td></td>
                <td></td>
                <td style={{ borderRight: "1px solid black" }}>Name</td>
                {valueCriteria && valueCriteria.map((dc) => (
                  <td key={dc.id} align="center">
                    {(stakeholder.vm[0] === "all" ||
                      Object.keys(stakeholder.vm).indexOf(dc.id) >=
                        0) && <div className="vmTableDCHeader"><small><i className="fas fa-gem"/></small> {dc.name}</div>}
                  </td>
                ))}
                <td align="center">(sum)</td>
              </tr>
            </thead>
            <tbody>
              {alternativeVariables.concat(contextVariables).map((dvOrEv, i) => {
                let inDvs = alternativeVariables.indexOf(dvOrEv) >= 0;
                let designOrEpoch = inDvs ? "alternative" : "epoch";
                let sumOfImpacts = (
                  stakeholder.vm[0] === "all"
                    ? dvOrEv.dcs
                    : dvOrEv.dcs.filter(
                        (dc) =>
                          Object.keys(stakeholder.vm).filter((vmid) => vmid === dc.id)
                            .length > 0
                      )
                )
                  .map((dc) => numericValues[dc.level])
                  .reduce((partialSum, a) => partialSum + a, 0);
                return (
                  <tr
                    key={designOrEpoch + dvOrEv.id}
                    className={
                      "dcDetails" +
                      (showingVarType && showingVarType !== designOrEpoch
                        ? " disabledRow"
                        : "")
                    }
                    style={i === 0 ? { borderTop: "2px solid black" } : {}}
                  >
                    <td width={40}>
                      {dvOrEv.dcs.length < 1 ? (
                        <a
                          href="#"
                          title={
                            "This " +
                            designOrEpoch +
                            " variable apparently doesn't affect any value criteria. " +
                            "Please map it to an existing (applicable) one, add an applicable" +
                            " (missing?) value criterion, or consider removing this (unneeded) " +
                            designOrEpoch +
                            " variable from your study."
                          }
                        >
                          <i className="fa fa-exclamation-circle" />
                        </a>
                      ) : inDvs ? (
                        <i
                          onClick={() => changeShowingVarType(designOrEpoch)}
                          className="fas fa-drafting-compass vmDvIcon"
                        />
                      ) : (<div onClick={() => changeShowingVarType(designOrEpoch)}
                      className="vmDvIcon" style={{fontSize:"0.7em"}}>
                        <CustomNavIcon
                          type={
                            dvOrEv.context && dvOrEv.preference
                              ? "both"
                              : dvOrEv.context
                              ? "fas fa-calculator"
                              : "fas fa-eye"
                          }
                        /></div>
                      )}
                    </td>
                    <td>
                      &nbsp;
                      {/* {dvOrEv.dcs.length < 1 && (
                        <i
                          className="fas fa-minus-circle removeIcon"
                          onClick={() =>
                            inDvs
                              ? alternativeVariablesDispatch({type:"deleted",id:dvOrEv.id})
                              : epochVariablesDispatch({type:"deleted",id:dvOrEv.id})
                          }
                        />
                      )} */}
                    </td>
                    <td className="vmDvCvName">
                      {dvOrEv.name}&nbsp;&nbsp;{"("}
                      {showLevels[dvOrEv.name] &&
                        dvOrEv.levels.map((lev, i) => (
                          <span
                            key={i}
                            className="vmLevs"
                            onClick={() => setNewShowLevel(dvOrEv.name)}
                          >
                            {lev}
                            {i === dvOrEv.levels.length - 1 ? "" : ","}{" "}
                          </span>
                        ))}
                      {!showLevels[dvOrEv.name] && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setNewShowLevel(dvOrEv.name)}
                        >
                          ...
                        </span>
                      )}
                      {")"}
                    </td>
                    {valueCriteria && valueCriteria.map((dc, i) => (
                      <td
                        key={dvOrEv.name + dc.id}
                        align="center"
                        style={
                          i === 0
                            ? { borderLeft: "2px solid black" }
                            : i === valueCriteria.length - 1
                            ? {
                                borderLeft: "1px solid gray",
                                borderRight: "2px solid black",
                              }
                            : { borderLeft: "1px solid gray" }
                        }
                      >
                        {(stakeholder.vm[0] === "all" ||
                          Object.keys(stakeholder.vm)
                            .indexOf(dc.id) >= 0) && (
                          <div
                            onClick={() =>
                              inDvs
                                ? studyDispatch({section:"Alternative Variables",type:"changeVC",vc:dc,av:dvOrEv})
                                : studyDispatch({section:"Epoch Variables",type:"changeVC",vc:dc, ev:dvOrEv})
                            }
                            className="changePrefs"
                          >
                            <i
                              className={
                                !dvOrEv.dcs.find((cvdc) => cvdc.id === dc.id)
                                  ? "fas fa-ban noPrefs"
                                  : dvOrEv.dcs.find((cvdc) => cvdc.id === dc.id)
                                      .level === "low"
                                  ? "fas fa-th low"
                                  : dvOrEv.dcs.find((cvdc) => cvdc.id === dc.id)
                                      .level === "med"
                                  ? "fas fa-th med"
                                  : "fas fa-th high"
                              }
                            />
                          </div>
                        )}
                      </td>
                    ))}
                    <td align="center">{sumOfImpacts}</td>
                  </tr>
                );
              })}
              {preparations && preparations.map((prep, i) => {
                console.log(prep);
                let sumOfImpacts = (prep.dcs ? (
                  stakeholder.vm[0] === "all"
                    ? prep.dcs
                    : prep.dcs.filter(
                        (dc) =>
                          Object.keys(stakeholder.vm).filter((vmdc) => vmdc.id === dc.id)
                            .length > 0
                      )
                ) : [])
                  .map((dc) => numericValues[dc.level])
                  .reduce((partialSum, a) => partialSum + a, 0);
                return (
                  <tr
                    key={prep.id}
                    className={
                      "dcDetails" +
                      (showingVarType && showingVarType !== "prep"
                        ? " disabledRow"
                        : "")
                    }
                    style={i === 0 ? { borderTop: "2px solid black" } : {}}
                  >
                    <td width={40}>
                      {prep.dcs.length < 1 ? (
                        <a
                          href="#"
                          title={
                            "This preparation apparently doesn't affect any value criteria. " +
                            "Is this correct?"
                          }
                        >
                          <i className="fa fa-exclamation-circle" />
                        </a>
                      ) : (
                        <i
                          onClick={() => changeShowingVarType("prep")}
                          className={"fas fa-chess-rook vmDvIcon"}
                        />
                      )}
                    </td>
                    <td>
                      &nbsp;
                      {/* <i
                        className="fas fa-minus-circle removeIcon"
                        onClick={() => props.removePrep(prep.id)}
                      /> */}
                    </td>
                    <td className="vmDvCvName">{prep.name}</td>
                    {valueCriteria && valueCriteria.map((dc, i) => (
                      <td
                        key={prep.name + dc.id}
                        align="center"
                        style={
                          i === 0
                            ? { borderLeft: "2px solid black" }
                            : i === valueCriteria.length - 1
                            ? {
                                borderLeft: "1px solid gray",
                                borderRight: "2px solid black",
                              }
                            : { borderLeft: "1px solid gray" }
                        }
                      >
                        {(stakeholder.vm[0] === "all" ||
                          Object.keys(stakeholder.vm)
                            .indexOf(dc.id) >= 0) && (
                          <div
                            onClick={() => alert("implement")}
                            className="changePrefs"
                          >
                            <i
                              className={
                                !prep.dcs.find((pedc) => pedc.id === dc.id)
                                  ? "fas fa-ban noPrefs"
                                  : prep.dcs.find((pedc) => pedc.id === dc.id)
                                      .level === "low"
                                  ? "fas fa-th low"
                                  : prep.dcs.find((pedc) => pedc.id === dc.id)
                                      .level === "med"
                                  ? "fas fa-th med"
                                  : "fas fa-th high"
                              }
                            />
                          </div>
                        )}
                      </td>
                    ))}
                    <td align="center">{sumOfImpacts}</td>
                  </tr>
                );
              })}
              <tr
                className="dcDetails"
                style={{ borderTop: "2px solid black" }}
              >
                <td colSpan={3}></td>
                {valueCriteria && valueCriteria.map((dc, i) => {
                  let allDcs = (
                    showingVarType && showingVarType === "alternative"
                      ? alternativeVariables
                      : showingVarType && showingVarType === "epoch"
                      ? contextVariables
                      : showingVarType && showingVarType === "prep"
                      ? preparations
                      : alternativeVariables.concat(contextVariables).concat(preparations)
                  )
                    .map((dvOrCv) => dvOrCv.dcs)
                    .flat();
                  let matchingDcs = allDcs.filter(
                    (dvOrCvdc) => dvOrCvdc.id === dc.id
                  );
                  let sumOfImpacts = matchingDcs
                    ? matchingDcs
                        .map((matchingDc) => numericValues[matchingDc.level])
                        .reduce((partialSum, a) => partialSum + a, 0)
                    : "0";
                  // .reduce((partialSum, a) => partialSum + a, 0);
                  return (
                    <td
                      key={dc.id}
                      align="center"
                      style={
                        i < valueCriteria.length - 1
                          ? { borderLeft: "1px solid gray" }
                          : {
                              borderLeft: "1px solid gray",
                              borderRight: "1px solid gray",
                            }
                      }
                    >
                      {(stakeholder.vm[0] === "all" ||
                        Object.keys(stakeholder.vm).indexOf(dc.id) >=
                          0) &&
                        sumOfImpacts}
                    </td>
                  );
                })}
              </tr>
              <tr className="vmSeparatorRow">
                <td colSpan={4 + (valueCriteria?valueCriteria.length:0)}></td>
              </tr>
            </tbody>
          </>
        ))}
      </Table>
    </Form.Group>
  );
}
export default ValueMatrixSection;
