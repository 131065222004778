import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import "./generator/generator";
import BlocklyComponent from "./Blockly/BlocklyComponent";
import initToolbox from "./Blockly/initialToolbox";

function EvalModelSection(props) {
  useEffect(() => console.log(props.modelRunError), [props.modelRunError]);
  return (
    <Container>
      <Row>
        <div className="lastRun">
          {props.lastRunPerf >= 0 &&
            "(Last run took: " +
              Math.round(props.lastRunPerf * 100) / 100 +
              (props.lastRunPerf >= 0 ? "ms" : "") +
              " )"}
        </div>
        {props.modelRunError && (
          <div className="modelErrorNotice">
            MODEL ERROR{" "}
            {window.LoopTrap === 0 && "(looped more than 999 times)"}
          </div>
        )}
        <div className="runningModel">RUNNING</div>
      </Row>
      <Row>
        <BlocklyComponent
          readOnly={false}
          trashcan={true}
          media="/media"
          move={{
            scrollbars: true,
            drag: true,
            wheel: true,
          }}
          initialToolbox={initToolbox}
          // saveWorkspace={saveWorkspace}
          runModel={props.runModel}
          setDefinePoppedOut={props.setDefinePoppedOut}
          setShowHelp={props.setShowHelp}
          previewPin={props.previewPin}
          setPreviewPoppedOut={props.setPreviewPoppedOut}
          loadInitial={props.loadInitial}
          setLoadInitial={props.setLoadInitial}
        ></BlocklyComponent>
      </Row>
    </Container>
  );
}
export default EvalModelSection;
