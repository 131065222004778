import { useReducer, useEffect, useRef } from "react";
import firebase from "./firebase";
import { serverTimestamp } from "firebase/firestore";
import "firebase/compat/firestore";

const firestoreApp = firebase.firestore();

/**** USERS ****/

// Fetch user data (hook)
// This is called automatically by auth.js and merged into auth.user
export function useUser(uid) {
  return useQuery(uid && firestoreApp.collection("users").doc(uid));
}

// Create a new user
export function createUser(uid, data) {
  return firestoreApp
    .collection("users")
    .doc(uid)
    .set({ uid, ...data }, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return firestoreApp.collection("users").doc(uid).update(data);
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Fetch item data (hook)
export function useItem(id) {
  return useQuery(id && firestoreApp.collection("items").doc(id));
}

// Fetch all items by owner (hook)
export function useItemsByOwner(owner) {
  return useQuery(
    owner &&
      firestoreApp
        .collection("items")
        .where("owner", "==", owner)
        .orderBy("createdAt", "desc")
  );
}
// Fetch all public items (hook)
export function usePublicItems() {
  return useQuery(
    firestoreApp.collection("items").where("shared", "array-contains", "public")
  );
}

// Fetch default study for one-study-version
export function useOSVitem() {
  return useQuery(
    firestoreApp.collection("items").where("owner", "==", "vxd5Nvg8A1gmEet8a8k9NYiKPYt1")
  );
}

// Create a new item
export function createItem(data) {
  console.log(data);
  return firestoreApp.collection("items").add({
    ...data,
    createdAt: serverTimestamp(),
  });
}
// Create a new, blank document
export function createBlankItem() {
  return firestoreApp.collection("items").doc().id;
}

// Update an item
export function updateItem(id, data) {
  return firestoreApp.collection("items").doc(id).update(data);
}

export function updateHeartbeat(user, id) {
  const docRef = firestoreApp.collection("items").doc(id);
  docRef.get().then((doc) => {
    // we need to volunteer to be RTC if there is not one, or if the one that is there hasn't been active for over 35 seconds
    const needRtc = !doc.data().rtc || (!doc.data().study.activeUsers[doc.data().rtc]) || Date.now() - doc.data().study.activeUsers[doc.data().rtc].timestamp > 35000;
    if (needRtc || doc.data().rtc===user.uid) {
        console.log("inside: ",doc.data());
        // do the check for timed out users
        Object.keys(doc
          .data()
          .study.activeUsers)
          .forEach((k) => {
            if (Date.now() - doc.data().study.activeUsers[k].timestamp > 35000) 
              docRef.update({['study.activeUsers.'+k]: firebase.firestore.FieldValue.delete()});
          });
          docRef
            .set({rtc: needRtc?user.uid:doc.data().rtc,study: { activeUsers: {[user.uid]: {
              displayName: user.displayName,
              timestamp: Date.now(),
            }}}},{merge:true})
            .catch(() => {
              console.log("couldn't write the real-time controller info");
            })
    } else {
      console.log("setting active users")
      docRef.set(
        {
          study: {
            activeUsers: {
              [user.uid]: {
                displayName: user.displayName,
                timestamp: Date.now(),
              },
            },
          },
        },
        { merge: true }
      );
    }
  });
}

// Delete an item
export function deleteItem(id) {
  return firestoreApp.collection("items").doc(id).delete();
}

/**** HELPERS ****/

// Reducer for useQuery hook state and actions
const reducer = (state, action) => {
  switch (action.type) {
    case "idle":
      return { status: "idle", data: undefined, error: undefined };
    case "loading":
      return { status: "loading", data: undefined, error: undefined };
    case "success":
      return { status: "success", data: action.payload, error: undefined };
    case "error":
      return { status: "error", data: undefined, error: action.payload };
    default:
      throw new Error("invalid action");
  }
};

// Custom React hook that subscribes to a Firestore query
function useQuery(query) {
  // Our initial state
  // Start with an "idle" status if query is falsy, as that means hook consumer is
  // waiting on required data before creating the query object.
  // Example: useQuery(uid && firestore.collection("profiles").doc(uid))
  const initialState = {
    status: query ? "loading" : "idle",
    data: undefined,
    error: undefined,
  };

  // Setup our state and actions
  const [state, dispatch] = useReducer(reducer, initialState);

  // Gives us previous query object if query is the same, ensuring
  // we don't trigger useEffect on every render due to query technically
  // being a new object reference on every render.
  const queryCached = useMemoCompare(query, (prevQuery) => {
    // Use built-in Firestore isEqual method to determine if "equal"
    return prevQuery && query && query.isEqual(prevQuery);
  });

  useEffect(() => {
    // Return early if query is falsy and reset to "idle" status in case
    // we're coming from "success" or "error" status due to query change.
    if (!queryCached) {
      dispatch({ type: "idle" });
      return;
    }

    dispatch({ type: "loading" });

    // Subscribe to query with onSnapshot
    // Will unsubscribe on cleanup since this returns an unsubscribe function
    return queryCached.onSnapshot(
      (response) => {
        // Get data for collection or doc
        const data = response.docs
          ? getCollectionData(response)
          : getDocData(response);

        dispatch({ type: "success", payload: data });
      },
      (error) => {
        dispatch({ type: "error", payload: error });
      }
    );
  }, [queryCached]); // Only run effect if queryCached changes

  return state;
}

// Get doc data and merge doc.id
function getDocData(doc) {
  return doc.exists === true ? { id: doc.id, ...doc.data() } : null;
}

// Get array of doc data from collection
function getCollectionData(collection) {
  return collection.docs.map(getDocData);
}

// Used by useQuery to store Firestore query object reference
function useMemoCompare(next, compare) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;

  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);

  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}
