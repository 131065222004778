import { Col, Container, Row, Stack } from "react-bootstrap";
import CrossEpochVariableRules from "../CrossEpochVariableRules.js";
import { useState } from "react";
import CustomNavIcon from "../../CustomNavIcon.js";

function ESFEraConstructor({ epochVariables, ...props }) {
  const [crossEpochVariableRules, setCrossEpochVariableRules] = useState([]);
  const [enableCrossVariableRules, setEnableCrossVariableRules] = useState(
    crossEpochVariableRules.length > 0
  );

  const eraTimescale =
    epochVariables.map((ev) => ev.timescale).indexOf("years") >= 0
      ? "years"
      : epochVariables.map((ev) => ev.timescale).indexOf("months") >= 0
      ? "months"
      : "days";
  const eraTimestep = epochVariables.find((ev) => ev.timescale === "days")
    ? "days"
    : epochVariables.find((ev) => ev.timescale === "months")
    ? "months"
    : "years";

  return (
    <>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <h4>Epoch Syncopation Framework (ESF) Era Constructor</h4>
      </Row>
      <Row>
        <h5 className="eraCreationRowHeader">
          {" "}
          <input type="checkbox" defaultChecked={true} disabled={true} /> Era
          Length
        </h5>
        <Col>
          <input
            min={0.5}
            max={100}
            step={0.5}
            maxLength={3}
            style={{ width: "50px" }}
            type="number"
          />
          &nbsp;{eraTimescale}, with time step of {eraTimestep}
        </Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <h5 className="eraCreationRowHeader">
          {" "}
          <input type="checkbox" defaultChecked={true} disabled={true} /> Epoch
          Variable Event Intervals
        </h5>
        {epochVariables.map((ev) => (
          <Col key={ev.id} sm={3} style={{minWidth:"250px"}}>
            <Container>
              <Row className="mt-5 markovVariableHeader">
                <Col sm="auto">
                  <small>
                    <small>
                      <CustomNavIcon
                        type={
                          ev.context && ev.preference
                            ? "both"
                            : ev.context
                            ? "fas fa-calculator"
                            : "fas fa-eye"
                        }
                      />
                    </small>
                  </small>
                </Col>
                <Col md="auto">{ev.name}</Col>
              </Row>
              <Row >
                <Col className="p-2 markovTable">
                  <div>
                    {" "}
                    𝝺 =&nbsp;
                    <input
                      min={0.5}
                      max={100}
                      step={0.5}
                      maxLength={3}
                      style={{ width: "50px" }}
                      type="number"
                    />{" "}
                    {ev.timescale}
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        ))}
      </Row>

      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h5  className="eraCreationRowHeader">
            <input
              type="checkbox"
              defaultChecked={false}
              onChange={(ev) => setEnableCrossVariableRules(ev.target.checked)}
            />{" "}
            Cross-Variable Transition Rules
          </h5>
        </Col>
      </Row>
      <Row>
        {enableCrossVariableRules && (
          <CrossEpochVariableRules
            epochVariables={epochVariables}
            crossVariableRules={crossEpochVariableRules}
            setCrossVariableRules={setCrossEpochVariableRules}
          />
        )}
      </Row>
    </>
  );
}
export default ESFEraConstructor;
