import { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import CustomNavIcon from "../../CustomNavIcon";

function EpochVariableDistributions({ epochVariables, ...props }) {
  const statDistros = ["uniform", "poisson", "gamma"];
  const oldEpochVariables = useRef([]);
  const [paramsState, setParamsState] = useState({});
  useEffect(() => {
    let newParamsState = {};
    epochVariables
      .filter((ev) => oldEpochVariables.current.indexOf(ev.id) < 0)
      .forEach((ev) => {
        newParamsState[ev.name] = {};
        ev.levels.forEach((l) => {
          newParamsState[ev.name][l] = "uniform";
        });
      });
    if (
      epochVariables.filter(
        (ev) => oldEpochVariables.current.indexOf(ev.id) < 0
      ).length > 0
    ) {
      oldEpochVariables.current = epochVariables.map((ev) => ev.id);
      setParamsState(newParamsState);
    }
  }, [epochVariables]);

  const changeParam = (event, l, ev) => {
    let newParamsState = { ...paramsState };
    newParamsState[ev.name][l] = event.target.value;
    setParamsState(newParamsState);
  };
  return (
    <>
      <Row>
        {epochVariables.map((ev) => (
          <Col key={ev.id} className="p-2 m-4">
            <Container className="markovTable">
              <Row>
                <div className="markovVariableHeader">
                  <div
                    style={{
                      width: "25px",
                      fontSize: "0.8em",
                      cursor: "pointer",
                      float: "left",
                      marginRight: "10px",
                    }}
                    onClick={() => props.setActiveNavTab("Epoch Variables")}
                  >
                    <CustomNavIcon
                      type={
                        ev.context && ev.preference
                          ? "both"
                          : ev.context
                          ? "fas fa-calculator"
                          : "fas fa-eye"
                      }
                    />
                  </div>
                  {ev.name} ({ev.timescale})
                </div>
              </Row>
              {ev.levels.map((l) => (
                <Row className="justify-content-between mt-4">
                  <Col sm="auto variableHeader">{l}:</Col>
                  <Col sm="auto">
                    <select onChange={(event) => changeParam(event, l, ev)}>
                      {statDistros.map((sd) => (
                        <option value={sd}>{sd}</option>
                      ))}
                    </select>
                  </Col>
                  <Container className="mt-1">
                    <Row className="justify-content-end">
                      <Col sm="auto">
                        {paramsState[ev.name] &&
                        paramsState[ev.name][l] === "uniform"
                          ? "Min"
                          : paramsState[ev.name] &&
                            paramsState[ev.name][l] === "poisson"
                          ? "𝝺"
                          : "k"}
                        :{" "}
                        <input
                          type="number"
                          defaultValue={0.5}
                          min={0.5}
                          max={
                            (paramsState[ev.name] &&
                              paramsState[ev.name][l] === "uniform") ||
                            (paramsState[ev.name] &&
                              paramsState[ev.name][l] === "poisson")
                              ? props.eraLength *
                                (props.eraTimescale === ev.timescale ? 1 : 12)
                              : 10
                          }
                          step={0.5}
                          style={{ width: "50px" }}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col sm="auto">
                        {paramsState[ev.name] &&
                        paramsState[ev.name][l] === "uniform"
                          ? "Max"
                          : paramsState[ev.name] &&
                            paramsState[ev.name][l] === "poisson"
                          ? ""
                          : "Theta"}
                        {paramsState[ev.name] &&
                          paramsState[ev.name][l] !== "poisson" && (
                            <input
                              type="number"
                              min={0.5}
                              max={
                                paramsState[ev.name] &&
                                paramsState[ev.name][l] === "uniform"
                                  ? props.eraLength *
                                    (props.eraTimescale === ev.timescale
                                      ? 1
                                      : 12)
                                  : 2
                              }
                              step={0.5}
                              style={{ width: "50px" }}
                            />
                          )}
                      </Col>
                    </Row>
                  </Container>
                </Row>
              ))}
            </Container>
          </Col>
        ))}
      </Row>
    </>
  );
}
export default EpochVariableDistributions;
