import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useStudy } from "./StudyContext";
import ExplorePlot from "./ExplorePlot";
import { Stack } from "react-bootstrap";
import ExploreValueEditor from "./ExploreValueEditor";
import { cartesianProduct } from "../util/helpers";
import ColorByLegend from "./ColorByLegend";

function ExploreScatterplot(props) {
  const study = useStudy();
  const epochs = useMemo(
    () =>
      study.epochVariables.length < 1
        ? []
        : cartesianProduct(
            study.epochVariables
              .filter((ev) => ev.context)
              .map((ev) => ev.levels)
          ),
    [study.epochVariables]
  );
  const selectEpochRef = useRef();
  const [selectedData, setSelectedData] = useState([]);
  const [currentEpochIndex, setCurrentEpochIndex] = useState(null);
  const [currentEpochDataIndexes, setCurrentEpochDataIndexes] = useState(null);
  const [colorByDV, setColorByDV] = useState(false);
  const [absolutePlotRange,setAbsolutePlotRange] = useState(true);
  const hoverData = (d) => {
    if (selectedData.find((sd) => sd.altId === d.altId))
      setSelectedData(selectedData.filter((sd) => sd.altId !== d.altId));
    else setSelectedData([...selectedData, d]);
  };
  const setAbsRange = (e) => {
    setAbsolutePlotRange(e.target.checked);
  }

  const changeEpochIndexes = useCallback(
    (index) => {
      if (index && epochs && study.epochVariables && study.evaluations) {
        let indexes = [];
        study.evaluations.forEach((currentEval, k) => {
          if (study.epochVariables.length < 1) indexes.push(k);
          else if (
            Object.keys(currentEval)
              .filter((cek) =>
                study.epochVariables.find((ev) => ev.name === cek)
              )
              .reduce(
                (inEpoch, currentEpochVarName, currentEpochVarNameInd) => {
                  return (
                    inEpoch &&
                    "" + currentEval[currentEpochVarName] ===
                      "" + epochs[index][currentEpochVarNameInd]
                  );
                },
                true
              )
          )
            indexes.push(k);
        });
        setCurrentEpochIndex(index);
        setCurrentEpochDataIndexes(indexes);
      }
    },
    [epochs, study.epochVariables, study.evaluations]
  );
  // if currentEpochIndex is null (initialized) and all study/epoch stuff is loaded, set default
  useEffect(() => {
    if (
      !currentEpochIndex &&
      epochs &&
      study.epochVariables &&
      study.evaluations &&
      study.valuations &&
      Object.keys(study.valuations).length > 0 &&
      Object.keys(study.valuations[study.stakeholders[0].id]).length > 0
    ) {
      if (epochs.length > 0) selectEpochRef.current.value = "0";
      changeEpochIndexes("0");
    }
  }, [
    epochs,
    study.epochVariables,
    study.evaluations,
    currentEpochIndex,
    changeEpochIndexes,
    study.valuations,
    study.stakeholders,
  ]);

  return (
    <><Stack direction="horizontal" style={{marginTop:"30px"}}>
      {epochs && epochs.length > 0 && (
        <div style={{paddingRight:"20px",marginRight:"30px",borderRight:"1px solid gray"}}>
          <h4>
            Select Epoch:
            <select
              onChange={(e) => changeEpochIndexes(e.target.value)}
              ref={selectEpochRef}
            >
              {epochs.map((e, i) => (
                <option value={i}>{i}</option>
              ))}
            </select></h4><h5>
            {epochs &&
              currentEpochIndex &&
              epochs[currentEpochIndex].map((j, k) => {
                return (
                  <div>
                    {study.epochVariables[k].name} : {j}
                  </div>
                );
              })}
          </h5>
        </div>
      )}<Stack><h4>Plot Settings</h4>
      <Stack direction="horizontal" style={{marginTop:"30px"}}>
        <div>
          Color:{" "}
          <select
            defaultValue="steelblue"
            onChange={(e) => setColorByDV(e.target.value)}
          >
            <option value="steelblue">
              Default (no color, partly transparent)
            </option>
            {study.alternativeVariables &&
              study.alternativeVariables.map((av) => (
                <option value={av.id}>Alternative Variable: {av.name}</option>
              ))}
          </select>
        </div>
        {colorByDV !== "steelblue" && (
          <ColorByLegend
            colorByDV={colorByDV}
            alternativeVariables={study.alternativeVariables}
          />
        )}
      </Stack>
      <label for="relativeSize" style={{width:"300px"}}>
        <input type="checkbox" name="relativeSize" id="relativeSize" defaultChecked={true} onChange={setAbsRange}/>{" "}
        Fixed Plot Size [0, 1]
      </label></Stack></Stack>
      {study.evaluations &&
        study.evaluations.length > 0 &&
        currentEpochIndex &&
        currentEpochDataIndexes &&
        study.valuations &&
        Object.keys(study.valuations).length > 0 &&
        Object.keys(study.valuations[study.stakeholders[0].id]).length > 0 &&
        Object.keys(study.valuations)
          .sort(
            (a, b) =>
              study.stakeholders.findIndex((sh) => sh.id === a) -
              study.stakeholders.findIndex((sh) => sh.id === b)
          )
          .map((sh) => (!study.stakeholders.find((s) => s.id === sh) ?"":
            <>
              <h3 style={{ paddingTop: "30px",marginTop:"30px",borderTop:"1px solid gray" }}><small><i className="fas fa-user" /></small>&nbsp;
                {study.stakeholders &&
                  study.stakeholders.find((s) => s.id === sh).name}
                :
              </h3>
              <Stack direction="horizontal" >
                <ExplorePlot
                  absolutePlotRange={absolutePlotRange}
                  stakeholder={study.stakeholders.find((s) => s.id === sh)}
                  hoverData={hoverData}
                  selectedData={selectedData}
                  width={600}
                  height={400}
                  colorByDV={colorByDV}
                  alternativeVariables={study.alternativeVariables}
                  inputData={Object.keys(study.valuations[sh])
                    // get only this epoch's valuations
                    .filter((k) => {
                      return currentEpochDataIndexes.indexOf(parseInt(k)) >= 0;
                    })
                    // then form the array we want
                    .map((k) => {
                      return {
                        altData: study.valuations[sh][k],
                        altId: currentEpochDataIndexes.indexOf(parseInt(k)),
                        altAvs: Object.keys(study.evaluations[k])
                          .filter((ek) =>
                            study.alternativeVariables.find(
                              (av) => ek === av.name
                            )
                          )
                          .map((ek) => {
                            return { [ek]: study.evaluations[k][ek] };
                          }),
                      };
                    })}
                />
                <ExploreValueEditor stakeholder={sh} />
              </Stack>
            </>
          ))}
    </>
  );
}
export default ExploreScatterplot;
