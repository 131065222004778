import { Col, Container, Row, Stack } from "react-bootstrap";
import CustomNavIcon from "../CustomNavIcon";
import { useEffect, useRef, useState } from "react";
import { cartesianProduct } from "../../../util/helpers";


function EpochWeighting({ epochVariables, ...props }) {
  const epochVariablesRefs = useRef({});
  epochVariables.forEach((ev) => (epochVariablesRefs.current[ev.name] = []));
  const [currentSum,setCurrentSum] = useState({});
  const [effectiveWeight, setEffectiveWeight] = useState([]);

  const calcHeatMapLev = (lev) => {
    let max = Math.max.apply(Math.max, effectiveWeight);
    let min = Math.min.apply(Math.min, effectiveWeight);
    let op = (max-min)>=0.001?100*(max-min)*(lev - min) : 0;

    return "rgba(0,255,255," + op + ")";
  };

  useEffect(() => {
    epochVariablesRefs.current = { ...epochVariablesRefs.current };

  }, [epochVariables]);

  useEffect(() => {
    updateEffWeights();
  }, []);

  const updateEffWeights = () => {
    // update effective weights
    let newEffWeight = [...effectiveWeight];
    cartesianProduct(epochVariables.map((ev) => ev.levels)).forEach(
      (config, i) => {
        let configWeight = -1;
        epochVariables.forEach((ev, j) => {
          configWeight =
            configWeight >= 0
              ? configWeight *
                epochVariablesRefs.current[ev.name].find(
                  (evr) => evr.getAttribute("data-evlev") === config[j]
                ).value
              : epochVariablesRefs.current[ev.name].find(
                  (evr) => evr.getAttribute("data-evlev") === config[j]
                ).value;
        });
        newEffWeight[i] = Math.round(1000 * configWeight) / 1000;
      }
    );
    setEffectiveWeight(newEffWeight);
  };

  const reallocateWeights = (evName) => {
    epochVariablesRefs.current[evName].forEach((w,i) => {
      epochVariablesRefs.current[evName][i].value = Math.round(100*w.value/currentSum[evName])/100
    })
    // update local sum
    let newCurrentSum = {...currentSum};
    newCurrentSum[evName] = 1;
    setCurrentSum(newCurrentSum);
    // update effective weights
    updateEffWeights();
  }

  const changeWeight = (event, lev) => {
    let matchingEv = epochVariables.find(
      (ev) => ev.id === event.target.getAttribute("data-evid")
    );
    // if there are only two levels, auto-adjust the other level to make 100%
    if (matchingEv.levels.length === 2) {
      let otherBox = epochVariablesRefs.current[matchingEv.name].find(
        (evr) => evr.getAttribute("data-evlev") !== lev
      );
      otherBox.value = Math.round(100 - event.target.value * 100) / 100;
    } else {
      // check to make sure we're not going over 1
      let otherBoxesSum = epochVariablesRefs.current[matchingEv.name]
        .filter((evr) => evr.getAttribute("data-evlev") !== lev)
        .map((evr) => evr.value)
        .reduce((partialSum, a) => partialSum + parseInt(a * 100), 0);
      let totalSum = parseInt(event.target.value * 100) + otherBoxesSum;
      if (parseInt(event.target.value * 100) + otherBoxesSum > 100) {
        event.target.value = (100 - otherBoxesSum) / 100;
        totalSum = parseInt(event.target.value * 100) + otherBoxesSum;
        epochVariablesRefs.current[matchingEv.name]
        .forEach(evr => {
          evr.classList.add("redColor");
          setTimeout(() => evr.classList.add("transitionAll"), 100);
          setTimeout(() => {
          evr.classList.remove("redColor");
        }, 300);
          setTimeout(() => {
          evr.classList.remove("transitionAll");
        }, 1300);})
      }
      let newCurrentSum = {...currentSum};
      newCurrentSum[matchingEv.name] = totalSum/100;
      setCurrentSum(newCurrentSum);
    }
    updateEffWeights();
  };
  return (
    <Container>
      <Row className="justify-content-start" ><Col lg="auto">
        <Stack direction="horizontal" gap={5}>
          {epochVariables.map((ev, i) => (
              <Stack gap={2} key={i} className="mt-4 markovTable" style={{padding:"10px"}}>
                <Stack direction="horizontal" style={{borderBottom:"2px dotted darkgray"}}>
                  <small>
                    <small>
                      <CustomNavIcon
                        type={
                          ev.context && ev.preference
                            ? "both"
                            : ev.context
                            ? "fas fa-calculator"
                            : "fas fa-eye"
                        }
                      />
                    </small>
                  </small>
                  <div style={{ marginLeft: "10px",fontWeight:"bold" }}>{ev.name}</div>
                </Stack>
                {ev.levels.map((l, j) => (
                  <Stack key={j} direction="horizontal" gap={1}>
                    <input
                      type="number"
                      ref={(el) =>
                        (epochVariablesRefs.current[ev.name][j] = el)
                      }
                      defaultValue={Math.round(100 / ev.levels.length) / 100}
                      max={1}
                      min={0}
                      step={0.05}
                      maxLength={3}
                      data-evid={ev.id}
                      data-evlev={l}
                      style={{
                        width: "60px",
                        marginTop: j === 0 ? "10px" : "0px",
                      }}
                      onChange={(ev) => changeWeight(ev, l)}
                    />
                    <div>{l}</div>
                  </Stack>
                ))}
                <div onClick={() => reallocateWeights(ev.name)} className={currentSum[ev.name] < 1 ? "unallocatedWeight" : ""} >∑ Weights:&nbsp;{currentSum[ev.name] ? currentSum[ev.name] : "1"}</div>
              </Stack>
          ))}<div className="ms-auto"></div>
        </Stack></Col>
      </Row>
      
      <Row className="mt-5">
        <h4>Effective Weights of Individual Epochs:</h4>
      </Row>
      <Row className="variableHeader">
        <Col>Epoch #</Col>
        {epochVariables.map((ev) => (
          <Col key={ev.id}>
            <div>{ev.name}</div>
          </Col>
        ))}
        <Col xs={1}>Weight</Col>
      </Row>
      {cartesianProduct(epochVariables.map((ev) => ev.levels)).map(
        (config, i) => (
          <Row className="epochWeightRow" key={i} style={{background: calcHeatMapLev(effectiveWeight[i]),opacity:(effectiveWeight[i]>0?"1":"0.4")}}>
            <Col>Epoch {i}</Col>
            {config.map((evLev) => (
              <Col>{evLev}</Col>
            ))}
            <Col
              xs={1}
              // style={{ background: calcHeatMapLev(effectiveWeight[i]) }}
            >
              {effectiveWeight[i]}
            </Col>
          </Row>
        )
      )}
    </Container>
  );
}
export default EpochWeighting;
